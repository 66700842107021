<template>
  <div class="lg:container mx-auto px-3 compensation-top-nav pb-10">
    <UserTabs :activeMyShipments="true"/>

    <h2 class="mb-6 text-2xl">表示する情報</h2>
    <div
      class="
        search-bar
        grid-cols-2
        md:grid-cols-3
        grid
        lg:gap-10
        gap-4
        items-end
      "
    >
      <div class="col-span-1 lg:col-span-2">
        <div class="grid-cols-2 grid lg:gap-10 gap-4">
          <div class="col-span-2 lg:col-span-1">
            <select class="w-full border bg-white px-4 py-2 outline-none" v-model="selectedMonth">
              <option v-for="month in 12" :key="month"
              :value="month -1"
              class="py-3">{{month}}月1日注文確定分</option>
            </select>
          </div>
          <div class="col-span-2 lg:col-span-1">
            <InputField v-model="searchKeyword" placeholder='商品名で絞り込み'/>
          </div>
        </div>
      </div>
      <div class="col-span-1 lg:col-span-1">
        <button
          class="
            bg-black-p-btn
            rounded-md
            text-white
            py-2
            h-10
            md:w-1/2
            w-2/3
          "
          @click="search()"
        >
          絞り込み表示
        </button>
      </div>
    </div>

    <template v-if="shipments.length > 0">
    <!-- PC -->
    <div class="lg:block hidden">
      <table class="table-auto text-left
        lg:my-10 my-6 border-collapse border table-home w-full mb-6">
        <thead>
          <tr>
            <th>注文番号</th>
            <th>商品</th>
            <th>店名</th>
            <th>送付先</th>
            <th>郵便番号</th>
            <th>送付先住所</th>
            <th>電話番号</th>
            <th>販売価格</th>
            <th>支払い日付</th>
            <th>ステータス</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in shipments" :key="item.id">
            <td>{{item.order.id}}</td>
            <td>{{item.order.product.name}}<br>{{item.order.selectedOptionName}}</td>
            <td>{{item.order.shop.name}}</td>
            <td>{{item.address.lastName}} {{item.address.firstName}}</td>
            <td class="text-left">{{item.address.cityCode}}-{{item.address.districtCode}}</td>
            <td>{{item.address.prefecture}}{{item.address.address}}</td>
            <td class="text-left">
              {{item.address.phoneNumber}}
            </td>
            <td>{{$filters.formatCurrency(item.order.price)}}円</td>
            <td>{{$filters.formatJpDatetime(item.paidDate)}}</td>
            <td :class="[item.status === '成功' ? 'text-green-300' : 'text-red-600' ]">
              {{item.status}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Mobile -->
    <div class="flex flex-col lg:hidden ">
      <div class="overflow-x-auto">
        <div class="align-middle inline-block min-w-full">
          <div class="overflow-hidden">
            <table class="table-auto text-left
            lg:my-10 my-6 border-collapse border table-home w-full mb-6">
              <thead>
                <tr>
                  <th>注文番号</th>
                  <th>商品/店名</th>
                  <th>送付先</th>
                  <th>販売価格</th>
                  <th>支払い日付</th>
                  <th>ステータス</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in shipments" :key="item.id">
                  <td>{{item.order.id}}</td>
                  <td>{{item.order.product.name}}<br>{{item.order.selectedOptionName}}<br/>
                    {{item.order.shop.name}}
                  </td>
                  <td>{{item.address.cityCode}}-{{item.address.districtCode}}<br/>
                    {{item.address.lastName}} {{item.address.firstName}}<br/>
                    {{item.address.prefecture}}{{item.address.address}}<br/>
                    {{item.address.phoneNumber}}
                  </td>
                  <td>{{$filters.formatCurrency(item.order.price)}}円</td>
                  <td>{{$filters.formatJpDatetime(item.paidDate)}}</td>
                  <td>{{item.status}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center lg:my-10 my-4 pagination">
      <paginate :totalPage="totalPage" v-model="page"></paginate>
    </div>
    </template>
    <div v-else class="w-full text-center text-xl flex w-full
    h-80 justify-center items-center"> 検索対象が見つかりませんでした。</div>
  </div>
</template>

<script>
import UserTabs from '../../components/UserPage/UserTabs.vue';
import { ShipmentService } from '@/services';
import Paginate from '../../components/common/Paginate.vue';

const PAGE_SIZE = process.env.VUE_APP_ITEM_PER_PAGE;

export default {
  name: 'MyShipments',
  components: {
    UserTabs,
    Paginate,
    // InputField,
  },
  data: () => ({
    shipments: [],
    page: 1,
    totalPage: 1,
    selectedMonth: new Date().getMonth(),
    searchKeyword: '',
  }),
  watch: {
    page() {
      this.search();
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      let now = new Date();
      let y = now.getFullYear();
      let firstDay = new Date(y, this.selectedMonth, 1);
      let lastDay = new Date(y, this.selectedMonth + 1, 0);

      ShipmentService.getMyShipment(
        firstDay, lastDay, this.searchKeyword, this.page, PAGE_SIZE,
      ).then((res) => {
        this.shipments = res.items;
        this.totalPage = res.totalPage;
      });
    },
  },
};
</script>
