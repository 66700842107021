import axios from 'axios';
import { ShopAdapter } from './adapter';

function getTopShops() {
  return axios.get('/shop/top_branch/').then(
    (res) => Promise.resolve(
      res.data.map((obj) => ShopAdapter.convertListItemFromResponse(obj)),
    ),
    (err) => Promise.reject(err),
  );
}

function getShopDetail(shopId) {
  return axios.get(`/shop/detail/${shopId}`).then((res) => ShopAdapter.convertDetailItemFromResponse(res.data));
}

function sendContactToShop(shopId, { name, email, content }) {
  let data = {
    name,
    email,
    content,
    shop_id: shopId,
  };

  return axios.post('/contact/contact_shop', data);
}

export const ShopService = {
  getTopShops,
  getShopDetail,
  sendContactToShop,
};
