<template>
  <div class="container px-3 mx-auto lg:mb-16 mb-9 lg:text-2xl">
    <div
      class="
        w-full
        grid
        lg:grid-cols-5
        md:grid-cols-5
        grid-cols-3
        xl:gap-24
        gap-y-10
        gap-x-2
        justify-between
        mb-14
      "
    >
      <router-link :to="{name: 'shop-products', params: {id: brand.id}}"
        class="mx-auto w-full"
        v-for="brand in showBrands"
        :key="brand.id"
      >
        <template v-if="brand.setting.logo">
          <div class="text-center">
            <div class="avatar pb-2">
              <div class="rounded-full lg:w-32 w-20 lg:h-32 h-20">
                <img :src="brand.setting.logo">
              </div>
            </div>
            <div class="text-center w-full line-clamp-2">{{brand.name}}</div>
          </div>
        </template>

        <div v-else
          class="
          flex
          justify-center
          items-center
          text-base
          lg:w-32 w-20
          lg:h-32 h-20
          rounded-full
          border border-gray-600"
        >
          {{brand.name}}
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    brands: {
      type: Array,
    },
  },
  computed: {
    showBrands() {
      if (this.brands.length > 15) {
        return this.brands.slice(0, 15);
      }
      return this.brands;
    },
  },
};
</script>
