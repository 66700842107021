<template>
  <!-- PC -->
  <div class="hidden sm:grid w-full  mb-9 leading-9 ">
    <div class="container mx-auto md:max-w-full md:relative">
      <div class="w-full h-full grid grid-cols-1 md:grid-cols-2">
        <img
          src="@/assets/images/service-introduction-1.png"
          alt=""
          class="w-full"
        />
        <div class="w-full md:bg-green-50 bg-white flex items-center px-8">
          <div class="xl:w-1/2 lg:w-2/3 m-auto xl:px-8">
              <h3 class="text-2xl lg:mb-8 mb-4">毎回好きな場所へお届け</h3>
              <p>
                自分で受け取るだけではなく、毎回お好きな場所へ 配達を指定できます。
              </p>
              <p>
                指定日までに配送先を設定いただき、お友達や家族
                にギフト代わりに贈ることもできます。
              </p>
          </div>
        </div>
      </div>

    </div>

    <div class="container mx-auto md:max-w-full md:relative">
      <div class="w-full h-full grid grid-cols-1 md:grid-cols-2">
        <div class="w-full md:bg-red-50 bg-white flex items-center px-8">
          <div class="xl:w-1/2 lg:w-2/3 m-auto xl:px-8" style="width: 484px">
              <h4 class="text-2xl mb-4 lg:mb-8">お休みも自由にできる</h4>
              <p>
                長期で家を開ける、食事制限中でお休みしたい等
              </p>
              <p>希望に合わせてお休みの設定もできます。</p>
              <p>もちろんご自身に合わなかった場合は、回数など</p>
              <p>の縛りなく解約も可能です。</p>
          </div>
        </div>

        <img
          src="@/assets/images/service-introduction-2.png"
          alt=""
          class="w-full"
        />
      </div>

    </div>
  </div>

  <!-- Mobile -->
  <div class="block sm:hidden w-full lg:mb-16 mb-9 leading-9">
    <div class="container mx-auto px-5 md:max-w-full">
      <div class="w-full h-full grid grid-cols-1 md:grid-cols-2">
        <img
          src="@/assets/images/service-introduction-1.png"
          alt=""
          class="w-full"
        />
        <div class="w-full flex items-center px-5 relative">
          <div class="bg-green-50 w-28 h-28 absolute top-4 left-0"></div>
          <div class="relative pt-10 pb-4">
            <h3 class="text-3xl mb-3"><b>毎回好きな場所へお届け</b></h3>
            <p>
              自分で受け取るだけではなく、毎回お好きな場所へ 配達を指定できます。
            </p>
            <p>
              指定日までに配送先を設定いただき、お友達や家族
              にギフト代わりに贈ることもできます。
            </p>
          </div>
        </div>
      </div>
    </div>
     <div class="container mx-auto px-5 md:max-w-full">
      <div class="w-full h-full grid grid-cols-1 md:grid-cols-2">
        <img
          src="@/assets/images/service-introduction-2.png"
          alt=""
          class="w-full"
        />
        <div class="w-full flex items-center px-5 relative">
          <div class="bg-red-50 w-28 h-28 absolute top-4 left-0"></div>
          <div class="relative pt-10 pb-4">
            <h3 class="text-3xl mb-3"><b>お休みも自由にできる</b></h3>
              <p>
                長期で家を開ける、食事制限中でお休みしたい等
                希望に合わせてお休みの設定もできます。
                もちろんご自身に合わなかった場合は、回数など
                の縛りなく解約も可能です。
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

};
</script>
