<template>
<div>
    <div class="info-pro">
      <div class="overme mb-2">
        <span class="w-4 ">◆</span> {{ item.product.shopName }}
      </div>
      <div class="ml-4 overme">{{ item.product.name }}</div>
      <div class="ml-4">{{ item.product.selectedOptionName }}</div>
    </div>

    <select
      v-model="item.shippingAddress.addressBookId"
      class="lg:w-1/3 w-2/3 mt-3 border bg-white px-4 py-2 outline-none mb-6"
    >
      <option value='*'>会員様住所へ送る</option>
      <option value=''>新しい送付先へ送る</option>
      <template v-for="(add, index) in addressBook"
        :key="index">
        <option v-if="!add.isMain"
          :value="add.addressBookId">
          {{ add.displayName }}
        </option>
      </template>
    </select>

    <g-form
      v-if="showMainDetail ||
        [ addrType.CREATE_NEW, addrType.USE_EXSITING ].includes(selectedAddrType)"
      ref="form">
    <hr class="my-4 w-full" />
    <div class="flex lg:w-3/5 md:w-2/5 sm:w-full mb-6">
      <div class="lg:mr-4 mr-2">
        <InputField
          label="姓"
          required
          maxLength=25
          v-model="item.shippingAddress.lastName"
          :disabled="selectedAddrType != addrType.CREATE_NEW"
        ></InputField>
      </div>

      <div class=''>
        <InputField
          label="名"
          required
          maxLength=25
          v-model="item.shippingAddress.firstName"
          :disabled="selectedAddrType != addrType.CREATE_NEW"
        />
      </div>
    </div>

    <div class="mt-2 lg:w-3/5 md:w-2/5 sm:w-full mb-6">
      <InputField
        label="電話番号"
        required
        charSet="number"
        v-model="item.shippingAddress.phoneNumber"
        :disabled="selectedAddrType != addrType.CREATE_NEW"
      />
    </div>

    <div id="postal-code" class="mt-2 mb-6">
      <label class="mb-2 block text-opacity-70">
        <span>郵便番号</span>
        <span class="text-red-600 text-xs">*必須</span>
      </label>

      <div class="flex mt-2 lg:w-3/5 md:w-2/5 sm:w-full mb-6">
        <div class="lg:w-1/5 w-1/3">
          <InputField
            required
            v-model="item.shippingAddress.cityCode"
            charSet="number"
            size=3
            :disabled="selectedAddrType != addrType.CREATE_NEW"
          />
        </div>

        <div class=''>
          <div class="w-5 mx-2 bg-gray-400 mt-5" style="height:2px"></div>
        </div>

        <div class="lg:w-4/5 w-2/3">
          <InputField
            required
            v-model="item.shippingAddress.districtCode"
            charSet="number"
            size=4
            :disabled="selectedAddrType != addrType.CREATE_NEW"
          />
        </div>
      </div>
    </div>

    <div class="flex mb-6">
      <div class="lg:w-1/5 w-1/3 lg:mr-4 mr-2">
        <InputField
          label="都道府県"
          required
          maxLength=50
          v-model="item.shippingAddress.prefecture"
          :disabled="selectedAddrType != addrType.CREATE_NEW"
        />
      </div>
      <div class="w-4/5">
        <InputField
          label="住所"
          maxLength=200
          required
          v-model="item.shippingAddress.address"
          :disabled="selectedAddrType != addrType.CREATE_NEW"
        />
      </div>
    </div>

    <hr class="my-4 w-full" />
    </g-form>

    <div v-else class="mt-4"></div>
</div>
</template>

<script>
import { authComputed } from '@/helpers';
import { UserService, OrderService } from '@/services';

const ADDR_TYPE = Object.freeze({ USE_MAIN: 1, CREATE_NEW: 0, USE_EXSITING: 2 });

export default {
  components: {
  },
  props: {
    item: {
      type: Object,
    },
    addressBook: {
      type: Array,
      default: () => [],
    },
    showMainDetail: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    addrType: ADDR_TYPE,
  }),
  computed: {
    ...authComputed,
    selectedAddrType() {
      let selectedAddressId = this.item.shippingAddress.addressBookId;

      if (!selectedAddressId) {
        return ADDR_TYPE.CREATE_NEW;
      }
      if (selectedAddressId === '*') {
        return ADDR_TYPE.USE_MAIN;
      }
      for (let addr of this.addressBook) {
        if (addr.addressBookId === this.item.shippingAddress.addressBookId) {
          return addr.isMain ? ADDR_TYPE.USE_MAIN : ADDR_TYPE.USE_EXSITING;
        }
      }
      return ADDR_TYPE.CREATE_NEW;
    },
    coupon() {
      return this.$store.getters['cart/coupon'];
    },
  },
  mounted() {
    // if (this.loggedIn) {
    this.item.shippingAddress.addressBookId = '*';
    // }
  },
  watch: {
    'item.shippingAddress.addressBookId': {
      handler(selectedAddressBookId) {
        if (!selectedAddressBookId) {
          this.clear();
        }
        for (let addr of this.addressBook) {
          if ((selectedAddressBookId === '*' && addr.isMain)
            || (addr.addressBookId === selectedAddressBookId)) {
            this.item.shippingAddress.firstName = addr.firstName;
            this.item.shippingAddress.lastName = addr.lastName;
            this.item.shippingAddress.phoneNumber = addr.phoneNumber;
            this.item.shippingAddress.prefecture = addr.prefecture;
            this.item.shippingAddress.cityCode = addr.cityCode;
            this.item.shippingAddress.districtCode = addr.districtCode;
            this.item.shippingAddress.address = addr.address;
          }
        }
      },
    },
  },
  methods: {
    clear() {
      this.item.shippingAddress.firstName = '';
      this.item.shippingAddress.lastName = '';
      this.item.shippingAddress.phoneNumber = '';
      this.item.shippingAddress.cityCode = '';
      this.item.shippingAddress.districtCode = '';
      this.item.shippingAddress.prefecture = '';
      this.item.shippingAddress.address = '';
    },
    isValid() {
      if (this.$refs.form) {
        return this.$refs.form.isValid();
      }
      return true;
    },
    async getShippingAddress() {
      if (this.selectedAddrType === ADDR_TYPE.CREATE_NEW) {
        if (!this.$refs.form.isValid()) throw new Error('Invalid');

        const payload = {
          first_name: this.item.shippingAddress.firstName,
          last_name: this.item.shippingAddress.lastName,
          phone_number: this.item.shippingAddress.phoneNumber,
          address_postcode:
            this.item.shippingAddress.cityCode + this.item.shippingAddress.districtCode,
          prefecture: this.item.shippingAddress.prefecture,
          address: this.item.shippingAddress.address,
        };

        let newAddr = await UserService.createDeliveryAddress(payload);
        return newAddr.addressBookId;
      }

      if (this.selectedAddrType === ADDR_TYPE.USE_MAIN) {
        let addrs = this.addressBook;
        if (addrs.length === 0) {
          let res = await UserService.getAllDeliveryAddress(1, 100);
          addrs = res.items;
        }
        for (let a of addrs) {
          if (a.isMain) {
            return a.addressBookId;
          }
        }
        throw new Error('会員様住所が見つかりません');
      }

      return this.item.shippingAddress.addressBookId;
    },
    async makeOrder(paymentMethodId) {
      let shippingAddrId = await this.getShippingAddress();

      return OrderService.makeOrder(paymentMethodId,
        shippingAddrId,
        this.item.product.id,
        this.item.option,
        this.item.quantity,
        this.item.affiliateCode,
        this.coupon.id);
    },
  },
};
</script>

<style>
</style>
