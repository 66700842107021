<template>
  <div class="w-full min-h-screen text-gray-p-text-color">
    <AppNavBar/>
    <div class="w-full flex flex-col min-h-screen">
      <main class="w-full h-full flex-grow">
        <slot></slot>
      </main>
    </div>
    <AppFooter/>
  </div>
</template>

<script>
import AppNavBar from '../components/layout/AppNavBar.vue';
import AppFooter from '../components/layout/AppFooter.vue';

export default {
  name: 'MainLayout',
  components: {
    AppNavBar,
    AppFooter,
  },
  computed: {
  },
};
</script>
