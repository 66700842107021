<template>
  <div>
    <g-form ref="form"
      v-if="!showResetPasswordSuccess"
      class="
        container
        max-w-screen-md
        mx-auto
        pt-52
        pb-24
      "
    >
      <p
        class="
          w-full
          px-10
          mx-auto
          text-2xl
          text-opacity-50
          text-center
        "
      >
        新しいパスワードを入力してください
      </p>

      <div
        class="
          mx-auto
          mt-8
          px-16
          md:px-28
          lg:px-32
        "
      >
        <InputField
          label="パスワード"
          type="password"
          v-model="newPassword"
          required
        />
      </div>

      <div
        class="
          mx-auto
          mt-6
          px-16
          md:px-28
          lg:px-32
        "
        >
        <InputField
          label="パスワード（確認）"
          type="password"
          v-model="newPasswordConfirm"
          required
        />
        <p
          class="
            text-left
            text-red-600
          "
          v-if="showErrorMess"
        >
          新パスワードと確認用パスワードが一致しません。
        </p>
      </div>

      <div
        class="
          lg:w-48
          md:w-30
          lg:mb-32
          lg:mt-14
          w-44
          mx-auto
          m-12
        "
      >
        <button
          type="submit"
          class="
            w-full
            h-full
            text-white
            text-xs
            md:text-sm
            lg:text-md
            p-4
            bg-black-p-btn
            rounded-lg
          "
          @click.prevent="resetPassword()"
        >
          パスワードを変更する
        </button>
      </div>
    </g-form>

    <!-- Message -->
    <div
      v-if="showResetPasswordSuccess"
      class="
        container
        max-w-screen-md
        mx-auto
        pt-52
        pb-28
        text-center
      "
    >
      <p
        class="
          w-full
          px-10
          mx-auto
          text-2xl
          text-opacity-50
        "
      >
        パスワードの変更が完了しました
      </p>

      <p
        class="
          w-full
          px-10
          mt-10
          mx-auto
          text-xl
          text-opacity-50
        "
      >
        引き続きサービスをご利用ください。
      </p>

      <div
        class="
          lg:w-48
          md:w-30
          lg:mb-32
          lg:mt-14
          w-44
          mx-auto
          m-12
        "
      >
        <!-- Click to redirect to Top page -->
        <router-link
          :to="{
            name: 'home',
          }"
        >
          <button
            type="submit"
            class="
              w-full
              h-full
              text-white
              text-xs
              text-md
              lg:text-lg
              p-2
              bg-black-p-btn
              rounded-lg
            "
          >
            トップページへ
          </button>
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
import { AuthService } from '../../services/AuthService';
import { authComputed } from '@/helpers';

export default {
  name: 'ResetPassword',
  components: {
    // InputField,
  },
  mounted() {
    if (this.loggedIn) this.$router.push({ name: 'home' });
    this.token = this.$route.query.token;
  },
  data: () => ({
    newPassword: '',
    newPasswordConfirm: '',
    showResetPasswordSuccess: false,
    showErrorMess: false,
    token: '',
  }),
  computed: {
    ...authComputed,
  },
  methods: {
    async resetPassword() {
      if (!this.$refs.form.isValid()) return;
      if (this.checkIsSamePassword()) {
        const res = await AuthService.resetPassword(this.token, this.newPassword);
        if (res.status === 200) {
          this.showResetPasswordSuccess = true;
          window.scrollTo(0, 0);
        }
      } else {
        this.showErrorMess = true;
      }
    },

    checkIsSamePassword() {
      const newPassword = this.getNewPassword();
      const newPasswordConfirm = this.getNewPasswordConfirm();

      if (newPassword === newPasswordConfirm) {
        return true;
      }
      return false;
    },

    getNewPassword() {
      return this.newPassword;
    },

    getNewPasswordConfirm() {
      return this.newPasswordConfirm;
    },
  },
};
</script>
