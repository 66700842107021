<template>
<div>
<slot name="trigger"></slot>
<input type="checkbox" id="my-modal-2" class="modal-toggle" v-model="open">
<div class="modal">
  <div class="modal-box">
    <slot name="main"></slot>
    <div class="modal-action">
      <slot name="action"></slot>
    </div>
  </div>
</div>
</div>
</template>

<script>

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    open: false,
  }),
  watch: {
    modelValue(val) {
      this.open = val;
    },
    open(val) {
      this.$emit('update:modelValue', val);
    },
  },
  mounted() {
    this.open = this.modelValue;
  },
};
</script>

<style>

</style>
