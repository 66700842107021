<template>
  <div>
    <a :href="link" target="_blank">
        <img :src="image" alt="" class="object-cover"
        v-if="image">
    </a>
  </div>
</template>
<script>
export default {
  name: 'Banner',
  props: {
    image: {
      type: String,
    },
    link: {
      type: String,
    },
  },
};
</script>
