import axios from 'axios';
import { OrderAdapter } from '@/services/adapter';

function makeOrder(
  paymentMethodId,
  deliveryAddressId,
  productId,
  optionId,
  amount,
  affiliateCode,
  couponId,
) {
  let item = {
    product_id: productId,
    amount,
  };
  if (optionId !== undefined) {
    item.product_option_id = optionId;
  }
  if (affiliateCode && affiliateCode.length > 0) {
    item.affiliate_code = affiliateCode;
  }

  let data = {
    delivery_address_id: deliveryAddressId,
    payment_method_id: paymentMethodId,
    items: [item],
  };

  if (!couponId || couponId !== '') {
    data.coupon = couponId;
  }
  return axios.post('/order/', data).then((res) => res.data);
}

function getMyOrder(page, size) {
  return axios.get('/order/', { params: { page, size } })
    .then((res) => ({
      items: res.data.items.map((o) => OrderAdapter.convertFromResponse(o)),
      totalPage: Math.ceil(res.data.total / size),
    }));
}

function getCoupon(coupon) {
  return axios.get(`/coupon/get_coupon/?code=${coupon}`)
    .then((res) => ({
      result: res,
    }))
    .catch((err) => ({
      result: err.response,
    }));
}

function skipNextDelivery(orderId) {
  return axios.post(`/order/${orderId}/skip`);
}

function cancelSkip(orderId) {
  return axios.post(`/order/${orderId}/cancel_skip`);
}

function cancelOrder(orderId) {
  return axios.post(`/order/${orderId}/cancel`);
}

function reOpenOrder(orderId) {
  return axios.post(`/order/${orderId}/resume`).then((res) => OrderAdapter.convertFromResponse(res.data));
}

function getOrderDetail(orderId) {
  return axios.get(`/order/${orderId}`).then((res) => OrderAdapter.convertFromResponse(res.data));
}

function updateShippingAddr(orderId, addressBookId) {
  return axios.put(`/order/${orderId}`, { delivery_address_id: addressBookId });
}

function updatePaymentMethod(orderId, paymentMethodId) {
  return axios.put(`/order/${orderId}`, { payment_method_id: paymentMethodId });
}

export const OrderService = {
  makeOrder,
  getMyOrder,
  skipNextDelivery,
  cancelOrder,
  getOrderDetail,
  updatePaymentMethod,
  updateShippingAddr,
  cancelSkip,
  reOpenOrder,
  getCoupon,
};
