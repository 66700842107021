import { utils } from '@/helpers';
import { ProductAdapter } from './ProductAdapter';
import { DeliveryAddressAdapter } from './DeliveryAddressAdapter';

export class Order {
  constructor() {
    this.id = '';
    this.orderDate = '';
    this.deliveryDate = '';
    this.shippingAddress = {};
    this.status = '';
    this.skipSetAt = '';
    this.productOptionId = '';
    this.product = {};
    this.shop = {};
    this.subscriptionId = '';
    this.coupon = {};
  }

  get isPassNextMonth() {
    let now = new Date();
    let beginThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    let endThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return this.skipSetAt
      && this.skipSetAt >= beginThisMonth
      && this.skipSetAt <= endThisMonth;
  }
}

function convertFromResponse(obj) {
  let order = new Order();
  order.id = obj.id;
  order.orderDate = new Date(obj.order_date);
  order.deliveryDate = obj.delivery_date;
  order.shippingAddress = DeliveryAddressAdapter.convertFromResponse(
    JSON.parse(obj.delivery_address),
  );
  order.status = obj.status;
  order.skipSetAt = obj.skip_set_at ? new Date(obj.skip_set_at) : null;
  order.productOptionId = obj.product_option_id;
  order.product = ProductAdapter.convertDetailItemFromResponse(obj.product);
  order.paymentMethodId = obj.payment_method_id;

  order.selectedOptionName = '';
  order.price = obj.product.price;
  if (obj.coupon) {
    order.price = utils.totalPriceWithCoupon(order.product.price, obj.coupon);
  }
  for (let op of obj.product.options) {
    if (op.id === obj.product_option_id) {
      order.selectedOptionName = op.name;
    }
  }

  // let now = new Date();
  // let beginThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  // let endThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  // order.isPassNextMonth = order.skipSetAt
  //   && order.skipSetAt >= beginThisMonth
  //   && order.skipSetAt <= endThisMonth;

  order.shop = {
    id: obj.product.shop.id,
    name: obj.product.shop.name,
  };

  return order;
}

export const OrderAdapter = {
  convertFromResponse,
};
