import axios from 'axios';
import { PaymentAdapter } from './adapter';

function addCard(paymentMethodId) {
  let data = { payment_method_id: paymentMethodId };
  return axios.post('/card/add', data);
}

function getMyCards() {
  return axios.get('/card/list').then((res) => res.data.map((c) => PaymentAdapter.parseCardFromRes(c)));
}

export const PaymentService = {
  addCard,
  getMyCards,
};
