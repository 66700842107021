import axios from 'axios';

function login(email, password) {
  return axios.post('auth/login', { email, password });
}

function passwordRecovery(email) {
  return axios.post(`/auth/password-recovery/${email}`);
}

function resetPassword(userToken, newPassword) {
  return axios.post('/auth/reset-password', { token: userToken, new_password: newPassword });
}

function changePassword(newPassword, oldPassword) {
  return axios.post(
    '/auth/change-password',
    { new_password: newPassword, old_password: oldPassword },
  );
}

function refreshToken(token) {
  return axios.post('auth/refresh', { token });
}

function verifyPassword(password) {
  return axios.post('auth/check_password', { password });
}

export const AuthService = {
  login,
  passwordRecovery,
  resetPassword,
  changePassword,
  refreshToken,
  verifyPassword,
};
