export class Card {
  constructor() {
    this.paymentMethodId = '';
    this.brand = '';
    this.expMonth = '';
    this.expYear = '';
    this.last4digit = '';
  }
}

function parseCardFromRes(res) {
  let card = new Card();
  card.paymentMethodId = res.id;
  card.brand = res.card.brand;
  card.expMonth = res.card.exp_month;
  card.expYear = res.card.exp_year;
  card.last4digit = res.card.last4;
  return card;
}

export const PaymentAdapter = {
  parseCardFromRes,
};
