import axios from 'axios';
import { NewsAdapter } from './adapter';

function getNews() {
  return axios.get('/news/').then(
    (res) => res.data.map(((item) => NewsAdapter.convertFromResponse(item))),
  );
}

function getNewsDetail(newsId) {
  return axios.get(`/news/${newsId}`).then(
    (res) => NewsAdapter.convertFromResponse(res.data),
  );
}

export const NewsService = {
  getNews,
  getNewsDetail,
};
