<template>
  <div
    class="fixed top-0 inset-x-0 bg-white
    bg-opacity-80 shadow-md h-12 sm:h-14 md:h-16 lg:h-24 z-50"
    @mouseleave="showCategoryList=false"
  >
    <div class="lg:container flex items-center mx-auto h-full px-2">
        <!-- LOGO-->
        <div class="hover cursor-pointer flex flex-none items-center justify-start h-full">
          <label
            for="toggle-mobile-categories"
            class="flex items-center lg:hidden h-8 w-8 cursor-pointer"
          >
            <img src="../../assets/svg_icon/navbarIcon.svg" alt="show categories" />
          </label>

          <h2 class="text-5xl lg:block logo" @click='goHome()'>
            <img src="../../assets/images/logo.png" alt="Weeat" />
          </h2>
        </div>

        <!-- CENTER -->
        <div
          class="hidden lg:flex flex-1 items-center justify-end px-8 h-full"
          @mouseover="showCategoryList=true" @mouseleave="showCategoryList=false"
        >
          <div class="cursor-pointer h-full flex items-center">
            カテゴリーから選ぶ
            <div class="fixed top-24 left-0 right-0" v-if="showCategoryList">
              <div class="w-full bg-green-footer-bg text-white">
              <div class="container mx-auto flex flex-wrap py-2" >
                <router-link :to="{name: 'category', params: {id: category.id}}"
                    v-for="(category, index) in productCategory"
                    :key="category.id"
                    class="my-2 mr-2"
                  >
                    <span>
                      {{ category.name }}
                      <span class="px-4" v-if="index !== productCategory.length-1">/</span>
                    </span>
                </router-link>
              </div>
              </div>
            </div>
          </div>
          <span class="mx-2 h-full flex items-center">|</span>
          <router-link :to="{ name: 'contact-us' }"
            class='h-full flex items-center'>お問い合わせ</router-link>
        </div>

        <!-- ICONS-->
        <div class="flex items-center flex-grow lg:flex-none justify-end h-full">
          <router-link v-if="!loggedIn"
            :to="{
              name: 'login'
            }"
            class="w-8 h-8 sm:h-7 sm:w-7 lg:h-9 lg:w-9 flex justify-center mx-3"
          >
            <img
              :src="loggedIn ? '@/assets/svg_icon/user.svg' :
              require('@/assets/svg_icon/lock_icon.svg')"
              alt="" class="h-full" />
          </router-link>

          <div class="dropdown" v-if="loggedIn">
            <div class="w-8 h-8 sm:h-7 sm:w-7 lg:h-9 lg:w-9 flex justify-center mx-3"
              @click="showLoggedInMenu=!showLoggedInMenu"
            >
              <img  tabindex="0" src="../../assets/svg_icon/user.svg" alt="" srcset="">
            </div>
            <ul tabindex="0"
              class="p-2 shadow menu bg-base-100 rounded-box -ml-32 dropdown-content"
              v-if="showLoggedInMenu"
            >
              <li @click="showLoggedInMenu=false">
                <router-link :to="{name:'my-orders'}">マイページ</router-link>
              </li>
              <li @click="logout()">
                <a>ログアウト</a>
              </li>
            </ul>
          </div>

          <router-link
            :to="{
              name: 'my-favorite-products'
            }"
            class="w-8 h-8 sm:h-7 sm:w-7 lg:h-9 lg:w-9 flex justify-center mx-3"
          >
            <img src="../../assets/svg_icon/heart-favorite.svg" alt="" class="h-full" />
          </router-link>

          <div class="my-6 indicator mx-3">
            <div v-if="cartItems.length > 0"
              class="indicator-item badge">{{cartItems.length}}</div>
            <router-link
              :to="{
                name: 'cart'
              }"
              class="w-8 h-8 sm:h-7 sm:w-7 lg:h-9 lg:w-9  flex justify-center"
            >
              <img src="../../assets/svg_icon/cart.svg" alt=""  class="h-full"/>
            </router-link>
          </div>
      </div>
    </div>

    <!-- Mobile menu -->
    <input type="checkbox" id="toggle-mobile-categories" class="hidden" v-model="showCategoryList"/>
    <div
      class="block lg:hidden fixed inset-y-0 left-0 w-full pt-14 overflow-y-auto z-10
        transform -translate-x-full bg-white transition bg-green-footer-bg text-white"
      id="mobile-categories"
    >
      <label
        for="toggle-mobile-categories"
        class="absolute top-3 right-3 block h-8 w-8 cursor-pointer lg:hidden"
      >
        <img src="../../assets/svg_icon/close-nav-bar.svg" alt="hide categories" class="w-full"/>
      </label>
      <!-- Category -->
      <div v-for="(category, index) in productCategory"
          :key="category.id+index"
          class="p-4 text-center items-centers"
          @click="showCategoryList = false"
      >
          <router-link :to="{name: 'category', params: {id: category.id}}" class="my-2 mr-2">
              <span>
                {{ category.name }}
              </span>
          </router-link>
      </div>
      <hr/>
      <div class="p-4 text-center items-centers" @click="showCategoryList = false">
       <router-link :to="{ name: 'contact-us' }">お問い合わせ</router-link>
      </div>
      <div class="p-4 text-center items-centers" @click="showCategoryList = false">
        <router-link :to="{ name: 'shopping-guide' }">
            ショッピングガイド
        </router-link>
      </div>
      <div v-if="loggedIn" class="mb-16">
        <div class="p-4 text-center items-centers" @click="showCategoryList = false">
          <router-link :to="{name:'my-orders'}">マイページ</router-link>
        </div>
        <div class="p-4 text-center items-centers" @click="logout()">
          <a>ログアウト</a>
        </div>
      </div>

      <div v-else class='p-4 text-center items-centers mb-16' @click="showCategoryList = false">
        <router-link :to="{ name: 'login'}">ログイン</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { authComputed, systemSetting, cart } from '@/helpers';

export default {
  name: 'AppNavBar',
  computed: {
    ...authComputed,
    ...systemSetting,
    ...cart,
  },
  data: () => ({
    showLoggedInMenu: false,
    showCategoryList: false,
  }),
  methods: {
    logout() {
      this.$store.dispatch('user/logout');
      this.$router.push('home');
    },
    goHome() {
      window.location.replace(process.env.BASE_URL);
    },
  },
};
</script>

<style scoped>
#toggle-mobile-categories:checked ~ #mobile-categories
{
  transform: translateX(0);
}
</style>
