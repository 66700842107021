import { SystemSettingService } from '@/services/SystemSettingService';
import { Setting, ExternalLink } from '@/services/adapter';
import {
  CategoryService,
} from '@/services';

const initState = {
  setting: new Setting([], [], new Array(6), new ExternalLink('', '', '', '', '')),
  category: [],
  isMobile: false,
};

const mutations = {
  SET_SETTING(state, setting) {
    state.setting = setting;
  },
  SET_CATEGORY(state, category) {
    state.category = category;
  },
  SET_DEVICE(state, isMobile) {
    state.isMobile = isMobile;
  },
};

const actions = {
  init({ commit }) {
    return Promise.all([
      SystemSettingService.getSystemSetting(),
      CategoryService.getCategories(),
    ]).then((response) => {
      commit('SET_SETTING', response[0]);
      commit('SET_CATEGORY', response[1]);
    });
  },
  setDevice({ commit }, isMobile) {
    commit('SET_DEVICE', isMobile);
  },
};

const getters = {
  systemSetting(state) {
    if (state.isMobile) {
      return {
        banners: state.setting.banners.sort((a, b) => a.index - b.index),
        slides: state.setting.mobileSlides,
        link: state.setting.link,
      };
    }
    return {
      banners: state.setting.banners.sort((a, b) => a.index - b.index),
      slides: state.setting.pcSlides,
      link: state.setting.link,
    };
  },
  isMobile(state) {
    return state.isMobile;
  },
  category(state) {
    return state.category;
  },
};

export const SystemSetting = {
  state: initState,
  actions,
  mutations,
  getters,
  namespaced: true,
};
