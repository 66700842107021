<template>
  <div
    class="container md:px-3 px-5
      mx-auto
    "
  >
    <div
      class="md:w-2/3
        mx-auto
        lg:my-10
        my-6
      "
    >
        <UserInfo v-if="loggedIn"/>

        <g-form ref="form">
          <!-- Signup form -->
          <div class="user-signup" v-if="!loggedIn">
            <div
              class="
                w-full
                flex
                items-center
                lg:mb-8
                mb-4
              "
            >
              <span class="rounded bg-gray-500 w-10" style="height: 1px"></span>
              <span class="px-3">会員様情報</span>
              <span class="flex-grow rounded bg-gray-500" style="height: 1px"></span>
            </div>

            <div
              class="
                flex
                lg:w-3/5
                md:w-2/5
                sm:w-full
                mb-6
              "
            >
              <div class="lg:mr-4 mr-2">
                <InputField
                  label="姓"
                  required
                  maxLength=25
                  v-model="user.lastName"
                ></InputField>
              </div>

              <div class="">
                <InputField
                  label="名"
                  required
                  maxLength=25
                  v-model="user.firstName"
                />
              </div>
            </div>

            <div
              class="
                flex
                lg:w-3/5
                md:w-2/5
                sm:w-full
                mt-2
                mb-6
              "
            >
              <div class="lg:mr-4 mr-2 ">
                <InputField
                  label="セイ"
                  required
                  maxLength=25
                  v-model="user.lastNameKana"
                  type="katakana"
                />
              </div>

              <div class="">
                <InputField
                  label="メイ"
                  maxLength=25
                  required
                  v-model="user.firstNameKana"
                  type="katakana"
                />
              </div>
            </div>

            <div id="email-input" class="mt-2 mb-6">
              <InputField
                label="メールアドレス"
                required
                maxLength=150
                type="email"
                v-model="user.email"
              />
            </div>

            <div id="password-input" class="mt-2 mb-6">
              <InputField
                label="パスワード"
                required
                type="password"
                v-model="user.password"
              />
            </div>

            <div
              class="
                mt-2
                lg:w-3/5
                md:w-2/5
                sm:w-full
                mb-6
              "
            >
              <InputField
                label="電話番号"
                required
                charSet="number"
                v-model="user.phoneNumber"
              />
            </div>

            <div id="postal-code" class="mt-2 mb-6">
              <label
                class=" mb-2 block text-opacity-70"
              >
                <span>郵便番号</span>
                <span class="text-red-600 text-xs">*必須</span>
              </label>

              <div
                class="
                  flex
                  mt-2
                  lg:w-3/5
                  md:w-2/5
                  sm:w-full
                  mb-6
                "
              >
                <div class="lg:w-1/5 w-1/3">
                  <InputField required v-model="user.cityCode"
                  charSet="number" size=3 />
                </div>

                <div class="">
                  <div class="w-5 mx-2 bg-gray-400 mt-5" style="height:2px"></div>
                </div>

                <div class="lg:w-4/5 w-2/3">
                  <InputField required v-model="user.districtCode"
                  size=4 charSet="number" />
                </div>
              </div>
            </div>

            <div class="flex mb-6">
              <div class="lg:w-1/5 w-1/3 lg:mr-4 mr-2 ">
                <InputField
                  label="都道府県"
                  required
                  maxLength=50
                  v-model="user.prefectures"
                />
              </div>
              <div class="w-4/5 ">
                <InputField
                  label="住所"
                  maxLength=200
                  required
                  v-model="user.address"
                />
              </div>
            </div>

          </div>

          <!-- Delivery address -->
          <div class="delivery-addres">
            <div
              class="
                w-full
                flex
                items-center
                lg:mb-8
                mb-4
              "
            >
              <span class="rounded bg-gray-500 w-10" style="height: 1px"></span>
              <span class="px-3">初回送付先</span>
              <span class="flex-grow rounded bg-gray-500" style="height: 1px"></span>
            </div>

            <div v-for="item in cartItems" :key="`${item.product.id}_${item.option}`">
              <shipping-address-form :item="item"
                :addressBook="addressBook" :ref="`cartItem_${item.product.id}_${item.option}`">
              </shipping-address-form>
            </div>

            <p class="text-red-600">
              ※ご自身で変更するまで初回送付先に指定の宛先に継続的に送られます。
            </p>
          </div>
        </g-form>
      <!-- </div> -->
    </div>

  </div>

  <div class="bg-gray-200 py-3 text-center">お支払い設定</div>

  <div
    class="
      bg-gray-100
      lg:py-10
      py-6
    "
  >
    <div class="lg:container md:px-3 px-5 mx-auto">
      <div class="grid grid-cols-5 lg:gap-16 gap-4">
        <div class="md:col-span-3 col-span-5">
          <PaymentMethodSelection ref="paymentMethod"></PaymentMethodSelection>
        </div>
        <div class="md:col-span-2 col-span-5">
          <TotalOrderValue />
        </div>
      </div>
    </div>

    <div class="container mx-auto text-center mb-2">
      <p v-if="error" class="text-lg text-red-600">{{error}}</p>
    </div>

    <div class="text-center w-48 mt-6 mx-auto">
      <g-button @click="makeOrder()" :disabled="isProcessing">オーダーを確定する</g-button>
    </div>

    <go-back-btn></go-back-btn>
  </div>
</template>

<script>
import UserInfo from './UserInfo.vue';
import TotalOrderValue from './TotalOrderValue.vue';
import ShippingAddressForm from './ShippingAddressForm.vue';
import PaymentMethodSelection from './PaymentMethodSelection.vue';
import { authComputed, cart } from '@/helpers';
import { UserService } from '@/services';

export default {
  name: 'Step2',
  components: {
    UserInfo,
    TotalOrderValue,
    PaymentMethodSelection,
    ShippingAddressForm,
  },
  computed: {
    ...authComputed,
    ...cart,
  },
  data: () => ({
    isProcessing: false,
    mode: '',
    user: {
      firstName: '',
      firstNameKana: '',
      lastName: '',
      lastNameKana: '',
      email: '',
      password: '',
      phoneNumber: '',
      cityCode: '',
      districtCode: '',
      prefectures: '',
      address: '',
    },
    error: '',
    addressBook: [],
  }),
  mounted() {
    if (this.$route.params.mode) {
      this.mode = this.$route.params.mode;
    }
    if (!this.loggedIn && this.mode !== 'autoregister') {
      this.$router.push({ name: 'login', query: { from: 'checkout', next: `${this.$route.fullPath}/step2/` } });
      return;
    }

    if (this.loggedIn) {
      // get first 100 address
      UserService.getAllDeliveryAddress(1, 100).then((res) => {
        this.addressBook = res.items;
      });
    }
  },
  methods: {
    async makeOrder() {
      let self = this;

      if (self.isProcessing) return;
      self.isProcessing = true;

      if (self.mode === 'autoregister') {
        // validate form
        if (!self.$refs.form.isValid()) {
          self.isProcessing = false;
          return;
        }
        try {
          await self.signUp();
          let deliveryAddressResponse = await UserService.getAllDeliveryAddress(1, 100);
          this.addressBook = deliveryAddressResponse.items;
        } catch {
          self.error = 'このメールアドレスは既に利用されています。別のメールアドレスを入力してください。';
          self.isProcessing = false;
          return;
        }
      }

      let selectedPaymentMethod;
      try {
        selectedPaymentMethod = await self.$refs.paymentMethod.getSelectedPaymentMethod();
      } catch (e) {
        self.error = 'このカードは利用できません';
        this.isProcessing = false;
        return;
      }

      for (let item of self.cartItems) {
        if (!self.$refs[`cartItem_${item.product.id}_${item.option}`].isValid()) {
          self.isProcessing = false;
          return;
        }
      }
      let orderRequest = [];
      for (let item of self.cartItems) {
        orderRequest.push(self.$refs[`cartItem_${item.product.id}_${item.option}`].makeOrder(selectedPaymentMethod));
      }
      try {
        await Promise.all(orderRequest);
      } finally {
        this.isProcessing = false;
      }

      setTimeout(() => {
        self.$store.dispatch('cart/clearCoupon');
        self.$store.dispatch('cart/clear');
        self.$router.push({ name: 'home' });
      }, 3000);
      self.$emit('orderSuccess');
    },

    async signUp() {
      let payload = {
        email: this.user.email,
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        first_name_kana: this.user.firstNameKana,
        last_name_kana: this.user.lastNameKana,
        phone_number: this.user.phoneNumber,
        address_postcode: this.user.cityCode + this.user.districtCode,
        prefecture: this.user.prefectures,
        address: this.user.address,
        password: this.user.password,
      };

      await this.$store.dispatch('user/register', payload);
    },
  },
};
</script>
