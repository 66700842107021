<template>
  <div class="w-full h-full compensation-top-nav lg:mb-32 mb-10">
    <div class="lg:container mx-auto px-3 p-static">
      <h1 class="md:text-4xl md:mb-16 md:pt-14">Weeat利用規約</h1>
      <article class="md:text-base leading-9">
        <p>
          「Weeat」のご利用に関して以下の規約をご確認ください。<br />
          本サービスを利用するためには、本利用規約の全てに同意していただく必要があり、
          本サービスを利用したときは、本利用規約に同意したものとみなされるものとします。
        </p>
        <p class="mb-4 mt-12">
          <span class="font-bold">第1条 (会員)</span>
          <br />
          <br />
          1.
          「会員」とは、当社が定める手続に従い本規約に同意の上、購入の申し込みを行う個人をいいます。<br />
          2.
          「会員情報」とは、会員が当社に開示した会員の属性に関する情報および会員の取引に関する履歴等の情報をいいます。<br />
          3.
          本規約は、全ての会員に適用され、登録手続時および登録後にお守りいただく規約です。
          <br />
          <br />
          <span class="font-bold">第2条 (登録)</span>
          <br />
          <br />
          1. 会員資格<br />
          本規約に同意の上、所定の申込みをされたお客様は、所定の登録手続完了後に会員としての資格を有します。<br />
          会員登録手続は、会員となるご本人が行ってください、代理による登録は一切認められません。<br />
          なお、過去に会員資格が取り消された方やその他当社が相応しくないと判断した方からの会員申込はお断りする場合があります。
          <br />
          <br />
          2. 会員情報の入力<br />
          会員登録手続の際には、入力上の注意をよく読み、所定の入力フォームに必要事項を正確に入力してください。<br />
          会員情報の登録において、特殊記号・旧漢字・ローマ数字などはご使用になれません。これらの文字が登録された場合は当社にて変更致します。
          <br />
          <br />
          3. パスワードの管理<br />
          (1)パスワードは会員本人のみが利用できるものとし、第三者に譲渡・貸与できないものとします。<br />
          (2)パスワードは、他人に知られることがないよう定期的に変更する等、会員本人が責任をもって管理してください。<br />
          (3)パスワードを用いて当社に対して行われた意思表示は、会員本人の意思表示とみなし、そのために生じる支払等は全て会員の責任となります。
          <br />
          <br />
          <span class="font-bold">第3条 (変更)</span>
          <br />
          <br />
          1.
          会員は、氏名、住所など当社に届け出た事項に変更があった場合には、速やかに当社に連絡するものとします。<br />
          2.
          変更登録がなされなかったことにより生じた損害について、当社は一切責任を負いません。また、変更登録がなされた場合でも、
          変更登録前にすでに手続がなされた取引は、変更登録前の情報に基づいて行われますのでご注意ください。
          <br />
          <br />
          <span class="font-bold">第4条 (退会)</span>
          <br />
          <br />
          会員が退会を希望する場合には、会員本人が退会希望の連絡を運営までお願いいたします。<br />
          所定の退会手続の終了後に退会となります、最大１０日ほどお手続きにお時間がかかります。
          <br />
          <br />
          <span class="font-bold">第5条 (会員資格の喪失及び賠償義務)</span>
          <br />
          <br />
          1.
          会員が、会員資格取得申込の際に虚偽の申告をしたとき、通信販売による代金支払債務を怠ったとき、その他当社が会員として不適当と認める事由があるときは、
          当社は、会員資格を取り消すことができることとします。
          <br />
          <br />
          2.
          会員が、以下の各号に定める行為をしたときは、これにより当社が被った損害を賠償する責任を負います。<br />
          (1)会員番号、パスワードを不正に使用すること<br />
          (2)当ホームページにアクセスして情報を改ざんしたり、当ホームページに有害なコンピュータープログラムを送信するなどして、当社の営業を妨害すること<br />
          (3)当社が扱う商品の知的所有権を侵害する行為をすること<br />
          (4)その他、この利用規約に反する行為をすること
          <br />
          <br />
          <span class="font-bold">第6条 (会員情報の取扱い)</span>
          <br />
          <br />
          1.
          当社は、原則として会員情報を会員の事前の同意なく第三者に対して開示することはありません。ただし、次の各号の場合には、会員の事前の同意なく、
          当社は会員情報その他のお客様情報を開示できるものとします。<br />
          (1)法令に基づき開示を求められた場合<br />
          (2)当社の権利、利益、名誉等を保護するために必要であると当社が判断した場合
          <br />
          <br />
          2.
          会員情報につきましては、当社の「個人情報保護への取組み」に従い、当社が管理します。当社は、会員情報を、会員へのサービス提供、サービス内容の向上、
          サービスの利用促進、およびサービスの健全かつ円滑な運営の確保を図る目的のために、当社おいて利用することができるものとします。
          <br />
          <br />
          3.
          当社は、会員に対して、メールマガジンその他の方法による情報提供(広告を含みます)を行うことができるものとします。会員が情報提供を希望しない場合は、
          当社所定の方法に従い、その旨を通知して頂ければ、情報提供を停止します。ただし、本サービス運営に必要な情報提供につきましては、会員の希望により停止をすることはできません。
          <br />
          <br />
          <span class="font-bold">第7条 (紹介制度に関する規約)</span>
          <br />
          <br />
          本サービスは、会員による紹介を通じて購入された商品について、３％の紹介報酬を契約が続く限り永続的に受け取れるシステムを有しております。
          <br />
          <br />
          1.
          紹介リンクを利用されなかった場合の、後からのお申し出には対応できません、必ず紹介リンクよりお申し込みください。<br />
          2.
          紹介報酬の引き出しは手動での申請が必要です。また、引き出しには所定の手数料がかかります。<br />
          3.
          振込先口座の指定間違いがあった場合の組み戻し手数料に関しては、会員の負担となります。
          <br />
          <br />
          <span class="font-bold">第8条 (禁止事項)</span>
          <br />
          <br />
          本サービスの利用に際して、会員に対し次の各号の行為を行うことを禁止します。
          <br />
          <br />
          1.
          法令または本規約、本サービスご利用上のご注意、本サービスでのお買い物上のご注意その他の本規約等に違反すること<br />
          2. 当社、およびその他の第三者の権利、利益、名誉等を損ねること<br />
          3.
          青少年の心身に悪影響を及ぼす恐れがある行為、その他公序良俗に反する行為を行うこと<br />
          4.
          他の利用者その他の第三者に迷惑となる行為や不快感を抱かせる行為を行うこと<br />
          5. 虚偽の情報を入力すること<br />
          6. 有害なコンピュータープログラム、メール等を送信または書き込むこと<br />
          7. 当社のサーバーその他のコンピューターに不正にアクセスすること<br />
          8.
          パスワードを第三者に貸与・譲渡すること、または第三者と共用すること<br />
          9. その他当社が不適切と判断すること
          <br />
          <br />
          <span class="font-bold">第9条 (サービスの中断・停止等)</span>
          <br />
          <br />
          1.
          当社は、本サービスの稼動状態を良好に保つために、次の各号の一に該当する場合、予告なしに、本サービスの提供全てあるいは一部を停止することがあります。<br />
          (1)システムの定期保守および緊急保守のために必要な場合<br />
          (2)システムに負荷が集中した場合<br />
          (3)火災、停電、第三者による妨害行為などによりシステムの運用が困難になった場合<br />
          (4)その他、止むを得ずシステムの停止が必要と当社が判断した場合
          <br />
          <br />
          <span class="font-bold">第10条 (サービスの変更・廃止)</span>
          <br />
          <br />
          当社は、その判断によりサービスの全部または一部を事前の通知なく、適宜変更・廃止できるものとします。
          <br />
          <br />
          <span class="font-bold">第11条 (免責)</span>
          <br />
          <br />
          1.
          通信回線やコンピューターなどの障害によるシステムの中断・遅滞・中止・データの消失、データへの不正アクセスにより生じた損害、
          その他当社のサービスに関して会員に生じた損害について、当社は一切責任を負わないものとします。<br />
          2.
          当社は、当社のウェブページ・サーバー・ドメインなどから送られるメール・コンテンツに、コンピューター・ウィルスなどの有害なものが含まれていないことを保証いたしません。<br />
          3.
          会員が本規約等に違反したことによって生じた損害については、当社は一切責任を負いません。
          <br />
          <br />
          <span class="font-bold">第12条 (本規約の改定)</span>
          <br />
          <br />
          当社は、本規約を任意に改定できるものとし、また、当社において本規約を補充する規約(以下「補充規約」といいます)を定めることができます。
          本規約の改定または補充は、改定後の本規約または補充規約を当社所定のサイトに掲示したときにその効力を生じるものとします。この場合、会員は、
          改定後の規約および補充規約に従うものと致します。
          <br />
          <br />
          <span class="font-bold">第13条 (準拠法、管轄裁判所)</span>
          <br />
          <br />
          本規約に関して紛争が生じた場合、当社本店所在地を管轄する地方裁判所を第一審の専属的合意管轄裁判所とします。
          <br />
          <br />
          <br />
          以上
          <br />
          <br />
          <br />
        </p>

        <p class="mb-6">最終更新日：2021年11月8日</p>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsService',
  components: {},
  data: () => ({}),
};
</script>
