export class ProductDetail {
  constructor() {
    this.id = '';
    this.shopId = '';
    this.name = '';
    this.price = 0;
    this.overview = '';
    this.introduction = '';
    this.categories = [];
    this.options = [];
    this.images = [];
    this.thumb = '';
    this.status = '';
    this.isInFavorite = false;
  }

  setVal(id, shopId, name, price, overview, introduction, categories, options, images, status) {
    this.id = id;
    this.shopId = shopId;
    this.name = name;
    this.price = price;
    this.overview = overview;
    this.introduction = introduction;
    this.categories = categories;
    this.options = options;
    this.images = images;
    for (let img of images) {
      if (img.is_main) {
        this.thumb = img.src;
      }
    }
    this.status = status;
  }
}

export class ProductListItem {
  constructor(id, shop, name, price, images, status) {
    this.id = id;
    this.shopId = shop.id;
    this.shopName = shop.name;
    this.name = name;
    this.price = price;
    this.status = status;
    const mainImg = images.find((img) => img.is_main);
    if (mainImg) {
      this.image = mainImg.src;
    } else {
      this.image = '';
    }
  }
}

function convertDetailItemFromResponse(obj) {
  let product = new ProductDetail();
  product.setVal(
    obj.id,
    obj.shop.id,
    obj.name,
    obj.price,
    obj.overview,
    obj.introduction,
    obj.categories,
    obj.options,
    obj.images,
    obj.status,
  );
  product.isInFavorite = obj.is_favorite;
  return product;
}

function convertListItemFromResponse(obj) {
  return new ProductListItem(
    obj.id,
    obj.shop,
    obj.name,
    obj.price,
    obj.images,
    obj.status,
  );
}

export const ProductAdapter = {
  convertDetailItemFromResponse,
  convertListItemFromResponse,
};
