<template>
  <div class="lg:container mx-auto md:px-3 px-5 lg:my-10 my-6 text-center ">
    <h1 class="text-2xl mb-10">オーダーが完了しました</h1>
    <div class="lg:w-1/2 md:w-2/3 mx-auto md:text-base text-sm">
    オーダー完了メールを送信しましたのでご確認ください<br/>
メールが届かない場合はお問い合わせください
    </div>
    <div class="lg:py-10 py-6 md:w-32 mx-auto w-1/2">
      <router-link
        :to="{
          name: 'home',
        }"
      >
        <g-button>トップページへ</g-button>
      </router-link>
    </div>

  </div>

</template>

<script>
import GButton from '../common/GButton.vue';

export default {
  name: 'Step3',
  components: {
    GButton,
  },
};
</script>
