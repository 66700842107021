import axios from 'axios';
import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

import Home from '../pages/Home.vue';
import Checkout from '../pages/Checkout.vue';
import ContactUs from '../pages/ContactUs.vue';
import ShopProducts from '../pages/ShopProducts.vue';
import ProductDetailPage from '../pages/ProductDetail.vue';
import CategoryProducts from '../pages/CategoryProducts.vue';

import Login from '../pages/auth/Login.vue';
import SignUp from '../pages/auth/SignUp.vue';
import ResetPassword from '../pages/auth/ResetPassword.vue';
import loginToPurchase from '../pages/auth/loginToPurchase.vue';
import RequestResetPassword from '../pages/auth/RequestResetPassword.vue';

import MyInfo from '../pages/user/MyInfo.vue';
import MyOrders from '../pages/user/MyOrders.vue';
import MyOrderEdit from '../pages/user/MyOrderEdit.vue';
import MyShipments from '../pages/user/MyShipments.vue';
import MyReferralReward from '../pages/user/MyReferralReward.vue';
import MyFavoriteProducts from '../pages/user/MyFavoriteProducts.vue';
import MyDeliveryManagement from '../pages/user/MyDeliveryManagement.vue';
import MyDeliveryManagementEdit from '../pages/user/MyDeliveryManagementEdit.vue';
import MyDeliveryAddressCreate from '../pages/user/MyDeliveryAddressCreate.vue';

import CommercialTransactions from '../pages/CommercialTransactions.vue';
import PrivacyPolicy from '../pages/PrivacyPolicy.vue';
import TermsService from '../pages/TermsService.vue';
import TermsUse from '../pages/TermsUse.vue';
import ApplicationOpenStore from '../pages/ApplicationOpenStore.vue';
import ShoppingGuide from '../pages/ShoppingGuide.vue';
import QA from '../pages/QA.vue';

import NewsList from '../pages/NewsList.vue';
import NewsDetails from '../pages/NewsDetails.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    props: true,
  },
  {
    path: '/cart/:step?/:mode?',
    name: 'cart',
    component: Checkout,
    props: true,
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs,
    props: true,
  },
  {
    path: '/shop/:id/products',
    name: 'shop-products',
    component: ShopProducts,
    props: true,
  },
  {
    path: '/product/:id',
    name: 'product-detail',
    component: ProductDetailPage,
    props: true,
  },
  {
    path: '/category/:id/products',
    name: 'category',
    component: CategoryProducts,
    props: true,
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
    props: true,
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    props: true,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    props: true,
  },
  {
    path: '/login-to-purchase',
    name: 'login-to-purchase',
    component: loginToPurchase,
    props: true,
  },
  {
    path: '/request-reset-password',
    name: 'request-reset-password',
    component: RequestResetPassword,
    props: true,
  },

  {
    path: '/my-orders',
    name: 'my-orders',
    component: MyOrders,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/edit-my-order/:id',
    name: 'edit-my-order',
    component: MyOrderEdit,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/my-shipments',
    name: 'my-shipments',
    component: MyShipments,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/my-favorite-products',
    name: 'my-favorite-products',
    component: MyFavoriteProducts,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/my-info',
    name: 'my-info',
    component: MyInfo,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/my-referral-reward',
    name: 'my-referral-reward',
    component: MyReferralReward,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/my-delivery-management',
    name: 'my-delivery-management',
    component: MyDeliveryManagement,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/edit/delivery-address/:id',
    name: 'edit-my-delivery-address',
    component: MyDeliveryManagementEdit,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/create-delivery-address',
    name: 'create-delivery-address',
    component: MyDeliveryAddressCreate,
    props: true,
    meta: {
      authRequired: true,
    },
  },

  {
    path: '/commercial-transactions',
    name: 'commercial-transactions',
    component: CommercialTransactions,
    props: true,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
    props: true,
  },
  {
    path: '/terms-to-service',
    name: 'terms-to-service',
    component: TermsService,
    props: true,
  },
  {
    path: '/terms-to-use',
    name: 'terms-to-use',
    component: TermsUse,
    props: true,
  },
  {
    path: '/openstore',
    name: 'openstore',
    component: ApplicationOpenStore,
    props: true,
  },
  {
    path: '/shopping-guide',
    name: 'shopping-guide',
    component: ShoppingGuide,
    props: true,
  },
  {
    path: '/fqa',
    name: 'fqa',
    component: QA,
    props: true,
  },
  // {
  //   path: '/news/:id',
  //   name: 'news-detail',
  //   component: ProductDetailPage,
  //   props: true,
  // },

  {
    path: '/news',
    name: 'news-list',
    component: NewsList,
    props: true,
  },
  {
    path: '/news-detail/:id',
    name: 'news-detail',
    component: NewsDetails,
    props: true,
  },
];

const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHistory(),
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
  routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.authRequired);
  const isAuthenticated = store.getters['user/loggedIn'];
  if (requiresAuth && !isAuthenticated) {
    next({
      path: '/login/',
      query: { next: to.fullPath },
    });
  } else if (to.name === 'login' && isAuthenticated) {
    router.push('/');
  } else if (to.name === 'signup' && isAuthenticated) {
    router.push('/');
  } else {
    next();
  }
});

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axios.defaults.timeout = process.env.VUE_APP_REQUEST_TIMEOUT;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      router.push({ name: 'network-issue' });
    } else if (error.response.status === 403) {
      // refresh token is invalid then logout
      if (error.config.url === 'auth/refresh') {
        store.dispatch('user/logout');
      } else {
        return store.dispatch('user/refreshToken').then((access) => {
          // eslint-disable-next-line prefer-destructuring
          const config = error.config;
          // eslint-disable-next-line dot-notation
          config.headers['Authorization'] = `Bearer ${access}`;
          return axios.request(config);
        });
      }
    }
    return Promise.reject(error);
  },
);

axios.interceptors.request.use((config) => {
  let localToken = localStorage.getItem('credentials');
  if (localToken) {
    localToken = JSON.parse(localToken);
    if (!store.state.user.accessToken) {
      // when user executed logout action in other tab
      return config;
    }
    let sessionTokenSet = store.state.user.accessToken + store.state.user.refreshToken;
    if (localToken.access_token + localToken.refresh_token !== sessionTokenSet) {
      // when other account is logged in by other tab
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } else {
      // eslint-disable-next-line no-param-reassign
      config.headers.common.Authorization = `Bearer ${localToken.access_token}`;
    }
  } else if (store.state.user.accessToken) { // when user logout, clear credential of other tabs
    throw new axios.Cancel('Tải lại trang');
  }
  return config;
});

export default router;
