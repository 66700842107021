<template>
  <carousel :autoplay="6000" wrapAround :transition="2000">
    <slide v-for="(slide, i) in systemSetting.slides.concat(systemSetting.slides)" :key="i">
      <a :href="slide.link" target="_blank">
        <img class="w-screen lg:h-screen object-cover" :src='slide.image'/>
      </a>
    </slide>
    <!-- <template #addons>
      <pagination />
    </template> -->
  </carousel>
</template>
<script>
import {
  Carousel,
  Slide,
  // Pagination,
} from 'vue3-carousel';
import { systemSetting } from '@/helpers';

export default {
  name: 'MainSlider',
  computed: {
    ...systemSetting,
  },
  components: {
    Carousel,
    Slide,
    // Pagination,
  },
};
</script>
<style scoped>
</style>
