<template>
  <footer class="bg-green-footer-bg">
    <div
      class="
        container
        mx-auto
        pt-5 md:pt-16
        pb-4 md:pb-16
      "
    >
      <div class="flex flex-col-reverse lg:flex-row justify-between text-sm">
        <!-- logo, sns buttons -->
        <div class="flex flex-col items-center flex-grow">
          <span class="text-5xl lg:text-4xl 2xl:text-5xl text-white md:mb-5 mb-3 logo">
            <img src="../../assets/images/logo_w.png" alt="Weeat" />
          </span>
          <div class="flex justify-between">
            <a :href="systemSetting.link.facebook" target="_blank"
              v-if="systemSetting.link.facebook"
              class="w-6 h-6 m-3">
              <img src="../../assets/svg_icon/facebook.svg" class="w-full h-full" alt="facebook" />
            </a>

            <a :href="systemSetting.link.instagram" target="_blank"
              v-if="systemSetting.link.instagram"
              class="w-6 h-6 m-3">
              <img
                src="../../assets/svg_icon/instagram.svg"
                class="w-full h-full"
                alt="instagram"
              />
            </a>

            <a :href="systemSetting.link.twitter" target="_blank"
              v-if="systemSetting.link.twitter"
              class="w-6 h-6 m-3">
              <img src="../../assets/svg_icon/tweetter.svg" class="w-full h-full" alt="tweetter" />
            </a>
          </div>
        </div>

        <!-- col 2 -->
        <div
          class="
            text-white
            flex flex-col
            items-center
            lg:items-start
            flex-grow
            md:mb-5 mb-3
            lg:mb-0
          "
        >
          <a class="mb-1 lg:md:mb-5 mb-3">
             ちょっと嬉しい・とっても美味しい
          </a>
          <a class="mb-1 lg:md:mb-5 mb-3">
             毎月届くお楽しみ
          </a>
          <a class="mb-1 lg:md:mb-5 mb-3">
             サブスクリプションサービス
          </a>
        </div>

        <!-- col 3 -->
        <div class="
            text-white
            flex flex-col
            items-center
            lg:items-start
            mb-6
            lg:mb-0
            flex-grow
          "
        >
          <router-link
            :to="{
              name: 'shopping-guide'
            }"
            class="link link-hover md:mb-5 mb-3"
          >
            ショッピングガイド
          </router-link>

          <router-link
            :to="{
              name: 'fqa'
            }"
            class="link link-hover mb-3">
             よくあるご質問
          </router-link>

          <router-link
            :to="{
              name: 'contact-us'
            }"
            class="link link-hover md:mb-5 mb-3"
          >
             お問合せ
          </router-link>

        </div>

        <!-- col 4 -->
        <div class="text-white flex flex-col items-center lg:items-start flex-grow">
          <router-link
            :to="{
              name: 'terms-to-service'
            }"
            class="link link-hover md:mb-5 mb-3"
          >
             ご利用規約
          </router-link>

          <router-link
            :to="{
              name: 'privacy-policy'
            }"
            class="link link-hover md:mb-5 mb-3"
          >
             プライバシーポリシー
          </router-link>

          <router-link
            :to="{
              name: 'commercial-transactions'
            }"
            class="link link-hover md:mb-5 mb-3"
          >
             特定商取引法に基づく表記
          </router-link>

          <router-link
            :to="{
              name: 'openstore'
            }"
            class="link link-hover md:mb-0 mb-3"
          >
             出店のご案内
          </router-link>
        </div>
      </div>
    </div>

    <div>
      <div class="max-w-screen-2xl relative bottom-0 mt-0 mx-auto rounded border-t border-white" />
      <p class="text-center text-xl py-8 text-white lg:static">©︎Weeat 2021</p>
    </div>
  </footer>
</template>

<script>
import { systemSetting } from '@/helpers';

export default {
  name: 'AppFooter',
  computed: {
    ...systemSetting,
  },
};
</script>
