<template>
  <div class="w-full h-full compensation-top-nav lg:mb-20 mb-10">
    <div class="lg:container mx-auto px-3 p-static">
      <h1 class="md:text-4xl md:mb-16 md:pt-14">お買い物・紹介制度ガイド</h1>
      <article class="md:text-base leading-7">
        <p class="lg:mb-10 mb-6">「Weeat」は飲食物を取り扱うショッピングモール型プラットフォームです。<br/>
        購入のお申し込みはいつでも好きな時でOK！毎月の課金は１日に行われ、１０日前後に商品が届きます。<br/>
        Weeatで取り扱う商品は全て送料込みですので、安心してお求めいただけます。<br/>
        <br/>
        <br/>
        特徴的なのは、毎月の配送先を変更できること。<br/>
        自宅へ届ける以外に、長期出張先、ご家族や友人、大切な方へと毎月お届け先をチョイス可能。<br/>
        また、不要は月はパスが可能です。（毎月課金日の前に設定されたもののみ反映します）
        <br/>
        <br/>
        また、紹介報酬制度があり紹介した商品が購入されると、お届けが続くかぎり３％が永続的に受け取れます。<br/>
        おすすめしたい商品が見つかったら、ぜひご紹介してください！
        </p>
        <div class="w-full bg-green-footer-bg text-white py-3 px-4 text-center">購入フロー</div>
        <div class="md:px-6 py-4">
          <ul class="circle">
            <li>
              お好きな商品を選びカートに入れます。
            </li>
            <li>
            クーポンをお持ちの場合はカート上で忘れず設定してください。
            </li>
            <li>
              購入者情報、お届け先情報を入力します（後で変更可能です）
            </li>
            <li>
            お支払いカード情報を設定して、お申し込みを完了してください。（現在はカード払いのみ対応しております）
            </li>
          </ul>
        </div>
        <div class="w-full bg-green-footer-bg text-white py-3 px-4 text-center">商品のお届け</div>
        <div class="md:px-6 py-4">
            <p class="lg:mb-10 mb-6">毎月１日の午前中に自動的にお支払いが行われます。<br/>
            その時点でお申し込みのあった方に、当月10日ごろに商品をお届けいたします。<br/>
            毎月1日のお支払い時間までのお申し込みは当月から、お支払い処理の時間を過ぎると翌月からのお届けになります。</p>
            <h3 class="md:text-xl lg:mb-10 mb-6">◆お届けイメージ</h3>
            <img class="w-full mb-5" src="../assets/images/shopping.png" alt=""/>
        </div>
        <div class="w-full bg-green-footer-bg text-white py-3 px-4 text-center">配送先の変更</div>
        <div class="md:px-6 py-4">
          <p>マイページ内のマイオーダーから商品の配送先を変更できます。<br/>
            毎月支払いの行われるタイミングまでに設定されている送り先に次回のお届けをさせていただきます。<br/>
            決済後の変更は基本的にはできませんが、お店によっては対応できる場合もございますので個別にお問い合わせください。
          </p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ShoppingGuide',
  components: {

  },
  data: () => ({}),
};
</script>
