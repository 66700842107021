<template>
  <div class="max-w-xl mx-auto py-4 px-3 md:px-0">
    <div class="w-full flex justify-center relative z-0">

      <div class="w-1/3 flex justify-center relative"
        v-for="step in numberOfStep" :key="step"
      >
        <div
          :class="
            [
              {'btn-step-checkout-inactive': currentStep != step},
              'btn-step-checkout btn-step-checkout-active'
            ]
          "
          @click="select(step)"
        >
          {{step}}
        </div>
        <div v-if="step < numberOfStep"
          class="absolute top-1/2 transform translate-x-1/2 w-full h-0.5 bg-gray-p-text-color"
        ></div>
      </div>

    </div>
    <div class="w-full flex justify-center mt-3 lg:mt-4">
      <div class="w-1/3 text-center"
        v-for="(label, index) in stepLabel" :key="index">{{label}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepProgress',
  props: {
    numberOfStep: {
      type: Number,
      default: 3,
    },
    stepLabel: {
      type: Array,
      defalt: () => [],
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
  }),
  methods: {
    select(step) {
      this.$emit('stepChanged', step);
    },

  },
};
</script>
