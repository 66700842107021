<template>
  <div>
    <div class="shop-banner relative">
      <img :src='isMobile ? shopInfo.setting.mobileBanner : shopInfo.setting.pcBanner'
        alt="" class="mx-auto mb-3" />
      <div class="w-full h-full absolute bottom-0">
          <div class="lg:container mx-auto px-3 h-full flex items-center">
              <h1 class="md:text-4xl text-2xl w-full overme ml-auto mr-0
              md:mt-16 mt-6 text-right text-white">{{shopInfo.name}}</h1>
          </div>
      </div>
      <div class="w-full absolute md:bottom-3 bottom-1 right-0">
          <div class="lg:container mx-auto px-3 shop-info text-right">
              <ul class="inline-block sns-button">
                  <li class="inline-block" v-if="shopInfo.setting.facebookLink">
                      <a :href="shopInfo.setting.facebookLink">
                          <img src="@/assets/svg_icon/blue_facebook.svg"
                            alt="" class="mx-2 w-10"/>
                      </a>
                  </li>
                  <li class="inline-block" v-if="shopInfo.setting.tiktokLink">
                      <a :href="shopInfo.setting.tiktokLink">
                          <img src="@/assets/svg_icon/tiktok_color.svg"
                            alt="" class="mx-2 w-10" />
                      </a>
                  </li>
                  <li class="inline-block" v-if="shopInfo.setting.instagramLink">
                      <a :href="shopInfo.setting.instagramLink">
                        <img src="@/assets/svg_icon/instagram_color.svg"
                          alt="" class="mx-2 w-10"/>
                      </a>
                  </li>
                  <li class="inline-block" v-if="shopInfo.setting.twitterLink">
                      <a :href="shopInfo.setting.twitterLink">
                          <img src="@/assets/svg_icon/twitter_color.svg"
                            alt="" class="mx-2 w-10" />
                      </a>
                  </li>
                  <li class="inline-block" v-if="shopInfo.setting.linkedinLink">
                      <a :href="shopInfo.setting.linkedinLink">
                          <img src="@/assets/svg_icon/LinkedIn_icon_circle.svg"
                            alt="" class="mx-2 w-10" />
                      </a>
                  </li>
                  <li class="inline-block" v-if="shopInfo.setting.website">
                      <a :href="shopInfo.setting.website">
                          <img src="@/assets/svg_icon/www.png"
                            alt="" class="mx-2 w-10" />
                      </a>
                  </li>
              </ul>
          </div>
      </div>
    </div>

    <div class="container lg:px-3 px-5 mx-auto mt-16">

      <!-- MOBILE -->
      <div class="block lg:hidden">
        <div :data-tip="productInfo.name" class="tooltip tooltip-bottom z-10"
          v-if="productInfo.name.length > 15">
          <h1 class="text-2xl line-clamp-2">{{ productInfo.name }}</h1>
        </div>
        <h1 v-else class="text-2xl line-clamp-2">{{ productInfo.name }}</h1>

        <p>月額 {{ $filters.formatCurrency(productInfo.price) }}円(税込）</p>
        <div class="border-t border-black-p-btn border-opacity-50 my-2"></div>
        <p>{{productInfo.overview}}</p>
      </div>

      <div class="grid md:grid-cols-2 grid-cols-1 gap-4 mt-3 md:mt-0">
        <div class="h-full">
          <img-box :images="productInfo.images">
            <template v-slot:overlay v-if="productInfo.status === 'STOP'">
              <img
                    src="@/assets/images/pendding_product.png"
                    class="w-full h-full absolute top-0 left-0 object-contain"
                >
            </template>
          </img-box>
        </div>

        <!-- PC -->
        <div>
          <div class="hidden lg:block">
            <div :data-tip="productInfo.name" class="tooltip tooltip-bottom z-10"
              v-if="productInfo.name.length > 50">
              <p class="text-xl line-clamp-2">{{ productInfo.name }}</p>
            </div>
            <p v-else class="text-xl line-clamp-2">{{ productInfo.name }}</p>

            <p>月額 {{ $filters.formatCurrency(productInfo.price) }}円(税込）</p>
            <div class="border-t border-black-p-btn border-opacity-50 my-2"></div>
            <p>{{productInfo.overview}}</p>
          </div>

          <div>
            <div class="mt-4" v-if="productInfo.options.length > 0">
              <SelectField v-model="selectedProductOption"
                :options="productInfo.options" className="lg:p-2 p-1"/>
            </div>

            <div class="flex items-center mt-4">
              <div class="w-36 lg:mr-12 mr-6">
                <select v-model="quantity"
                  class="border border-black-p-btn border-opacity-50 outline-none w-full p-2"
                >
                  <option v-for="i in 50" :key="i" :value="i">{{i}}</option>
                </select>
              </div>

              <div
                class="
                  w-60
                  mr-8
                "
              >
                <g-button :disabled="error.length > 0 || productInfo.status === 'STOP'"
                  @click="addToCart">カートに入れる</g-button>
              </div>

              <img class="favorite max-h-8"
                v-if="productInfo.isInFavorite"
                src="@/assets/svg_icon/favorite.svg"
                @click.prevent="removeFromFavoriteProduct()"
              >
              <img class="add-to-favorite max-h-8"
                v-if="!productInfo.isInFavorite"
                src="@/assets/svg_icon/heart-favorite.svg"
                @click.prevent="addToMyFavorite()"
              >
            </div>

            <div class="text-red-500 py-2">{{error}}</div>
          </div>
          <div v-if="productInfo.status === 'STOP'"
            class="text-red-500 py-2">この商品は、新規のお申し込みを停止しています。</div>

          <div>
            <section class="my-5">
              <h5 class="text-yellow-p-text-color">お支払い方法</h5>
              <p>
                クレジットカード
              </p>
            </section>
            <section class="my-5">
              <h5 class="text-yellow-p-text-color">お支払いタイミング</h5>
              <ul>
                <li>・初回はご注文後最初の1日</li>
                <li>・2回目以降毎月1日のご請求</li>
              </ul>
            </section>
            <section class="my-5">
              <h5 class="text-yellow-p-text-color">停止・パスについて</h5>
              <ul>
                <li>・いつでも停止可能です、1回のみでの停止もOKです。</li>
                <li>・1回パスで次回のみ停止も可能です。</li>
                <li>（前月末日の23:５９までの設定が必要です。）</li>
              </ul>
            </section>
          </div>

          <template v-if="loggedIn && productInfo.status === 'ON_SALE' && shopInfo.allowAffiliate">
          <div class="border-t border-black-p-btn border-opacity-50 mb-4"></div>

          <div class="block lg:flex items-center justify-between w-full my-2">
            <div>
              <g-button @click="generateAffiliateLink()">この商品の紹介URLを取得</g-button>
            </div>
            <!-- <div
              class="
                flex mt-3 md:mt-0
                items-center
                md:justify-around
                lg:justify-start
                justify-between
              "
            >
              <span class="w-10 h-10 lg:h-7
              lg:w-7 lg:ml-6 rounded overflow-hidden cursor-pointer"
                @click="generateAffiliateLink()"
              >
                <img src="@/assets/svg_icon/twitter-circle.svg" alt="twitter" class="w-full">
              </span>
              <span class="w-10 h-10 lg:h-7 lg:w-7 lg:ml-6 rounded overflow-hidden cursor-pointer"
                @click="generateAffiliateLink()"
              >
                <img src="@/assets/svg_icon/facebook-circle.svg" alt="facebook" class="w-full">
              </span>
              <span class="w-10 h-10 lg:h-7 lg:w-7 lg:ml-6 rounded overflow-hidden cursor-pointer"
                @click="generateAffiliateLink()"
              >
                <img src="@/assets/svg_icon/line-circle.svg" alt="line" class="w-full">
              </span>
              <span class="w-10 h-10 lg:h-7 lg:w-7 lg:ml-6 rounded overflow-hidden cursor-pointer"
                @click="generateAffiliateLink()"
              >
                <img src="@/assets/svg_icon/linkedin-circle.svg" alt="linkedin" class="w-full">
              </span>
              <span class="w-10 h-10 lg:h-7 lg:w-7 lg:ml-6 rounded overflow-hidden cursor-pointer"
                @click="generateAffiliateLink()"
              >
                <img src="@/assets/svg_icon/url-circle.svg" alt="url" class="w-full">
              </span>
            </div> -->
          </div>
          <router-link :to="{name: 'shopping-guide'}"
            class="text-blue-500 underline lg:mt-0 mt-6 block"
          >
            Weeatの報酬付き商品紹介システムについて
          </router-link>
          </template>
        </div>
      </div>

      <div class="container xl:max-w-7xl mx-auto lg:mt-20 mt-8 lg:mb-16 m-10">
        <div class="md:w-3/4 w-full mx-auto">
          <Banner v-if="systemSetting.banners[5]"
            :image="systemSetting.banners[5].image" :link="systemSetting.banners[5].link" />
        </div>
      </div>
      <div class="
        max-w-screen-2xl
        mx-auto
      ">
        <div class="tab-container
            flex
            mb-8
          "
        >
          <div class="
              md:text-base text-xs
              w-2/6
              text-center
              border-b-2
              cursor-pointer
            "
            :class="
              {
                'text-orange-text-primary border-orange-text-primary': activeProductInfor,
              }
            "
            @click="showProductInfor()"
          >
            商品情報
          </div>

          <div
            class="
              w-2/6
              md:text-base text-xs
              text-center
              border-b-2
              cursor-pointer
            "
            :class="
              {
                'text-orange-text-primary border-orange-text-primary': activeStoreInfor,
              }
            "
            @click="showStoreInfor()"
          >
            お店情報
          </div>

          <div
            class="
              md:text-base text-xs
              w-2/6
              text-center
              border-b-2
              cursor-pointer
            "
            :class="
              {
                'text-orange-text-primary border-orange-text-primary': activeContactForm,
              }
            "
            @click="showContactForm()"
          >
            お店に問い合わせ
          </div>
        </div>

        <div v-if="activeProductInfor" class="product-introduction">
          <div v-html="productInfo.introduction"></div>
        </div>

        <div v-if="activeStoreInfor" class="shop-introduction">
          <StoreInformation :storeInformation="shopInfo"/>
        </div>

        <div v-if="activeContactForm" class="contact-form">
          <ShopContactForm :shopId="shopInfo.id" />
        </div>
      </div>

    </div>

    <div class="md:mt-8 mt-4">
      <div class="bg-gray-p-bg">
        <div class="mx-auto pt-4 pb-6">
          <ListProductCard
            title="このお店のその他の商品"
            :products="productsInSameShop.slice(0,3)"
          />

          <div
            class="w-24 mx-auto"
            v-if="productsInSameShop.length > 3"
          >
          <router-link :to="{name: 'shop-products', params: {id: shopInfo.id}}">
            <g-button>もっと見る</g-button>
          </router-link>
          </div>
        </div>
      </div>

      <div class='md:mt-8 mt-4'>
        <div class="py-4">
          <div class="md:hidden block">
            <ListProductCard
              title="おすすめ商品"
              :products="recommendedProducts.slice(0, 3)"
            />
          </div>
          <div class="md:block hidden">
            <ListProductCard
              title="この商品が気になるあなたにおすすめ"
              :products="recommendedProducts.slice(0, 3)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- FLOAT -->
    <div class="floating-cart-wrapper hidden md:block"
    v-bind:class="{hidden: scrollPosition < 500, show: scrollPosition > 500}">
      <p class="item-name line-clamp-2">{{ productInfo.name }}</p>
      <span class="item-price-bigfont">
        月額 {{ $filters.formatCurrency(productInfo.price) }}円(税込）</span>
        <div class="mt-3" v-if="productInfo.options.length > 0">
          <SelectField v-model="selectedProductOption"
          :options="productInfo.options" className="lg:p-2 p-1"/>
        </div>
        <div class="mt-3">
         <InputField
            type="number"
            :max=50
            :min=1
            v-model="quantity"
          />
        </div>
        <div class="flex mt-3">
          <div
            class="
              w-60
              mr-1
            "
          >
            <g-button :disabled="error.length > 0 || productInfo.status === 'STOP'"
              @click="addToCart">
              <small>カートに入れる</small>
            </g-button>
          </div>

          <img class="favorite max-h-8"
            v-if="productInfo.isInFavorite"
            src="@/assets/svg_icon/favorite.svg"
            @click.prevent="removeFromFavoriteProduct()"
          >
          <img class="add-to-favorite max-h-8"
            v-if="!productInfo.isInFavorite"
            src="@/assets/svg_icon/heart-favorite.svg"
            @click.prevent="addToMyFavorite()"
          >
        </div>
    </div>
    <div class="block md:hidden bg-white p-2 fixed bottom-0 w-full border-t z-10"
      v-if="productInfo.status === 'ON_SALE'">
      <div class="flex justify-end items-center">
         <div class="h-full mr-2" v-if="productInfo.options.length > 0">
          <SelectField v-model="selectedProductOption"
          :options="productInfo.options" className="p-2"/>
        </div>
        <div
          class="
            w-60
            mr-1
          "
        >
          <g-button :disabled="error.length > 0" @click="addToCart">
            <small>カートに入れる</small>
          </g-button>
        </div>

        <img class="favorite max-h-8"
          v-if="productInfo.isInFavorite"
          src="@/assets/svg_icon/favorite.svg"
          @click.prevent="removeFromFavoriteProduct()"
        >
        <img class="add-to-favorite max-h-8"
          v-if="!productInfo.isInFavorite"
          src="@/assets/svg_icon/heart-favorite.svg"
          @click.prevent="addToMyFavorite()"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { systemSetting, authComputed } from '@/helpers';
import SelectField from '@/components/common/SelectField.vue';
// import InputField from '@/components/common/InputField.vue';
import Banner from '@/components/Banner.vue';
import ListProductCard from '@/components/ListProductCard.vue';
import StoreInformation from '@/components/ProductDetailPage/StoreInformation.vue';
import ShopContactForm from '@/components/ProductDetailPage/ShopContactForm.vue';
import { ProductService, ShopService } from '@/services';
import { Shop, ProductDetail } from '@/services/adapter';
import ImgBox from '../components/ProductDetailPage/ImgBox.vue';
import GButton from '../components/common/GButton.vue';
// import GButton from '../components/common/GButton.vue';

export default {
  name: 'ProductDetail',
  components: {
    SelectField,
    Banner,
    ListProductCard,
    StoreInformation,
    ShopContactForm,
    ImgBox,
    GButton,
    // InputField,
    // GButton,
  },
  data: () => ({
    error: '',
    productInfo: new ProductDetail(),
    shopInfo: new Shop(),

    recommendedProducts: [],
    productsInSameShop: [],
    activeProductInfor: true,
    activeStoreInfor: false,
    activeContactForm: false,

    quantity: 1,
    selectedProductOption: undefined,
    scrollPosition: null,

    affiliateCode: null,
  }),
  watch: {
    quantity(val) {
      if (val > 50) {
        this.error = '一度に購入できる最大購入個数を超えています';
      } else if (val < 1) {
        this.error = '数量は１以上入力してください';
      } else {
        this.error = '';
      }
    },
  },
  computed: {
    ...systemSetting,
    ...authComputed,
  },
  created() {
    ProductService.getDetailProduct(this.$route.params.id).then((res) => {
      this.productInfo = res;
      ShopService.getShopDetail(this.productInfo.shopId).then((shop) => {
        this.shopInfo = shop;
      });
      ProductService.getProductOfShop(this.productInfo.shopId).then((p) => {
        this.productsInSameShop = p.items.sort(() => 0.5 - Math.random());
      });
      ProductService.getProductOfCategory(this.productInfo.categories[0].id, 1, 100).then((p) => {
        this.recommendedProducts = p.items.sort(() => 0.5 - Math.random());
      });
    }).catch((err) => {
      if (err.response.status === 404) {
        this.$router.push({ name: 'home' });
      }
    });

    if (this.$route.query) {
      this.affiliateCode = this.$route.query.affiliate;
    }
  },
  methods: {
    showProductInfor() {
      this.activeProductInfor = true;
      this.activeStoreInfor = false;
      this.activeContactForm = false;
    },

    showStoreInfor() {
      this.activeProductInfor = false;
      this.activeStoreInfor = true;
      this.activeContactForm = false;
    },

    showContactForm() {
      this.activeProductInfor = false;
      this.activeStoreInfor = false;
      this.activeContactForm = true;
    },

    addToMyFavorite() {
      if (!this.loggedIn) {
        this.$router.push({ name: 'login' });
        return;
      }
      const productId = this.productInfo.id;
      ProductService.addFavoriteProduct(productId).then(() => {
        this.productInfo.isInFavorite = true;
      });
    },
    removeFromFavoriteProduct() {
      if (!this.loggedIn) {
        this.$router.push({ name: 'login' });
        return;
      }
      const productId = this.productInfo.id;
      ProductService.deleteFavoriteProduct(productId).then(() => {
        this.productInfo.isInFavorite = false;
      });
    },
    addToCart() {
      if (this.error) return;

      let selectedOptionName = '';
      if (this.productInfo.options.length > 0) {
        if (!this.selectedProductOption) {
          this.$toast.error('商品のオプションを選択してください');
          return;
        }
        for (let op of this.productInfo.options) {
          if (op.id === this.selectedProductOption) {
            selectedOptionName = op.name;
          }
        }
      }

      let info = {
        id: this.productInfo.id,
        name: this.productInfo.name,
        price: this.productInfo.price,
        shopName: this.shopInfo.name,
        shopId: this.shopInfo.id,
        options: this.productInfo.options,
        images: this.productInfo.images,
        selectedOptionName,
      };

      this.$store.dispatch('cart/addItem', {
        product: info,
        option: this.selectedProductOption,
        quantity: this.quantity,
        affiliateCode: this.affiliateCode,
      }).then(() => {
        this.$toast.show('カートに追加しました');
      }).catch((err) => {
        this.$toast.error(err.message);
      });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    generateAffiliateLink() {
      let baseUrl = process.env.VUE_APP_BASE_URL;
      let affiliateLink = this.$router.resolve(
        {
          name: 'product-detail',
          params: { id: this.productInfo.id },
          query: { affiliate: this.loggedInUser.affilicateCode },
        },
      );
      navigator.clipboard.writeText(`${baseUrl}${affiliateLink.fullPath}`);
      this.$toast.show('アフィリエイトリンクをコピーしました');
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
};
</script>
<style>
.dummy {
  margin-top: 90%;
}
.shop-introduction img{
  height: auto !important;
}
.product-introduction pre{
  white-space: pre-line;
}
@media (max-width:767px) {
 .img-product-cart{
   min-width: 64px;
   max-width: 64px;
 }
 .select-quantity-mb{
   min-width: 80px;
   max-width: 80px;
 }
}
</style>
