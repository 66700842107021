<template>
  <div>
    <div class="selected-img relative">
      <img :src="selected"  class="w-full object-cover"/>
      <div class="absolute w-full h-full top-0 left-0">
        <slot name="overlay"></slot>
      </div>
    </div>
    <div class="grid grid-cols-4 md:gap-4 gap-2 mt-3">
      <div v-for="(img, index) in images" :key="index" class="relative"
        @click="select(img.src)"
      >
        <div class="absolute w-full h-full bg-gray-900 opacity-75 z-10"
          v-if="selected !== img.src"
        ></div>
        <div class="square_img">
          <img
          class="w-full h-full object-cover"
          :src="img.src"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selected: '',
  }),
  watch: {
    images(val) {
      if (val) {
        this.selected = val[0].src;
      }
    },
  },
  methods: {
    select(src) {
      this.selected = src;
    },
  },
};
</script>

<style>
.img-list img {
  object-fit: cover;
  height: 120px;
  width: auto;
}
.selected-img, .square_img{
  position: relative;
  overflow: hidden;
}
.selected-img img, .square_img img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.selected-img:after, .square_img::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
@media (max-width:767px) {
  .img-list img {
    height: 60px;
  }
}
</style>
