<template>
  <div class="w-24 mx-auto my-8">
    <g-button @click="back()">戻る</g-button>
  </div>
</template>

<script>
import GButton from './GButton.vue';

export default {
  components: { GButton },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>

</style>
