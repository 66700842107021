<template>
  <div>
    <div
      v-if="!isSend"
      class="
        container
        mx-auto
        lg:pt-52
        pt-32
      "
    >
      <div
        class="
          md:max-w-screen-md
          max-w-screen-md
          mx-auto
          mb-8
          px-10
        "
      >
      <p
        class="
          w-full
          lg:text-lg
          text-opacity-50
          text-center
        "
      >
        登録のメールアドレスを入力して、パスワードをリセットします
      </p>
      </div>

      <div
        class="
          md:max-w-screen-md
          max-w-screen-md
          mx-auto
          mt-8
          px-10
        "
      >
      <g-form ref="form">
        <InputField
          label="メールアドレス"
          v-model="userEmail"
          required
          type="email"
        />
        <p class="text-left text-lg text-red-600">{{error}}</p>
      </g-form>
      </div>

      <div
        class="
          lg:my-10
          w-52
          mx-auto
          mt-12
        "
      >
        <button
          type="submit"
          class="
            w-full
            h-full
            text-white
            p-2
            bg-black-p-btn
            rounded-lg
          "
          @click.prevent="sendResetRequestToUser()"
        >
          パスワードリセット
        </button>
      </div>

      <go-back-btn></go-back-btn>
    </div>

    <div
      v-if="isSend"
      class="
        container
        mx-auto
        lg:pt-64
        pt-32
        px-4
        text-center
      "
    >
      <p
        class="
          text-2xl
          text-opacity-100
          tracking-wider
          leading-relaxed
        "
      >
        パスワードリセットメールをお送りしました
      </p>

      <p
        class="
          mt-12
          text-xs
          md:text-base
          text-opacity-50
          tracking-wider
          leading-loose
        "
      >
        パスワードリセットメールを送信しましたので<br>
        メール内のリンクから新しいパスワードを設定してください<br>
        メールが届かない場合はお問い合わせください
      </p>

      <div
        class="
          lg:w-48
          md:w-30
          lg:mb-32
          lg:mt-14
          w-40
          mx-auto
          my-14
        "
      >
        <g-button
          :disabled="error.length > 0"
          @click="$router.push('/')"
        >トップページへ</g-button>
      </div>
    </div>
  </div>
</template>

<script>
import GForm from '../../components/common/GForm.vue';
import { AuthService } from '../../services/AuthService';

// import InputField from '../../components/common/InputField.vue';

export default {
  name: 'SendResetPasswordEmail',
  components: {
    GForm,
    // InputField,
  },
  data: () => ({
    isProcessing: false,
    userEmail: '',
    isSend: false,
    error: '',
  }),
  watch: {
    userEmail() {
      this.error = '';
    },
  },
  methods: {
    async sendResetRequestToUser() {
      if (!this.$refs.form.isValid() || this.isProcessing) return;

      this.isProcessing = true;
      if (this.userEmail) {
        AuthService.passwordRecovery(this.userEmail).then(() => {
          this.isSend = true;
          window.scrollTo(0, 0);
        }).catch(() => {
          this.error = 'メールアドレスが存在しません';
        }).finally(() => {
          this.isProcessing = false;
        });
      }
    },
  },
};
</script>
