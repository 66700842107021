<template>
    <div class="container mx-auto md:px-3 px-5 compensation-top-nav">
      <UserTabs :activeMyFavoriteProducts="true"/>
      <div v-if="favoriteProducts.length == 0" class="w-full text-center text-xl flex
      h-80 justify-center items-center">
        お気に入りに登録されている商品がありません。
      </div>
      <div v-else
        class="
          w-full
          grid
          lg:grid-cols-3
          grid-cols-2 wrap_ProductShop"
      >
        <div class="ProductShop"
          v-for="(product, index) in favoriteProducts"
          :key="index"
        >
          <ProductCard
            :product="product"
            showFavoriteStatus
          />
        </div>
      </div>

      <Paginate v-model="page" :totalPage="totalPage"></Paginate>
    </div>
</template>

<script>
import { ProductService } from '../../services';
import UserTabs from '../../components/UserPage/UserTabs.vue';
import ProductCard from '../../components/ProductCard.vue';
import Paginate from '@/components/common/Paginate.vue';

const PRODUCT_PER_PAGE = process.env.VUE_APP_ITEM_PER_PAGE;

export default {
  name: 'MyFavoriteProducts',
  components: {
    UserTabs,
    ProductCard,
    Paginate,
  },
  data: () => ({
    favoriteProducts: [],
    page: 1,
    totalPage: 1,
  }),
  watch: {
    page() {
      this.getProductInCurrentPage();
    },
  },
  created() {
    this.getProductInCurrentPage();
  },
  methods: {
    getProductInCurrentPage() {
      ProductService.getFavoriteProduct(this.page, PRODUCT_PER_PAGE).then((res) => {
        this.favoriteProducts = res.items;
        this.totalPage = res.totalPage;
      });
    },
  },

};
</script>
