<template>
  <div class="w-full">
    <div class="container px-4 md:px-3 mx-auto">
      <h3 v-if="title" class="md:text-5xl text-xl text-center md:mb-8 mb-4">{{ title }}</h3>

      <!-- PC View -->
      <div
        class="
          w-full
          hidden
          lg:grid
          lg:grid-cols-3
          md:gap-x-12
          md:gap-y-20
          gap-4
        "
      >
        <div
          v-for="(product, index) in products"
          :key="index"
        >
          <ProductCard
            :product="product"
          />
        </div>
      </div>

      <!-- Mobile view -->
      <div class="w-full lg:hidden">
        <carousel :items-to-show="1" :autoplay="5000" :wrap-around="true">
          <slide v-for="i in Math.ceil(products.length/2)" :key="i">
            <div class="flex w-full">
                <ProductCard class="w-1/2 mx-1"
                  v-if="products[i*2-2]"
                  :product="products[i*2-2]"
                />
                <ProductCard class="w-1/2 mx-1"
                  v-if="products[i*2-1]"
                  :product="products[i*2-1]"
                />
            </div>
          </slide>
          <template #addons>
            <pagination />
          </template>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Carousel,
  Slide,
  Pagination,
} from 'vue3-carousel';
import ProductCard from './ProductCard.vue';
import 'vue3-carousel/dist/carousel.css';
// import GButton from './common/GButton.vue';

export default {
  name: 'ListProductCard',
  props: {
    title: {
      type: String,
    },
    products: {
      type: Array,
    },
  },
  components: {
    ProductCard,
    Carousel,
    Slide,
    Pagination,
    // GButton,
  },
};
</script>
<style>
.carousel {
  display: block;
}
.carousel__pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 20px;
}
.carousel__pagination-button {
  margin: var(--vc-pgn-margin);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 1px solid #707070;
  cursor: pointer;
  background-color: #fff;
}

.carousel__pagination-button--active {
  background-color: #707070;
}
</style>
