<template>
  <div class="form-control user-infor">
    <label v-if="label" class="mb-2 text-opacity-70">
      <span>{{ label }}</span>
      <span v-if="showRequiredLabel" class="text-red-600 text-xs">*必須</span>
    </label>
    <textarea
      v-model="value"
      @input="valueChanged"
      class="
        border
        border-black-p-btn
        border-opacity-50
        p-2
        h-80
        w-full"
      placeholder=""
    ></textarea>
    <p class="text-left text-red-600 text-sm">{{ this.errors.join(" ") }}</p>
  </div>
</template>

<script>

export default {
  name: 'TextAreaField',
  inject: ['register', 'unregister'],
  props: {
    label: String,
    modelValue: String,
    required: {
      type: Boolean,
    },
    showRequiredLabel: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    value: '',
    errors: [],
  }),
  created() {
    if (this.register) this.register(this);
  },
  mount() {
    if (this.modelValue) this.value = this.modelValue;
  },
  methods: {
    valueChanged() {
      this.validate();
      this.$emit('update:modelValue', this.value);
    },

    validate() {
      this.clearErrors();
      if (this.rules) {
        // eslint-disable-next-line no-restricted-syntax
        for (let r of this.rules) {
          let err = r(this.value);
          if (err !== true) this.errors.push(err);
        }
      }

      if (this.required && !this.value) {
        if (this.label) {
          this.errors.push(`${this.label}必須項目です。`);
        } else {
          this.errors.push('必須項目です。');
        }
      }
    },

    clearErrors() {
      this.errors = [];
    },
  },
};
</script>
