<template>
  <div class="lg:container mx-auto compensation-top-nav px-3 pb-10">
    <UserTabs :activeMyOrder="true"/>
    <div v-if="orders.length > 0" class="overflow-x-auto pr-1">
      <TableOrder :orders="orders"/>
    </div>
    <div v-else class="w-full text-center text-xl
    h-80 flex justify-center items-center">オーダーがありません</div>

    <Paginate v-model="page" :totalPage="totalPage"></Paginate>

    <div class="container xl:max-w-7xl mx-auto lg:mt-20 mt-8 lg:mb-16 m-10">
        <div class="md:w-3/4 w-full mx-auto">
          <Banner v-if="systemSetting.banners[5]"
            :image="systemSetting.banners[5].image" :link="systemSetting.banners[5].link" />
        </div>
      </div>
  </div>
</template>

<script>
import UserTabs from '../../components/UserPage/UserTabs.vue';
import TableOrder from '../../components/UserPage/TableOrder.vue';
import { OrderService } from '@/services';
import { systemSetting } from '@/helpers';
import Banner from '@/components/Banner.vue';
import Paginate from '@/components/common/Paginate.vue';

const ITEM_PER_PAGE = process.env.VUE_APP_ITEM_PER_PAGE;

export default {
  name: 'MyOrders',
  components: {
    UserTabs,
    TableOrder,
    Banner,
    Paginate,
  },
  data: () => ({
    orders: [],
    page: 1,
    totalPage: 1,
  }),
  computed: {
    ...systemSetting,
  },
  watch: {
    page() {
      this.getOrderInCurrentPage();
    },
  },
  mounted() {
    this.getOrderInCurrentPage();
  },
  methods: {
    getOrderInCurrentPage() {
      OrderService.getMyOrder(this.page, ITEM_PER_PAGE).then((res) => {
        this.orders = res.items;
        this.totalPage = res.totalPage;
      });
    },
  },
};
</script>
