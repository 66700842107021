import { OrderAdapter, Order } from './OrderAdapter';
import { DeliveryAddressAdapter, DeliveryAddress } from './DeliveryAddressAdapter';

export class Shipment {
  constructor() {
    this.id = '';
    this.address = new DeliveryAddress();
    this.paidDate = null;
    this.status = '';
    this.order = new Order();
  }
}

function convertFromRes(obj) {
  let shipment = new Shipment();
  shipment.id = obj.id;
  shipment.address = DeliveryAddressAdapter.convertFromResponse(obj.delivery_address);
  shipment.paidDate = new Date(obj.paid_date);
  shipment.order = OrderAdapter.convertFromResponse(obj.order);
  shipment.status = obj.status;
  return shipment;
}

export const ShipmentAdapter = {
  convertFromRes,
};
