<template>
  <div class="w-full h-full compensation-top-nav lg:mb-32 mb-10">
    <div class="lg:container mx-auto px-3 p-static">
        <h1 class="md:text-4xl text-2xl md:pt-14 text-center">News</h1>
        <p class="md:text-lg md:pt-6 md:mb-16 mb-6 text-center">Weeatからのお知らせ・新着情報です</p>
        <!-- News -->
        <div class="lg:container px-5 md:px-3 mx-auto">
          <div v-if="listNews.length > 0"
            class="w-full mb-10 grid lg:grid-cols-3 grid-cols-2 gap-4 md:gap-10 2xl:gap-12">
            <NewsItem v-for="n in listNews" :key="n.id" :news="n"/>
          </div>
          <div v-else class="w-full text-center text-xl md:mb-24 mb-10">Newsがありません</div>
        </div>
    </div>
  </div>
</template>

<script>
import { NewsService } from '@/services';
import NewsItem from '../components/common/NewsItem.vue';

export default {
  name: 'NewsList',
  components: {
    NewsItem,
  },
  data: () => ({
    listNews: [],
    page: 1,
  }),
  mounted() {
    NewsService.getNews().then((res) => {
      this.listNews = res;
    });
  },
};
</script>

<style>

</style>
