 <template>
  <tr class="border-b-2">
    <td class="py-2 lg:py-3 hidden md:table-cell">
      <button class="btn-circle" @click="removeItem()">
        <svg xmlns="http://www.w3.org/2000/svg"
          fill="none" viewBox="0 0 24 24"
          class="inline-block w-6 h-6 stroke-current">
          <path stroke-linecap="round"
            stroke-linejoin="round" stroke-width="2"
            d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </td>

    <td class="py-4 md:py-8 flex">
      <router-link :to="{ name: 'product-detail', params: { id: info.product.id }}"
        class="flex-none"
      >
        <img
          class="md:h-20 md:w-20 w-16 h-16 object-cover img-product-cart"
          :src="productThumb"
          alt=""
        />
      </router-link>
      <div class="pl-3">
        <router-link :to="{ name: 'product-detail', params: { id: info.product.id }}">
          <p class="md:text-base text-sm line-clamp-2">{{info.product.name}}</p>
          <p class="md:text-base text-sm">{{info.product.selectedOptionName}}</p>
          <small>{{$filters.formatCurrency(info.product.price)}}円(税込）</small><br />
        </router-link>
        <div class="overme"><small>{{info.product.shopName}}</small></div>
        <!-- <input class="md:hidden block border border-black-p-btn
        border-opacity-50 outline-none w-full p-2"
          type="number"
          max=50
          v-model="info.quantity"/> -->
        <select v-model="info.quantity"
          class="md:hidden block border border-black-p-btn
          border-opacity-50 outline-none w-full p-2 select-quantity-mb"
        >
          <option v-for="i in 50" :key="i" :value="i">{{i}}</option>
        </select>
      </div>
    </td>
    <td class="py-4 md:py-8 hidden md:table-cell">
      <!-- <input class="border border-black-p-btn border-opacity-50 outline-none w-full p-2"
        type="number"
        max=50
        v-model="info.quantity"/> -->
      <select v-model="info.quantity"
        class="border border-black-p-btn border-opacity-50 outline-none w-full p-2"
      >
        <option v-for="i in 50" :key="i" :value="i">{{i}}</option>
      </select>
    </td>
    <td class="py-4 md:py-8 md:pl-4 pl-1 text-right md:text-left text-sm">
       <button class="btn-circle mb-6 w-full md:hidden block " @click="removeItem()">
        <svg xmlns="http://www.w3.org/2000/svg"
          fill="none" viewBox="0 0 24 24"
          class="inline-block w-6 h-6 stroke-current">
          <path stroke-linecap="round"
            stroke-linejoin="round" stroke-width="2"
            d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
      {{$filters.formatCurrency(info.product.price*info.quantity)}}円(税込）
    </td>
  </tr>
</template>

<script>
import { systemSetting } from '@/helpers';

export default {
  name: 'CartItem',
  computed: {
    ...systemSetting,
    productThumb() {
      for (let image of this.info.product.images) {
        if (image.is_main) {
          return image.src;
        }
      }
      return '';
    },
  },
  props: {
    info: {
      type: Object,
      deafult: () => {},
    },
  },
  data: () => ({

  }),

  watch: {
    'info.quantity': {
      // immediate: true,
      handler(after, before) {
        if (after >= 1) {
          this.$store.dispatch('cart/changeQuantity',
            { product: this.info.product, option: this.info.option, quantity: after });
        } else if (window.confirm('この商品を削除してもよろしいですか')) {
          this.removeItem();
        } else {
          this.info.quantity = before;
        }
      },
    },
  },
  methods: {
    removeItem() {
      this.$store.dispatch('cart/removeItem', { product: this.info.product, option: this.info.option });
    },
  },
};
</script>
<style>
.select-quantity-mb{
   max-width: 150px;
 }
@media (max-width:767px) {
 .img-product-cart{
   min-width: 64px;
   max-width: 64px;
 }
 .select-quantity-mb{
   min-width: 80px;
   max-width: 80px;
 }
}
</style>
