/* eslint no-shadow: ["error", { "allow": ["state","getters"] }] */
import { ProductService } from '@/services';

const CART_ITEM_KEY_SEP = '_';

function genKey(product, option) {
  return `${product.id}${CART_ITEM_KEY_SEP}${option}`;
}
class CartItem {
  constructor(product, option, quantity, affiliateCode) {
    this.product = product;
    this.option = option;
    this.quantity = quantity;
    this.shippingAddress = { addressBookId: '' };
    this.affiliateCode = affiliateCode;
  }
}

const cartState = {
  items: new Map(),
  coupon: {},
};

const mutations = {
  SET_COUNPON(state, data) {
    state.coupon = { ...data.coupon };
  },
  CLEAR_COUNPON(state) {
    state.coupon = {};
  },
  ADD_ITEM(state, {
    product, option, quantity, affiliateCode,
  }) {
    state.items.set(genKey(product, option),
      new CartItem(product, option, quantity, affiliateCode));
  },
  REMOVE_ITEM(state, { product, option }) {
    state.items.delete(genKey(product, option));
  },
  SET_QUANTITY(state, { product, option, quantity }) {
    let key = genKey(product, option);

    if (!state.items.has(key)) return;

    let cardItem = state.items.get(key);
    cardItem.quantity = quantity;
  },
  SET_ITEM_SHIPPING_ADDRESS(state, { product, option, address }) {
    let cartItem = state.items.get(genKey(product, option));
    if (cartItem) {
      cartItem.shippingAddress = { ...address, addressBookId: '' };
    }
  },
  CLEAR(state) {
    state.items.clear();
  },
};

const actions = {
  getCoupon({ commit }, coupon) {
    return new Promise((resolve) => {
      commit('SET_COUNPON', coupon);
      resolve();
    });
  },
  clearCoupon({ commit }) {
    commit('CLEAR_COUNPON');
  },
  addItem({ state, commit }, {
    product, option, quantity, affiliateCode,
  }) {
    return new Promise((resolve, reject) => {
      let id = genKey(product, option);
      let cartItem = state.items.get(id);

      if (cartItem) {
        if (cartItem.quantity + quantity > 50) {
          reject(new Error('一度に購入できる最大購入個数を超えています'));
          return;
        }
        commit('SET_QUANTITY', {
          product,
          option,
          quantity: cartItem.quantity + quantity,
        });
      } else {
        commit('ADD_ITEM', {
          product, option, quantity, affiliateCode,
        });
      }

      resolve();
    });
  },
  removeItem({ commit }, { product, option }) {
    return new Promise((resolve) => {
      commit('REMOVE_ITEM', { product, option });
      resolve();
    });
  },
  changeQuantity({ commit }, { product, option, quantity }) {
    return new Promise((resolve) => {
      commit('SET_QUANTITY', { product, option, quantity });
      resolve();
    });
  },
  clear({ commit }) {
    commit('CLEAR');
    localStorage.removeItem('cart');
  },
  loadCartData({ state }) {
    let localData = JSON.parse(localStorage.getItem('cart'));

    if (!localData) return;
    let localCartItems = new Map(Object.entries(localData));

    let productIds = Array.from(localCartItems.keys())
      .map((key) => key.split(CART_ITEM_KEY_SEP)[0]);

    ProductService.getProductDetailFromIds(productIds).then((products) => {
      for (let [key, value] of localCartItems) {
        let found = false;
        for (let p of products) {
          if (p.id === value.product.id && p.status === 'ON_SALE') {
            found = true;
            p.shopName = value.product.shopName;
            p.selectedOptionName = value.product.selectedOptionName;
            value.product = p;
            break;
          }
        }

        if (!found) {
          localCartItems.delete(key);
        }
      }

      state.items = localCartItems;
    });
  },
  saveCartData2Local({ state }) {
    if (state.items.size === 0) {
      localStorage.removeItem('cart');
    } else {
      localStorage.setItem('cart', JSON.stringify(Object.fromEntries(state.items)));
    }
  },
  setItemShippingAdd({ commit }, { product, option, address }) {
    commit('SET_ITEM_SHIPPING_ADDRESS', { product, option, address });
  },
};

const getters = {
  coupon(s) {
    return { ...s.coupon };
  },
  cartItems(s) {
    return Array.from(s.items.values());
  },
  cartTotalPrice(state, getters) {
    let total = 0;
    for (let item of getters.cartItems) {
      total += item.product.price * item.quantity;
    }
    return total;
  },
};

export const Cart = {
  state: cartState,
  mutations,
  actions,
  getters,
  namespaced: true,
};
