<template>
    <!-- PC transaction list -->
    <div class="lg:block hidden">
      <table v-if="transactions.length > 0"
      class="table-auto text-left
      lg:my-10 my-6 border-collapse w-full mb-6">
        <thead>
          <tr>
            <th class="text-left">日付</th>
            <th class="text-left">注文番号</th>
            <th class="text-left">内訳</th>
            <th class="text-left">販売価格</th>
            <th class="text-left">報酬・引出</th>
            <th class="text-left">合計</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in transactions" :key="index">
            <td class="border p-3">{{$filters.formatJpDatetime(item.executedDate)}}</td>
            <td class="border p-3">{{item.isWithdraw ? '-' : item.orderId}}</td>
            <td class="border p-3">{{item.memo}}</td>
            <td class="border p-3">
              {{item.isWithdraw ? '' : `${$filters.formatCurrency(item.productPrice)}円`}}
            </td>
            <td class="text-left border p-3">{{$filters.formatCurrency(item.amount)}}円</td>
            <td class="border p-3">{{$filters.formatCurrency(item.balance)}}円</td>
          </tr>
        </tbody>
      </table>
      <paginate :totalPage="totalPage" v-model="page"></paginate>
    </div>

    <!-- Mobile transaction list -->
    <div class="flex flex-col md:hidden min-w-full">
      <div class="overflow-x-auto">
        <div class="align-middle inline-block min-w-full">
          <div class="overflow-hidden">
            <table v-if="transactions.length > 0"
            class="table-auto text-left
            lg:my-10 my-6 border-collapse border table-home w-full mb-6">
              <thead>
                <tr>
                  <th>注文日/No.</th>
                  <th>商品</th>
                  <th>販売価格</th>
                  <th>報酬/引出</th>
                  <th>合計残高</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in transactions" :key="index">
                  <td>
                    {{$filters.formatJpDatetime(item.executedDate)}}<br/>
                    {{item.isWithdraw ? '-' : item.orderId}}
                  </td>
                  <td>{{item.memo}}</td>
                  <td>
                    {{item.isWithdraw ? '' : `${$filters.formatCurrency(item.productPrice)}円`}}
                  </td>
                  <td>{{$filters.formatCurrency(item.amount)}}円</td>
                  <td>{{$filters.formatCurrency(item.balance)}}円</td>
                </tr>
              </tbody>
            </table>
            <paginate :totalPage="totalPage" v-model="page"></paginate>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { AffiliateService } from '@/services';
import Paginate from '@/components/common/Paginate.vue';

const ITEM_PER_PAGE = process.env.VUE_APP_ITEM_PER_PAGE;

export default {
  components: {
    Paginate,
  },
  data: () => ({
    transactions: [],
    page: 1,
    totalPage: 1,
  }),
  watch: {
    page() {
      this.loadTransaction();
    },
  },
  mounted() {
    this.loadTransaction();
  },
  methods: {
    loadTransaction() {
      AffiliateService.getTransactionHistory(this.page, ITEM_PER_PAGE)
        .then((res) => {
          this.transactions = res.items;
          this.totalPage = res.totalPage;
        });
    },
  },
};
</script>

<style>

</style>
