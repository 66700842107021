<template>
  <div class="w-full h-full compensation-top-nav">
    <div v-if="!showSuccessMsg">
      <div  class="lg:container mx-auto px-3 p-static">
        <h1 class="text-3xl md:text-4xl mb-8 md:mb-16 md:pt-14">
          Weeatでは出店いただくお店を募集しております
        </h1>
        <article class="md:text-base leading-7">
          <p class="lg:mb-10 mb-6">
            Weeat（ウィート）の飲⾷系サブスクリプションプラットフォームは想いを届ける、想いを繋ぐことにこだわります。<br />
            <br />
            作り⼿から消費者へはもちろん、サブスクリプションの購⼊者⾃⾝が届けたい⼈へ⾃分が魅⼒を感じている商品を家族や友⼈、
            お世話になった⽅など、⼤切な誰かに贈ることができるサービスです。<br />
            <br />
            Weeatのサブスクリプションモデルは、⽉1回全店舗共通の⽇付でサブスクリプションフィーをお客様よりいただき、
            その時点で指定されている送付先に、特定の⽇付までの間にまとめて商品を発送いただくもので、毎⽉決済が済んだオーダーを確認してから発送していただきます。
            <br />
            <br />
            Weeatのプラットフォームへの掲載は初期費⽤も⽉額固定費もかかりません。<br />
            売り上げに対する⼿数料・アフィリエイト経由での購⼊への⼿数料のみでご利⽤いただけます。<br />
            また、多くのモールで実施されているプラットフォームへの縛りがありませんので、サブスクで気に入っていただいた商品の再購入や、
            ⾃社サイトで追加の販売やSNSやリアル店舗への誘導も⾃由に⾏っていただけます。
          </p>
          <div class="w-full bg-green-footer-bg text-white py-3 px-4 text-center">
            Weeatのこだわり
          </div>
          <div class="md:px-6 py-4">
            <p class="lg:mb-10 mb-6">
              Weeatは、⽣産する⼈・販売する⼈・買う⼈・送る⼈・貰う⼈、全ての幸せを追求します。<br />
              フードロス対策の課題への取り組みから始まったこのプロジェクトは、様々な社会課題解決への取組も積極的に⾏っていきます。
            </p>
            <div class="grid grid-cols-4 gap-4 md:gap-10">
              <div class="col-span-4 sm:col-span-2 lg:col-span-1 text-center">
                <img src="../assets/images/1.png" alt="" class="mx-auto mb-4" />
                <h3 class="text-lg mb-4">全⽅向が良いサブスク</h3>
                <p class="md:px-0 px-4 md:text-sm">
                  素材の⽣産者、販売店、お客様 全てを笑顔にできるサービスを⽬指し
                  みんながワクワクできるフード系
                  サブスクリプションのプラットフォーム
                  として出来ることを追求します。
                </p>
              </div>
              <div class="col-span-4 sm:col-span-2 lg:col-span-1 text-center">
                <img src="../assets/images/2.png" alt="" class="mx-auto mb-4" />
                <h3 class="text-lg mb-4">売り⼿の無駄を無くす</h3>
                <p class="md:px-0 px-4 md:text-sm">
                  ⾒た⽬で廃棄される⾷材を活⽤した
                  加⼯⾷品の開発への取り組みを計画 また、そのような事業を運営する
                  ⽅々に協⼒していきます。
                </p>
              </div>
              <div class="col-span-4 sm:col-span-2 lg:col-span-1 text-center">
                <img src="../assets/images/3.png" alt="" class="mx-auto mb-4" />
                <h3 class="text-lg mb-4">⾷品ロスを減らす</h3>
                <p class="md:px-0 px-4 md:text-sm">
                  購⼊者の⽀払いを受けて１週間後 ぐらいの商品発送になるため
                  無駄な仕⼊れがなくなります。 また、ストック型ビジネスのため
                  売上予測が⽴てやすい利点があります。
                </p>
              </div>
              <div class="col-span-4 sm:col-span-2 lg:col-span-1 text-center">
                <img src="../assets/images/4.png" alt="" class="mx-auto mb-4" />
                <h3 class="text-lg mb-4">間接的・直接的⽀援</h3>
                <p class="md:px-0 px-4 md:text-sm">
                  売り上げの1部（注⽂ごとに10円） を貧困問題に取り組む団体に寄付
                  また、これまで廃棄されてきた⾷材を
                  活⽤し、直接的な⽀援も⾏えるような 取り組みを考えていきます。
                </p>
              </div>
            </div>
          </div>
          <div class="w-full bg-green-footer-bg text-white py-3 px-4 text-center">
            操作の簡単さにこだわっています
          </div>
          <div class="md:px-6 py-4">
            <p class="lg:mb-10 mb-6">
              お店を運営する担当者さんが、直感的な操作ができるような作りにこだわっています。<br />
              出店しても、ページ作るのが⼤変そう、パソコンは苦⼿なので不安など、普段のお仕事と異なる分野への進出には不安があると思いますが、パソコンに詳しくなくても⼤丈夫です。
              <br />
              <br />
              <br />
              操作画面はパソコンのみとはなりますが、専⽤の⾃社ページの制作も不要で、お店の紹介文や販売するアイテムのお写真と説明とお値段を⽤意いただければカンタンな登録で出品可能です。
            </p>
          </div>
          <div class="w-full bg-green-footer-bg text-white py-3 px-4 text-center">
            費用のご案内
          </div>
          <div class="md:px-6 py-4">
            <p class="text-center lg:mb-10 mb-6">
              Weeatへの出店は完全無料、手数料は売れた分だけなのでノーリスクでスタートできます。
            </p>
            <div class="grid grid-cols-2 gap-4 md:gap-10">
              <div class="col-span-2 lg:col-span-1 text-center">
                <div class="grid grid-cols-2 gap-4 md:gap-6">
                  <div class="col-span-1 text-center">
                    <div class="shadow rounded-2xl py-4 md:py-8 px-3 h-full">
                      <div class="m-auto">
                        <img src="../assets/images/ICON1.png" alt=""
                        class="mx-auto mb-4 w-1/3 lg:w-auto" />
                      </div>
                      <h3 class="lg:text-2xl">初期費用</h3>
                      <p class="text-red-800 lg:text-2xl">0円</p>
                    </div>
                  </div>
                  <div class="col-span-1 text-center">
                    <div class="shadow rounded-2xl py-4 md:py-8 px-3 h-full">
                      <div class="m-auto">
                        <img  src="../assets/images/ICON2.png" alt=""
                        class="mx-auto mb-4 w-1/3 lg:w-auto" />
                      </div>
                      <h3 class="lg:text-2xl">月額固定費</h3>
                      <p class="text-red-800 lg:text-2xl">0円</p>
                    </div>
                  </div>
                  <div class="col-span-1 text-center">
                    <div class="shadow rounded-2xl py-4 md:py-8 px-3 h-full">
                      <div class="m-auto">
                        <img src="../assets/images/ICON3.png" alt=""
                        class="mx-auto mb-4 w-1/3 lg:w-auto" />
                      </div>
                      <h3 class="lg:text-2xl">販売手数料</h3>
                      <p class="text-red-800 lg:text-2xl">10~15%</p>
                      <span class="text-xs">取扱商材等により変動いたしますので 個別にお問い合わせください。</span>
                    </div>
                  </div>
                  <div class="col-span-1 text-center">
                    <div class="shadow rounded-2xl py-4 md:py-8 px-3 h-full">
                      <div class="m-auto">
                        <img src="../assets/images/ICON4.png" alt=""
                        class="mx-auto mb-4 w-1/3 lg:w-auto" />
                      </div>
                      <h3 class="lg:text-2xl">紹介報酬</h3>
                      <p class="text-red-800 lg:text-2xl">3%</p>
                      <span class="text-xs">※紹介経由の場合のみ毎⽉紹介報酬がかかります。</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-2 lg:col-span-1">
                <div class="w-full bg-green-footer-bg text-white py-3 px-4
                md:text-left text-center">
                  シミュレーション
                </div>
                <p class="my-4 md:text-xl">手数料12%で、4,900円の商品が350個売れ、うち50件が紹介の場合</p>
                <table class="w-full text-center table-auto border-collapse">
                  <tbody>
                    <tr class="bg-green-50">
                      <td class="border border-green-footer-bg py-3 px-4">商品価格</td>
                      <td class="border border-green-footer-bg">4,900円</td>
                    </tr>
                    <tr class="">
                      <td class=" border border-green-footer-bg py-3 px-4">通常注文</td>
                      <td class="border border-green-footer-bg">300件</td>
                    </tr>
                    <tr class="bg-green-50">
                      <td class="border border-green-footer-bg py-3 px-4">紹介経由注文</td>
                      <td class="border border-green-footer-bg">50件</td>
                    </tr>
                    <tr class="">
                      <td class="border border-green-footer-bg py-3 px-4">総売り上げ</td>
                      <td class="border border-green-footer-bg">1,715,000円</td>
                    </tr>
                    <tr class="bg-green-50">
                      <td class="border border-green-footer-bg py-3 px-4">紹介報酬（3％）</td>
                      <td class="border border-green-footer-bg">-7,350円</td>
                    </tr>
                    <tr class="">
                      <td class="border border-green-footer-bg py-3 px-4">販売手数料（12%) </td>
                      <td class="border border-green-footer-bg">-205,800円</td>
                    </tr>
                  </tbody>
                </table>
                <p class="my-4 md:text-xl text-center">お店の受け取り売上 / 1,501,850円</p>
              </div>
            </div>
          </div>
          <div class="w-full bg-green-footer-bg text-white py-3 px-4 text-center">
            お申し込みから出店の流れ
          </div>
          <div class="md:px-6 py-4">
            <p class="lg:mb-10 mb-6 pt-3 text-center">
            通常はお申し込みから出店まで、１〜２週間を予定しております
            </p>
            <div class="grid grid-cols-4 gap-4 md:gap-10">
              <div class="col-span-2 lg:col-span-1 text-center">
                <img src="../assets/images/e1.png" alt="" class="mx-auto mb-3" />
                <h3 class="md:text-lg mb-1">お申し込み</h3>
                <p class="md:px-0 px-4 md:text-sm text-center">
                必要事項の⼊⼒・書類提出<br/>
  オンラインでお申し込みいただけます
                </p>
              </div>
              <div class="col-span-2 lg:col-span-1 text-center">
                <img src="../assets/images/e2.png" alt="" class="mx-auto mb-3" />
                <h3 class="md:text-lg mb-1">出店審査</h3>
                <p class="md:px-0 px-4 md:text-sm">
                  いただいた書類等をもとに<br/>
                  出店審査を⾏います

                </p>
              </div>
              <div class="col-span-2 lg:col-span-1 text-center">
                <img src="../assets/images/e3.png" alt="" class="mx-auto mb-3" />
                <h3 class="md:text-lg mb-1">アカウント発⾏</h3>
                <p class="md:px-0 px-4 md:text-sm">
                  出店審査が終わりましたら<br/>
                  お店のアカウントを発⾏いたします
                </p>
              </div>
              <div class="col-span-2 lg:col-span-1 text-center">
                <img src="../assets/images/e4.png" alt="" class="mx-auto mb-3" />
                <h3 class="md:text-lg mb-1">お店開店</h3>
                <p class="md:px-0 px-4 md:text-sm">
                  商品やお店情報を登録し<br/>
                  お店をオープンさせてください
                </p>
              </div>
            </div>
            <p class="md:text-base my-6 md:my-10">
            必要書類について<br/>
            【法人】<br/>
            &nbsp; 登記簿謄本（履歴事項全部証明書）のスキャンデータ / 印鑑証明（共に契約開始日より3ヶ月以内に発行されたもの）
            <br/>
            <br/>
            【個人事業主】<br/>
              &nbsp;  住民票 / 印鑑証明書（共に契約開始日より3ヶ月以内に発行されたもの）
            <br/>
            <br/>
            追加で以下の書類が必要となる場合がございます。<br/>
            ・お取扱予定商材の販売に当たって必要な営業許可・資格等の書類（酒類販売、食品営業、医薬品販売等）<br/>
            ・商材の写真
            </p>
          </div>
        </article>
      </div>
      <div  class="xl:max-w-screen-2xl lg:container mx-auto px-3">
        <g-form class="bg-gray-50 p-3 md:p-10 border border-gray-400" ref="form">
          <h3 class="text-center text-xl lg:mb-16 mb-8">出店お申し込みフォーム</h3>
          <div class="mb-6 required">
            <InputField
              class="w-full"
              v-model="ownerName"
              label="出店者名（法人名・個人名）"
              required
            />
          </div>
          <div class="mb-6 required">
            <InputField required
              class="w-full"
              v-model="ceoName"
              label="代表者"
            />
          </div>
          <div class="mb-6 required">
            <InputField
              required
              class="w-full"
              v-model="ceoNameFurigana"
              label="代表者フリガナ"
              type="katakanaOrhiragana"
            />
          </div>
          <div class="mb-6">
            <label class="mb-2 block">
              <span>店舗代表住所</span>
              <span class="text-red-600 text-xs">*必須</span>
            </label>
            <div
              class="
                flex
                lg:w-1/3
                items-center
              "
            >
              <div class="w-1/3">
                <InputField
                  required
                  v-model="ceoCityCode"
                />
              </div>
              <div class="w-5 sm:w-10 mx-2 bg-gray-400" style="height:2px"></div>
              <div>
                <InputField
                  required
                  v-model="ceoDistrictCode"
                />
              </div>
            </div>
          </div>
          <div class="mb-6 required">
            <InputField
              required
              class="w-full"
              v-model="ceoAddress"
            />
          </div>
          <div class="mb-6 required">
            <InputField
              required
              class="w-full"
              v-model="phoneNumber"
              label="電話番号"
              charSet="number"
            />
          </div>
          <div class="mb-6 required">
            <InputField
              required
              class="w-full"
              v-model="email"
              type="email"
              label="メールアドレス"
            />
          </div>
          <div class="mb-6 required">
            <InputField
              required
              class="w-full"
              v-model="name"
              label="店舗名"
            />
          </div>
          <div class="mb-6 required">
            <InputField
              required
              class="w-full"
              v-model="nameFurigana"
              label="店舗名フリガナ"
              type="katakanaOrhiragana"
            />
          </div>
          <div class="mb-6 required">
            <InputField
              required
              class="w-full"
              v-model="managerName"
              label="ショップ責任者"
            />
          </div>
          <div class="mb-6 required">
            <InputField
              required
              class="w-full"
              v-model="managerNameFurigana"
              label="ショップ責任者フリガナ"
              type="katakanaOrhiragana"
            />
          </div>
          <div class="mb-6 required">
            <TextAreaField
              class="w-full h-32"
              v-model="sellingProductIntro"
              label="取扱商材"
              required
            />
          </div>
          <div class="mb-0">
            <!-- <label class="mb-3 block">添付書類</label> -->
            <!-- <div class="px-3">
              <p class="mb-3">登記簿謄本（法人）/ 住民票（個人事業主）</p>
              <label
                id="resident-file-name"
                for="resident_card"
                class="
                  bg-white
                  border
                  text-sm
                  px-4
                  py-2
                  cursor-pointer
                  hover:bg-gray-500
                  hover:text-white
                "
              >
                ファイル選択
              </label>
              <input
                @change="getResidentCard($event)"
                class="hidden"
                id="resident_card"
                type="file"
              >

              <p class="my-3">印鑑証明（法人は法人印 / 個人事業主は個人印）</p>
              <label
                id="business-file-name"
                for="business_license"
                class="
                  bg-white
                  border
                  text-sm
                  px-4
                  py-2
                  cursor-pointer
                  hover:bg-gray-500
                  hover:text-white
                "
              >
                ファイル選択
              </label>
              <input
                class="hidden"
                id="business_license"
                type="file"
                @change="getBusinessLicense($event)"
              >
            </div> -->
            <label class="flex  justify-center mt-6">
              <input
                type="checkbox"
                v-model="acceptPolicy"
                class="form-checkbox text-green-500 h-6 w-6"
              >
              <span class="ml-2">
                <a
                  @click="$router.push('/privacy-policy')"
                  href=""
                  class="underline text-blue-600"
                >
                プライバシーポリシー</a>と
                <a
                  href="https://weeat.twitties8.com/syopkiyaku/"
                  target="_blank"
                  class="underline text-blue-600"
                >
                出店者規約</a>に同意の上申し込むに同意の上申し込む
              </span>
            </label>
            <div class="text-center lg:pt-10  pt-6">
              <button
                type="submit"
                class="btn bg-black-p-btn text-sm md:text-xl lg:px-20 px-10 h-14"
                :disabled="!acceptPolicy"
                @click.prevent="send()"
              > お申し込み
              </button>
            </div>
          </div>
        </g-form>
        <p class="md:text-base my-6 md:my-10">
          出店には所定の審査がございます、審査の結果ご希望に添えない場合は申し訳ございませんがその原因についてお伝えを控えております。<br/>
        なお、審査には２週間〜１ヶ月程度のお時間がかかります。<br/>
        追加で提出をお願いする書類がある場合には、メールにてご案内させていただきますのでよろしくお願いいたします。
        </p>
      </div>
    </div>

    <div v-else
      class="
        container
        mx-auto
        lg:mt-60
        mt-16
        text-center
      "
    >
      <p
        class="
          text-center
          lg:text-3xl
          text-xl
        "
      >
        送信しました
      </p>
      <div
        class="
          lg:w-48
          md:w-30
          lg:mb-32
          lg:mt-14
          w-56
          mx-auto
          my-8
        "
      >
        <button
          type="submit"
          class="
            w-full
            h-full
            text-white
            text-xs
            text-md
            lg:text-lg
            p-3
            py-5
            bg-black-p-btn
            rounded-lg
          "
          @click="$router.push('/')"
        >
          トップページへ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { SystemSettingService } from '../services/SystemSettingService';

// import InputField from '../components/common/InputField.vue';
import TextAreaField from '../components/common/TextAreaField.vue';
// import GForm from '../components/common/GForm.vue';

export default {
  name: 'ApplicationOpenStore',
  components: {
    // InputField,
    TextAreaField,
    // GForm,
  },
  computed: {
    postalCode() {
      return this.ceoCityCode + this.ceoDistrictCode;
    },
  },
  data: () => ({
    isProcessing: false,

    acceptPolicy: false,
    showSuccessMsg: false,

    ownerName: '',
    ceoName: '',
    ceoNameFurigana: '',
    ceoCityCode: '',
    ceoDistrictCode: '',
    ceoAddress: '',
    phoneNumber: '',
    email: '',
    name: '',
    nameFurigana: '',
    managerName: '',
    managerNameFurigana: '',
    sellingProductIntro: '',
    // residentCard: '',
    // businessLicense: '',
  }),
  methods: {
    async send() {
      if (!this.$refs.form.isValid() || this.isProcessing) return;
      this.isProcessing = true;

      const formData = new FormData();
      const payload = {
        owner_name: this.ownerName,
        ceo_name: this.ceoName,
        ceo_name_furigana: this.ceoNameFurigana,
        ceo_address_postcode: this.postalCode,
        ceo_address: this.ceoAddress,
        phone_number: this.phoneNumber,
        email: this.email,
        name: this.name,
        name_furigana: this.nameFurigana,
        manager_name_for_display: this.managerName,
        manager_name_furigana_for_display: this.managerNameFurigana,
        selling_product_intro: this.sellingProductIntro,
        // resident_card: this.residentCard,
        // business_license: this.businessLicense,
      };

      Object.entries(payload).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const res = await SystemSettingService.sendContactFormForOpenNewShop(formData);

      this.isProcessing = false;
      if (res.status === 200) {
        this.showSuccessMsg = true;
        window.scrollTo(0, 0);
      }
    },

    getResidentCard(event) {
      const file = event.target.files[0];
      [this.residentCard] = [file];
      document.getElementById('resident-file-name').innerText = file.name;
    },

    getBusinessLicense(event) {
      const file = event.target.files[0];
      [this.businessLicense] = [file];
      document.getElementById('business-file-name').innerText = file.name;
    },
  },

};
</script>
