<template>
  <div>
  <router-link :to="{ name: 'product-detail', params: { id: product.id }}" class="block_img">
    <div
      class="product-card w-full relative inline-block mb-3"
    >
      <div class="dummy md:mt-64 mt-36"></div>
      <div class="thumb-img absolute inset-0 overflow-hidden">
        <img
          :src="product.image"
          class="object-cover w-full h-full"
        >

        <img
          v-if="product.status === 'STOP'"
          src="@/assets/images/pendding_product.png"
          class="w-full h-full absolute top-0 left-0 object-contain"
        >

        <img
          v-if="showFavoriteStatus && favoriteStatus"
          src="../assets/svg_icon/favorite.svg"
          class="w-8 h-8 absolute bottom-3 right-3"
          @click.prevent="removeFromFavorite()"
        >
        <img
          v-if="showFavoriteStatus && !favoriteStatus"
          src="../assets/svg_icon/heart-favorite.svg"
          class="w-8 h-8 absolute bottom-3 right-3"
          @click.prevent="addToFavorite()"
        >
      </div>
    </div>
  </router-link>

    <div
      class="
        text-center
        md:text-lg
        wrap_info
      "
    >
      <router-link :to="{ name: 'product-detail', params: { id: product.id }}">
        <p class="line-clamp-2 lg:mb-3">
          {{product.name}}
        </p>
      </router-link>

      <router-link :to="{ name: 'shop-products', params: { id: product.shopId }}">
      <p class="line-clamp-1 lg:mb-3">
          {{product.shopName}}
        </p>
      </router-link>

      <p class="line-clamp-1 lg:mb-3">
        ￥{{$filters.formatCurrency(product.price)}}/月
      </p>
    </div>
  </div>
</template>

<script>
import { ProductService } from '../services/ProductService';

export default {
  name: 'ProductCard',
  props: {
    showFavoriteStatus: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => ({
        id: '',
        image: '',
        name: '',
        shopName: '',
        price: '',
      }),
    },
  },

  data: () => ({
    favoriteStatus: true,
  }),

  methods: {
    addToFavorite() {
      ProductService.addFavoriteProduct(this.product.id).then(() => {
        this.favoriteStatus = true;
      });
    },
    removeFromFavorite() {
      ProductService.deleteFavoriteProduct(this.product.id).then(() => {
        this.favoriteStatus = false;
      });
    },
  },
};
</script>
<style scoped>
</style>
