<template>
  <div>
    <div v-if="loggedIn">
      <p class="mb-3">お支払いカード選択</p>
      <select class="w-full mt-2 border bg-white px-4 py-3 outline-none"
        v-model="selectedCard"
      >
          <option value="">新しいカード追加</option>
          <option class="py-3"
          v-for="card in cards" :key="card.paymentMethodId"
          :value="card.paymentMethodId">
          {{card.brand}}＊＊＊＊-＊＊＊＊-{{card.last4digit}}
        </option>
      </select>
    </div>

    <div :class="{ hidden: selectedCard !== '' }" class="mt-6">
      <div class="card-element mb-3 lg:mb-6">
        <p class="mb-1">カード番号</p>
        <div class='card-number bg-white border
        border-black-p-btn border-opacity-50 outline-none w-full p-2'></div>
      </div>

      <div class="flex items-center mb-3 lg:mb-6 gap-4">
        <div class="card-element md:w-1/6 w-1/3">
          <p class="mb-1">有効期限</p>
          <div class='card-expiry bg-white border
          border-black-p-btn border-opacity-50 outline-none w-full p-2'></div>
        </div>
        <div class="card-element md:w-2/6 w-1/3">
          <p class="mb-1">CVV</p>
          <div class='card-cvc bg-white border
          border-black-p-btn border-opacity-50 outline-none w-full p-2'></div>
        </div>
      </div>
      <p class="text-red-600">{{error}}</p>
      <p>
        ※当サイトではセキュリティ対策のためSSLの暗号通信技術を使用しております。
      </p>
    </div>
  </div>
</template>

<script>
// import InputField from '../common/InputField.vue';
import { PaymentService } from '@/services';
import { authComputed } from '@/helpers';

let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

export default {
  components: {
    // InputField,
  },
  props: {
    selected: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    cards: [],
    selectedCard: '',
    newCard: {},
    error: '',
  }),
  computed: {
    ...authComputed,
  },
  mounted() {
    if (this.loggedIn) {
      PaymentService.getMyCards().then((res) => {
        this.cards = res;
      });
    }

    this.newCard = this.setupCardElement();
    if (this.selected) this.selectedCard = this.selected;
  },
  methods: {
    setCardError(event) {
      this.error = event.error ? event.error.message : '';
    },
    setupCardElement() {
      let elements = stripe.elements();
      let cardNumberEl = elements.create('cardNumber');
      cardNumberEl.mount('.card-number');
      let cardExpiryEl = elements.create('cardExpiry');
      cardExpiryEl.mount('.card-expiry');
      let cardCvcEl = elements.create('cardCvc');
      cardCvcEl.mount('.card-cvc');

      cardNumberEl.on('change', this.clearError);
      cardExpiryEl.on('change', this.clearError);
      cardCvcEl.on('change', this.clearError);

      return cardNumberEl;
    },
    async createPaymentMethod() {
      let result = await stripe.createPaymentMethod({
        type: 'card',
        card: this.newCard,
      });

      if (result.error) {
        this.setCardError(result);
        throw new Error(result.error);
      }

      await PaymentService.addCard(result.paymentMethod.id);
      return result.paymentMethod.id;
    },
    getSelectedPaymentMethod() {
      if (this.selectedCard) {
        return new Promise((resolve) => {
          resolve(this.selectedCard);
        });
      }
      return this.createPaymentMethod();
    },
    clearError() {
      this.error = '';
    },
  },
};
</script>

<style>

</style>
