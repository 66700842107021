import axios from 'axios';
import {
  UserAdapter,
  DeliveryAddressAdapter,
  BankInfoAdapter,
} from './adapter';

function getCurrentUser() {
  return axios.get('/user/')
    .then((res) => Promise.resolve(UserAdapter.convertFromResponse(res.data)))
    .catch((err) => Promise.reject(err));
}

function updateCurrentUser(input) {
  const data = {
    email: input.email,
    first_name: input.firstName,
    last_name: input.lastName,
    first_name_kana: input.firstNameKana,
    last_name_kana: input.lastNameKana,
    phone_number: input.phoneNumber,
    address_postcode: input.cityCode + input.districtCode,
    prefecture: input.prefecture,
    address: input.address,
  };
  return axios.put('/user/', data)
    .then((res) => UserAdapter.convertFromResponse(res.data));
}

function register(input) {
  const data = {
    email: input.email,
    first_name: input.first_name,
    last_name: input.last_name,
    first_name_kana: input.first_name_kana,
    last_name_kana: input.last_name_kana,
    phone_number: input.phone_number,
    address_postcode: input.address_postcode,
    prefecture: input.prefecture,
    address: input.address,
    password: input.password,
  };
  return axios.post('user/register', data)
    .then((res) => UserAdapter.convertFromResponse(res.data))
    .catch((err) => Promise.reject(err));
}

// address_book
function getAllDeliveryAddress(page, size) {
  return axios.get('/user/address_book', { params: { page, size } })
    .then((res) => ({
      items: res.data.items.map((obj) => DeliveryAddressAdapter.convertFromResponse(obj)),
      totalPage: Math.ceil(res.data.total / size),
    }));
}

function createDeliveryAddress(formData) {
  return axios.post('/user/address_book', formData)
    .then((res) => Promise.resolve(DeliveryAddressAdapter.convertFromResponse(res.data)))
    .catch((err) => Promise.reject(err));
}

function getDeliveryAddress(addressId) {
  return axios.get(`/user/address_book/${addressId}`)
    .then((res) => Promise.resolve(DeliveryAddressAdapter.convertFromResponse(res.data)))
    .catch((err) => Promise.reject(err));
}

function updateDeliveryAddress(addressId, formData) {
  return axios.put(`/user/address_book/${addressId}`, formData)
    .then((res) => Promise.resolve(DeliveryAddressAdapter.convertFromResponse(res.data)))
    .catch((err) => Promise.reject(err));
}

function deleteDeliveryAddress(addressId) {
  return axios.delete(`/user/address_book/${addressId}`);
}

function getBankAcountInfo() {
  return axios.get('user/bank/').then((res) => BankInfoAdapter.convertFromResponse(res.data));
}

function saveBankAccountInfo(input) {
  let data = {
    bank_name: input.bankName,
    branch_name: input.branchName,
    account_type: input.accountType,
    account_number: input.accountNumber,
    account_owner_name: input.accountHolder,
  };
  return axios.post('user/bank/', data);
}

// // bank
// function getAllBankInfos() {
//   return axios.get('/user/bank/')
//     .then((res) => Promise.resolve(BankInfoAdapter.convertFromResponse(res.data)))
//     .catch((err) => Promise.reject(err));
// }

// function createBankInfo(input) {
//   const data = {
//     bank_name: input.bank_name,
//     branch_name: input.branch_name,
//     account_type: input.account_type,
//     account_number: input.account_number,
//     account_owner_name: input.account_owner_name,
//   };
//   return axios.post('/user/bank/', data)
//     .then((res) => Promise.resolve(BankInfoAdapter.convertFromResponse(res.data)))
//     .catch((err) => Promise.reject(err));
// }

// function getDetailBankInfo(bankInfoId) {
//   return axios.get(`/user/bank/${bankInfoId}`)
//     .then((res) => Promise.resolve(BankInfoAdapter.convertFromResponse(res.data)))
//     .catch((err) => Promise.reject(err));
// }

// function updateBankInfo(bankInfoId, input) {
//   const data = {
//     bank_name: input.bank_name,
//     branch_name: input.branch_name,
//     account_type: input.account_type,
//     account_number: input.account_number,
//     account_owner_name: input.account_owner_name,
//   };
//   return axios.put(`/user/bank/${bankInfoId}`, data)
//     .then((res) => Promise.resolve(BankInfoAdapter.convertFromResponse(res.data)))
//     .catch((err) => Promise.reject(err));
// }

// function deleteBankInfo(bankInfoId) {
//   return axios.delete(`/user/bank/${bankInfoId}`)
//     .then((res) => Promise.resolve(BankInfoAdapter.convertFromResponse(res.data)))
//     .catch((err) => Promise.reject(err));
// }

export const UserService = {
  getCurrentUser,
  updateCurrentUser,
  register,

  // address_book
  getAllDeliveryAddress,
  createDeliveryAddress,
  getDeliveryAddress,
  updateDeliveryAddress,
  deleteDeliveryAddress,

  // bank
  getBankAcountInfo,
  saveBankAccountInfo,
  // createBankInfo,
  // getDetailBankInfo,
  // updateBankInfo,
  // deleteBankInfo,
};
