<template>
  <div class="lg:container mx-auto px-3 compensation-top-nav pb-10">
    <UserTabs :activeMyDeliveryManagement="true"/>
    <div class="text-right">
      <div
        class="
          md:w-36
          w-28
          ml-auto
          mr-0
          md:mb-10
        "
      >
        <g-button @click="$router.push('create-delivery-address')">新規登録</g-button>
      </div>
    </div>

    <template v-if="deliveryAddress.length > 0">
    <div class="lg:block hidden">
      <table
        class="
          table-auto
          text-center
          lg:my-10
          my-6
          border-collapse
          border
          table-home
          w-full
          mb-6
        "
      >
        <thead>
          <tr>
            <th>送付先</th>
            <th>郵便番号</th>
            <th>送付先住所</th>
            <th>電話番号</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(address, index) in deliveryAddress"
            :key="index"
          >
            <td class="table-cell">
              <a
                class="underline text-blue-600"
                href="#"
                @click="$router.push({
                  name: 'edit-my-delivery-address',
                  params: {
                    id: address.addressBookId,
                  },
                })"
              >
                {{ address.lastName }} {{ address.firstName }}
              </a>
            </td>
            <td>{{ address.cityCode }}-{{ address.districtCode }}</td>
            <td>{{address.prefecture}} {{ address.address }}</td>
            <td>{{ address.phoneNumber }}</td>
            <td>
              <img
                style="min-width:20px"
                :address-id="address.addressBookId"
                class="mx-auto cursor-pointer"
                src="../../assets/svg_icon/trash.svg"
                alt=""
                @click="confirmDelete(address.addressBookId)"
              >
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <div class="flex flex-col lg:hidden min-w-full">
      <div class="overflow-x-auto">
        <div class="align-middle inline-block min-w-full">
          <div class="overflow-hidden">
            <table
              class="
                table-auto
                text-left
                lg:my-10
                my-6
                border-collapse
                border
                table-home
                w-full
                mb-6
              "
            >
              <thead>
                <tr>
                  <th>送付先</th>
                  <th>郵便番号</th>
                  <th>送付先住所</th>
                  <th>電話番号</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(address, index) in deliveryAddress"
                  :key="index"
                >
                  <td>
                    <a
                      class="underline text-blue-600"
                      href="#"
                      @click="$router.push({
                        name: 'edit-my-delivery-address',
                        params: {
                          id: address.addressBookId,
                        },
                      })"
                    >
                      {{ address.lastName }} {{ address.firstName }}
                    </a>
                  </td>
                  <td>{{ address.cityCode }}-{{ address.districtCode }}</td>
                  <td>{{ address.prefecture }} {{ address.address }}</td>
                  <td>{{ address.phoneNumber }}</td>
                  <td>
                    <img
                      style="min-width:20px"
                      :address-id="address.addressBookId"
                      class="mx-auto cursor-pointer"
                      src="../../assets/svg_icon/trash.svg"
                      alt=""
                      @click="confirmDelete(address.addressBookId)"
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Paginate v-model="page" :totalPage="totalPage"/>
    </template>

    <div v-else class="w-full text-center text-xl flex w-full
    h-80 justify-center items-center">情報がありません。</div>

    <confirm-dialog v-model="openDeleteConfirm"
      @yes="deleteAddress()"
    >
      <template v-slot:main>削除してもいいですか</template>
    </confirm-dialog>
  </div>
</template>

<script>
import { UserService } from '../../services/UserService';
import ConfirmDialog from '@/components/common/ConfirmDialog.vue';
import UserTabs from '../../components/UserPage/UserTabs.vue';
import Paginate from '@/components/common/Paginate.vue';

const ITEM_PER_PAGE = process.env.VUE_APP_ITEM_PER_PAGE;

export default {
  name: 'MyDeliveryManagement',
  components: {
    UserTabs,
    ConfirmDialog,
    Paginate,
  },
  mounted() {
    this.getAddressesInCurrentPage();
  },
  data: () => ({
    deliveryAddress: [],
    openDeleteConfirm: false,
    selectedAddressId: '',
    page: 1,
    totalPage: 1,
  }),
  watch: {
    page() {
      this.getAddressesInCurrentPage();
    },
  },
  methods: {
    confirmDelete(addressId) {
      this.openDeleteConfirm = true;
      this.selectedAddressId = addressId;
    },
    async deleteAddress() {
      let addressId = this.selectedAddressId;
      const res = await UserService.deleteDeliveryAddress(addressId);
      if (res.status === 200) {
        this.deliveryAddress = this.deliveryAddress.filter(
          (address) => address.addressBookId !== Number(addressId),
        );
      }
    },

    getAddressesInCurrentPage() {
      UserService.getAllDeliveryAddress(this.page, ITEM_PER_PAGE)
        .then((res) => {
          this.deliveryAddress = res.items.filter((add) => !add.isMain);
          this.totalPage = res.totalPage;
        });
    },
  },
};
</script>
