<template>
    <div class="w-full h-full compensation-top-nav lg:mb-32 mb-10" v-if="!isLoading">
        <div class="lg:container mx-auto md:px-3 md:mt-0 -mt-12 lg:pt-20">
            <img
                class="w-full h-full"
                :src="news.thumb"
                />
            <div class="md:px-3 px-5">
                <h1 class="md:text-3xl text-2xl md:pt-8 pt-4">{{news.title}}</h1>
                <p>{{$filters.formatJpDatetime(news.publishedAt)}}</p>
                <hr class="my-5 lg:my-5" />
                <article v-html="news.content" class="wysiwyg-content">
                </article>
                <hr class="my-10 lg:my-16" />
                <div class="hidden md:block">
                  <div class="w-full mb-10 grid
                    lg:grid-cols-3 grid-cols-2 gap-4 md:gap-10 2xl:gap-12">
                      <NewsItem v-for="n in listNews.slice(0, 3)" :key="n.id" :news="n"/>
                  </div>
                  <div class="text-center" v-if="listNews.length > 3">
                      <router-link class="bg-black-p-btn rounded-md text-white py-3 px-10"
                        :to="{ name: 'news-list' }"
                      >
                        一覧へ
                      </router-link>
                  </div>
                </div>
                <div class="block md:hidden">
                  <div class="w-full mb-10 grid
                    lg:grid-cols-3 grid-cols-2 gap-4 md:gap-10 2xl:gap-12">
                      <NewsItem v-for="n in listNews.slice(0, 2)" :key="n.id" :news="n"/>
                  </div>
                  <div class="text-center" v-if="listNews.length > 2">
                      <router-link class="bg-black-p-btn rounded-md text-white py-3 px-10"
                        :to="{ name: 'news-list' }"
                      >
                        一覧へ
                      </router-link>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewsItem from '../components/common/NewsItem.vue';
import { NewsService } from '@/services';
import { News } from '@/services/adapter';

export default {
  name: 'NewsDetail',
  components: {
    NewsItem,
  },
  data: () => ({
    news: new News('', '', '', '', ''),
    listNews: [],
    isLoading: false,
  }),
  mounted() {
    this.isLoading = true;
    let newsId = this.$route.params.id;
    NewsService.getNewsDetail(newsId).then((res) => {
      this.news = res;
    }).finally(() => {
      this.isLoading = false;
    });
    NewsService.getNews().then((res) => {
      this.listNews = res;
    });
  },
};
</script>

<style>
.wysiwyg-content img{
  height: auto !important;
}

.wysiwyg-content pre{
  white-space: pre-line;
}
</style>
