<template>
  <table class="table-auto w-full sm:text-tiny lg:mb-10 mb-6">
          <thead class="text-left hidden md:table-row-group">
            <tr>
              <th class="py-3">商品</th>
              <th class="py-3">次回送付先</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, index) in orders" :key="order.id"
              :class="{ 'border-gray-400 bg-row': index%2 == 0 }">
              <td class="border border-gray-400 px-4 py-2 w-2/5">
                <div class="flex">
                  <div class="flex-none">
                    <img :src="order.product.thumb" alt=""
                    class="md:w-32 md:h-32 w-20 h-20 object-cover" />
                    <div class="text-left mt-3 md:hidden">
                      <p class="mb-3" v-if="!isSuspenedOrder(order)">次回課金日<br/>
                        {{calNextSubscriptionPayDate(order)}}
                      </p>
                      <p v-else class="mb-3">停止中</p>
                      <button class="btn bg-black-p-btn h-8 min-h-8"
                        :disabled="order.product.status==='CLOSED'"
                        @click="$router.push({ name: 'edit-my-order', params: {id: order.id} })"
                      >編 集</button>
                    </div>
                  </div>
                  <div class="md:pl-4 pl-2 flex-1">
                    <p>注文番号:{{order.id}}</p>
                    <p>初回注文：{{$filters.formatJpDatetime(order.orderDate)}}</p>
                    <p class="overme">{{order.product.name}}</p>
                    <p>{{order.selectedOptionName}}</p>
                    <p>{{$filters.formatCurrency(order.price)}}円(税込）</p>
                    <p class="overme">{{order.shop.name}}</p>
                    <div class="md:hidden border-t pt-2 mt-2">
                      <p>次回送付先<br/>
                        〒{{order.shippingAddress.cityCode}}-{{order.shippingAddress.districtCode}}
                        <br />
                        {{order.shippingAddress.prefecture}}{{order.shippingAddress.address}}<br />
                        {{order.shippingAddress.lastName}} {{order.shippingAddress.firstName}}<br />
                        {{order.shippingAddress.phoneNumber}}
                      </p>
                      <div class="text-red-600" v-if="order.product.status==='CLOSED'">
                        こちらの商品は販売が停止されています。
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="border border-gray-400 px-4 py-2 hidden md:table-cell align-baseline">
                <div class="text-red-600" v-if="order.product.status==='CLOSED'">
                  こちらの商品は販売が停止されています。
                </div>
                <div class="flex justify-between">
                  <p>
                    〒{{order.shippingAddress.cityCode}}-{{order.shippingAddress.districtCode}}
                    <br />
                    {{order.shippingAddress.prefecture}}{{order.shippingAddress.address}}<br />
                    {{order.shippingAddress.lastName}} {{order.shippingAddress.firstName}}<br />
                    {{order.shippingAddress.phoneNumber}}
                  </p>
                  <div class="text-right">
                    <p class="mb-3" v-if="!isSuspenedOrder(order)">次回課金日：
                      {{calNextSubscriptionPayDate(order)}}
                    </p>
                    <p v-else class="mb-3">停止中</p>
                    <button class="btn bg-black-p-btn px-8 min-h-8 h-10"
                    @click="$router.push({ name: 'edit-my-order', params: {id: order.id} })"
                    :disabled="order.product.status==='CLOSED'"
                    >編 集</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
</template>
<script>
import { utils } from '@/helpers';

export default {
  name: 'TableOrder',
  components: {},
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    calNextSubscriptionPayDate(order) {
      return this.$filters.formatJpDatetime(utils.calNextPayDateOfOrder(order), 'MM/DD');
    },
    isSuspenedOrder(order) {
      return (order.status !== 'active' && order.status !== 'not_started');
    },
  },
};
</script>
