<template>
  <button
        :type="type"
        :disabled="disabled"
        class="
        w-full
        h-full
        text-white
        p-2
        rounded-lg
        "
        :class="[ disabled ? 'btn-disabled': 'bg-black-p-btn' ]"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
  },
};
</script>

<style>

</style>
