import axios from 'axios';
import { SystemSettingAdapter } from './adapter';

function getSystemSetting() {
  return axios.get('/system/setting').then(
    (res) => Promise.resolve(SystemSettingAdapter.convertFromResponse(res.data)),
    (err) => Promise.reject(err),
  );
}

function sendContactFormToAdmin(formData) {
  return axios.post('/contact', formData);
}

function sendContactFormForOpenNewShop(formData) {
  return axios.post('/contact/open_shop', formData);
}

export const SystemSettingService = {
  getSystemSetting,
  sendContactFormToAdmin,
  sendContactFormForOpenNewShop,
};
