<template>
  <div class="cart-item-list container mx-auto px-3 lg:my-10 my-6">
    <table class="w-full">
      <thead class="border-b-2 text-left">
        <th class="py-1 w-14 hidden md:table-cell"></th>
        <th class="py-1 w-2/3 md:w-1/2 lg:w-1/2">商品</th>
        <th class="py-1 w-1/4 hidden md:table-cell">個数</th>
        <th class="py-1 pl-4 hidden md:table-cell">合計</th>
      </thead>
      <tbody>
        <CartItem v-for="(item, index) in cartItems" :key="index" :info="item"/>
      </tbody>
    </table>
  </div>

  <div class="summarize bg-gray-100 py-10">
    <div class="container md:px-3 px-5 mx-auto grid md:grid-cols-3 grid-cols-1 gap-4">
      <div class="md:col-span-2 col-span-1">
        <div class="cupon-area">
          <h3 class="mb-3">クーポンコード</h3>
          <div class="bg-gray-300 lg:p-10 p-3">
            <div class="relative text-gray-700">
              <InputField v-model="coupon" className='h-12 border-white'/>
              <button
                class="
                  absolute
                  text-white
                  inset-y-0
                  right-0
                  flex
                  items-center
                  lg:px-10
                  px-6
                  bg-black-p-btn
                "
                v-on:click="getCoupon"
              >
                クーポンを適用する
              </button>
            </div>
            <span class="text-red-500" v-if="isValidCoupon == false">
              {{ inValidCouponError }}
            </span>
          </div>
          <span>※クーポンは初回オーダーにのみ適用されます、初回のオーダーをスキップ・停止した場合は無効となります。</span>
        </div>

        <div class="w-full mt-10 md:block hidden">
          <g-button class="bg-blue-500" @click="next()">サブスクリプションの注文に進む</g-button>
        </div>
      </div>

      <div class="col-span-1">
        <TotalOrderValue />
      </div>
      <div class="w-full md:hidden block mt-3">
        <g-button class="bg-blue-500" @click="next()">サブスクリプションの注文に進む</g-button>
      </div>
    </div>
  </div>

  <!-- Banner  -->
  <div class="container px-3 mx-auto lg:mb-16 my-10 md:hidden block"
    v-if="systemSetting.banners[0]">
    <div class="lg:w-3/4 w-full mx-auto">
      <Banner :image="systemSetting.banners[5].image" :link="systemSetting.banners[5].link" />
    </div>
  </div>

  <go-back-btn></go-back-btn>

  <!-- Banner  -->
  <div class="container px-3 mx-auto lg:mb-16 my-10 md:block hidden"
  v-if="systemSetting.banners[0]">
    <div class="lg:w-3/4 w-full mx-auto">
      <Banner :image="systemSetting.banners[0].image" :link="systemSetting.banners[0].link" />
    </div>
  </div>
</template>

<script>
import Banner from '../Banner.vue';
import CartItem from './CartItem.vue';
import TotalOrderValue from './TotalOrderValue.vue';
import { systemSetting, cart } from '@/helpers';
import { OrderService } from '@/services';
// import GButton from '../common/GButton.vue';
// import InputField from '../common/InputField.vue';

export default {
  name: 'Step1',
  components: {
    Banner,
    CartItem,
    TotalOrderValue,
    // GButton,
    // InputField,
  },
  data() {
    return {
      coupon: '',
      inValidCouponError: '',
      isValidCoupon: true,
    };
  },
  computed: {
    ...systemSetting,
    ...cart,
  },
  methods: {
    next() {
      this.$emit('next');
    },
    getCoupon() {
      this.$store.dispatch('cart/clearCoupon');
      OrderService.getCoupon(this.coupon).then((res) => {
        if (res.result.status === 200) {
          this.isValidCoupon = true;
          this.$store.dispatch('cart/getCoupon', {
            coupon: res.result.data,
          });
        } else {
          this.isValidCoupon = false;
          this.inValidCouponError = res.result.data.detail;
        }
      });
    },
  },
};
</script>
