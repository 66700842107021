import axios from 'axios';
import { CategoryAdapter, ProductAdapter } from './adapter';

function getCategories() {
  return axios.get('category/').then(
    (res) => Promise.resolve(res.data.map((item) => CategoryAdapter.convertFromResponse(item))),
    (err) => Promise.reject(err),
  );
}

function getCategoryDetail(categoryId) {
  return axios.get(`category/${categoryId}`).then((res) => CategoryAdapter.convertFromResponse(res.data));
}

// eslint-disable-next-line no-unused-vars
function getProductsOfCategory(categoryId, page, size) {
  return axios.get(`category/${categoryId}/products`, { params: { page, size } })
    .then((res) => ({
      items: res.data.items.map((item) => ProductAdapter.convertListItemFromResponse(item)),
      totalPage: Math.ceil(res.data.total / size),
    }));
}

export const CategoryService = {
  getCategories,
  getCategoryDetail,
  getProductsOfCategory,
};
