<template>
  <div class="w-full h-full compensation-top-nav">
    <div v-if="cartItems.length > 0">
      <StepProgress
        :numberOfStep="numberOfStep"
        :currentStep="currentStep"
        @stepChanged="stepChanged($event)"
        :stepLabel="stepLabel"
      />
      <div v-if="currentStep === 1">
        <Step1 @next="currentStep=2"/>
      </div>

      <div v-if="currentStep === 2">
        <Step2 @orderSuccess="currentStep=3"/>
      </div>

      <div v-if="currentStep === 3">
        <Step3/>
      </div>
    </div>

    <div
      v-else
      class="
        container
        mx-auto
        lg:mt-40
        px-4
      "
    >
      <h1 class="text-2xl my-10" v-if="loggedIn">
        こんにちは {{loggedInUser.lastName}} {{loggedInUser.firstName}} さん
      </h1>
      <h1 class="text-2xl my-10" v-else>こんにちは ゲスト さん</h1>

      <div v-if="isAnyNotOnSaleProduct"
        class='w-full text-center text-xl flex
        h-80 justify-center items-center text-red-600'
      >
        新規受付を停止している商品が入っています、該当商品を削除してお進みください
      </div>

      <div class="w-full text-center text-xl flex
      h-80 justify-center items-center">
        <div>
          <div class="flex items-center justify-center">
            <img class="inline lg:h-11 h-8" src="../assets/svg_icon/cart.svg" alt="" srcset="">
            <span
              class="
                text-center
                text-xl
                mt-3
                ml-2
              "
            >
              買い物かごに何も入っていません
            </span>
          </div>
          <go-back-btn></go-back-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cart, authComputed } from '@/helpers';

import StepProgress from '../components/CheckoutPage/StepProgress.vue';
import Step1 from '../components/CheckoutPage/Step1.vue';
import Step2 from '../components/CheckoutPage/Step2.vue';
import Step3 from '../components/CheckoutPage/Step3.vue';

export default {
  name: 'Checkout',
  components: {
    StepProgress,
    Step1,
    Step2,
    Step3,
  },
  computed: {
    ...cart,
    ...authComputed,
    isAnyNotOnSaleProduct() {
      return this.cartItems.some((item) => item.product.status === 'STOP');
    },
  },
  watch: {
    currentStep() {
      if (this.currentStep === 3) window.scrollTo(0, 0);
    },
  },
  mounted() {
    if (this.$route.params.step === 'step1') {
      this.currentStep = 1;
      return;
    }
    if (this.$route.params.step === 'step2') {
      this.currentStep = 2;
      return;
    }
    if (this.$route.params.step === 'step3') {
      this.currentStep = 3;
    }
  },
  data: () => ({
    numberOfStep: 3,
    currentStep: 1,
    stepLabel: ['買い物かご確認', '情報入力', 'オーダー完了'],
  }),
  methods: {
    stepChanged(step) {
      if (this.currentStep === 3 || step === 3) return;
      this.currentStep = step;
    },
  },
};
</script>
