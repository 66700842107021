export class Category {
  constructor() {
    this.name = '';
    this.id = '';
    this.banner = '';
  }

  setVal(obj) {
    this.name = obj.name;
    this.id = obj.id;
    this.banner = obj.banner;
  }
}
export class CategoryAdapter {
  static convertFromResponse(res) {
    let c = new Category();
    c.setVal(res);
    return res;
  }
}
