<template>
  <div>
    <label v-if="label" class="mb-2 block text-opacity-70">
      <span>{{ label }}</span>
      <span v-if="showRequiredLabel && required" class="text-red-600 text-xs">*必須</span>
    </label>
    <input
      :class="[defaultClass, className]"
      :type="type"
      :min="min"
      :max="max"
      v-model="inputValue"
      @input="updateInputValue"
      :placeholder="placeholder"
      :disabled="disabled"
    />
    <p class="text-left text-sm text-red-600">{{ this.errors.join(" ") }}</p>
  </div>
</template>

<script>
function isValidEmail(input) {
  // eslint-disable-next-line
  const emailCheckRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return emailCheckRegex.test(input);
}

function isStrongPassword(input) {
  const isContainAlphabet = (p) => /[a-zA-Z]/.test(p);
  const isContainNumber = (p) => /[0-9０-９]/.test(p);
  if (
    input.length >= 8 && isContainAlphabet(input) && isContainNumber(input)
  ) return true;
  return false;
}

function isKatakanaType(input) {
  const katakanaCheckRegex = /[\u{3000}-\u{301C}\u{30A1}-\u{30F6}\u{30FB}-\u{30FE}]/mu;
  return katakanaCheckRegex.test(input);
}

function isKatananaOrHiraganaType(input) {
  const checkRegex = /[\u3040-\u309f\u30a0-\u30ff]/;
  return checkRegex.test(input);
}

export default {
  name: 'InputField',
  inject: ['register', 'unregister'],
  components: {},
  props: {
    label: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      required: true,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    showRequiredLabel: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
    },
    maxLength: {
      type: Number,
    },
    size: {
      type: Number,
    },
    charSet: {
      type: String,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    inputValue: '',
    errors: [],
    defaultClass:
      'border border-black-p-btn border-opacity-50 outline-none w-full p-2',
  }),
  created() {
    if (this.register) this.register(this);
  },
  mounted() {
    this.inputValue = this.modelValue;
  },
  unmounted() {
    if (this.unregister) this.unregister(this);
  },
  watch: {
    modelValue(newVal) {
      this.inputValue = newVal;
    },
  },
  methods: {
    updateInputValue() {
      this.validate();
      this.$emit('update:modelValue', this.inputValue);
    },
    clearErrors() {
      this.errors = [];
    },
    validate() {
      this.clearErrors();
      let testVal = this.inputValue;

      if (this.required && !`${testVal}`.trim()) {
        if (this.label) {
          this.errors.push(`${this.label}必須項目です。`);
        } else {
          this.errors.push('必須項目です。');
        }
      }

      if (this.rules) {
        // eslint-disable-next-line no-restricted-syntax
        for (let r of this.rules) {
          let err = r(testVal);
          if (err !== true) this.errors.push(err);
        }
      }

      if (!testVal) return;

      if (this.type === 'text') {
        if (this.maxLength && testVal.length > this.maxLength) {
          this.errors.push(`${this.label}を${this.maxLength}桁以内を入力してください。`);
        }
        if (this.size && `${testVal.length}` !== `${this.size}`) {
          this.errors.push(`${this.size}桁を入力してください。`);
        }
        if (this.charSet === 'number' && testVal.length > 0) {
          let onlyDigit = /^\d*$/.test(testVal);
          if (!onlyDigit) this.errors.push('数字を入力してください。');
        }
      }

      if (this.type === 'password' && this.inputValue && !isStrongPassword(this.inputValue)) {
        this.errors.push('「パスワード」は８文字以上で、アルファベットと数字を含む必要があります。');
      }

      if (this.type === 'email' && this.inputValue && !isValidEmail(this.inputValue)) {
        this.errors.push('メールアドレス形式が間違っています。');
      }

      if (this.type === 'katakana' && this.inputValue && !isKatakanaType(this.inputValue)) {
        this.errors.push(`${this.label}はカタカナで入力してください`);
      }

      if (this.type === 'katakanaOrhiragana' && this.inputValue && !isKatananaOrHiraganaType(this.inputValue)) {
        this.errors.push(`${this.label}はカタカナ/ひらがなで入力してください。`);
      }
    },
  },
};
</script>
