<template>
  <div>
    <h1
      class="
        pt-10
        pb-7
        text-center
        text-3xl
        lg:p-16
        md:text-5xl
        lg:text-4xl
      "
    >
      会員の方
    </h1>
    <div
      class="
        container
        md:max-w-screen-md
        xl:max-w-screen-lg
        px-6
        mx-auto
        bg-gray-p-bg
      "
    >
      <g-form ref="form">
        <div
          class="mx-auto py-6 px-16 lg:px-32"
          id="email"
        >
          <InputField
            label="メールアドレス"
            v-model="email"
            type="email"
            required
          />
        </div>

        <div
          class="mx-auto px-16 lg:px-32"
          id="password"
        >
          <InputField
            label="パスワード"
            v-model="password"
            type="password"
            required
            @keyup.enter="login()"
          />
        </div>

        <div v-if="error"
          class="mx-auto px-16 lg:px-32"
        >
         <p class="text-left text-lg text-red-600">{{error}}</p>
        </div>

        <div
          class="
            lg:w-36
            md:w-32
            w-28
            mx-auto
            pt-10
          "
        >
          <button
            type="submit"
            class="
              w-full
              h-full
              text-white
              py-2
              md:text-lg
              lg:text-xl
              bg-black-p-btn
              rounded-md
            "
            @click.prevent="login()"
          >
            ログイン
          </button>
        </div>

        <a
          class="
            link
            text-blue-900
            block
            w-full
            text-center
            p-10
            md:text-lg
            lg:text-xl
          "
          @click="this.$router.push('request-reset-password')"
        >
          パスワードを忘れた方はこちら
        </a>
      </g-form>
    </div>
  </div>
</template>

<script>
// import GForm from '../common/GForm.vue';
// import InputField from '../common/InputField.vue';

export default {
  name: 'LoginForm',
  components: {
    // InputField,
    // GForm,
  },
  data() {
    return {
      email: '',
      password: '',
      error: '',
    };
  },
  watch: {
    email() {
      this.error = '';
    },
    password() {
      this.error = '';
    },
  },
  methods: {
    login() {
      if (!this.$refs.form.isValid()) return;
      this.$store.dispatch('user/login', {
        email: this.email.toLowerCase(),
        password: this.password,
      }).then(
        () => {
          this.$router.push(this.$route.query.next || { name: 'home' });
        },
      ).catch((error) => {
        console.log(error);
        this.error = 'メールアドレス、パスワードのいずれかが間違っています。';
      });
    },
  },
};
</script>
