<template>
  <div class="">
    <div class="border-b border-gray-p-text-color">
      <!-- <div class="md:mb-8 mb-3">
        <img class="w-full" :src="storeInformation.setting.pcBanner"
        :alt="storeInformation.name" srcset="">
      </div> -->

      <div class="lg:w-2/3 w-full md:mb-8 mb-3" v-html="storeInformation.setting.introduction">
      </div>
    </div>

    <div class="md:py-10 py-5">
      <div class="mb-3 block lg:flex lg:mb-8">
        <p class="lg:text-right lg:w-1/3">店舗名称</p>
        <p class="ml-5 lg:ml-10 lg:w-4/6">{{ storeInformation.name }}</p>
      </div>

      <div class="mb-3 lg:flex lg:mb-8">
        <p class="lg:text-right lg:w-1/3">販売事業者名</p>
        <p class="ml-5 lg:ml-10 lg:w-4/6">{{ storeInformation.ownerName }}</p>
      </div>

      <div class="mb-3 lg:flex lg:mb-8">
        <p class="lg:text-right lg:w-1/3">販売責任者名</p>
        <p class="ml-5 lg:ml-10 lg:w-4/6">{{ storeInformation.managerName }}</p>
      </div>

      <div class="mb-3 lg:flex lg:mb-8">
        <p class="lg:text-right lg:w-1/3">所在地</p>
        <p class="ml-5 lg:ml-10 lg:w-4/6">{{ storeInformation.ceoAddress }}</p>
      </div>

      <div class="mb-3 lg:flex lg:mb-8">
        <p class="lg:text-right lg:w-1/3">電話番号</p>
        <p class="ml-5 lg:ml-10 lg:w-4/6">{{ storeInformation.phoneNumber }}</p>
      </div>

      <div class="mb-3 lg:flex lg:mb-8">
        <p class="lg:text-right lg:w-1/3">返品について</p>
        <p class="ml-5 lg:ml-10 lg:w-4/6">
          {{ storeInformation.setting.productReturnPolicy }}
        </p>
      </div>

      <div class="mb-3 lg:flex lg:mb-8">
        <p class="lg:text-right lg:w-1/3">お支払い時期</p>
        <p class="ml-5 lg:ml-10 lg:w-4/6">毎月1日</p>
      </div>

      <div class="mb-3 lg:flex lg:mb-8">
        <p class="lg:text-right lg:w-1/3">引き渡し時期</p>
        <p class="ml-5 lg:ml-10 lg:w-4/6">毎月１０日前後</p>
      </div>

      <div class="mb-3 lg:flex lg:mb-8">
        <p class="lg:text-right lg:w-1/3">お支払い方法</p>
        <p class="ml-5 lg:ml-10 lg:w-4/6">クレジットカード払い</p>
      </div>

      <div class="mb-3 lg:flex lg:mb-8">
        <p class="lg:text-right lg:w-1/3">商品代金以外の必要料金</p>
        <p class="ml-5 lg:ml-10 lg:w-4/6">なし</p>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'StoreInformation',
  props: {
    storeInformation: Object,
  },
};
</script>
