class Slide {
  constructor(image, link) {
    this.image = image;
    this.link = link;
  }
}

class Banner {
  constructor(index, image, link) {
    this.index = index;
    this.image = image;
    this.link = link;
  }
}

export class ExternalLink {
  constructor(fb, insta, twitter, tiktok, linkedin) {
    this.facebook = fb;
    this.instagram = insta;
    this.twitter = twitter;
    this.tiktok = tiktok;
    this.linkedin = linkedin;
  }
}
export class Setting {
  constructor(pcSlides, mobileSlides, banners, link) {
    this.pcSlides = pcSlides;
    this.mobileSlides = mobileSlides;
    this.banners = banners;
    this.link = link;
  }
}

function convertFromResponse(obj) {
  let link = new ExternalLink(obj.facebook_link, obj.instagram_link,
    obj.twitter_link, obj.tiktok_link, obj.linkedin_link);

  let pcSlides = [];
  let mobileSlides = [];
  for (let i = 1; i <= 3; i += 1) {
    let pcSlide = new Slide(obj[`pc_main_slider_img${i}`], obj[`pc_main_slider_img${i}_shadow_link`]);
    pcSlides.push(pcSlide);

    let mobileSlide = new Slide(obj[`mobile_main_slider_img${i}`], obj[`mobile_main_slider_img${i}_shadow_link`]);
    mobileSlides.push(mobileSlide);
  }

  let banners = [];
  for (let i = 1; i <= 6; i += 1) {
    let banner = new Banner(
      i,
      obj[`banner_${i}`],
      obj[`banner_${i}_shadow_link`],
    );
    banners.push(banner);
  }

  return new Setting(pcSlides, mobileSlides, banners, link);
}

export const SystemSettingAdapter = {
  convertFromResponse,
};
