export class Transaction {
  constructor() {
    this.executedDate = null;
    this.orderId = null;
    this.productPrice = null;
    this.income = null;
    this.outcome = null;
    this.memo = '';
    this.balance = 0;
  }

  get amount() {
    if (this.isWithdraw) {
      return this.outcome * -1;
    }
    if (this.income) {
      return this.income;
    }
    return 0;
  }

  get isWithdraw() {
    return this.outcome !== null;
  }
}

function converseTransaction(obj) {
  let t = new Transaction();
  t.executedDate = new Date(obj.transaction_date);
  t.orderId = obj.order_id;
  t.productPrice = obj.product_price;
  t.memo = obj.memo;
  t.balance = obj.balance;
  t.income = obj.income_amount;
  t.outcome = obj.outcome_amount;
  return t;
}

export class RewardSummarization {
  constructor() {
    this.cumulativeAmount = 0;
    this.balance = 0;
  }
}

function convertRewardSummarization(obj) {
  let summ = new RewardSummarization();
  summ.cumulativeAmount = obj.total_income;
  summ.balance = obj.withdrawable_amount;
  return summ;
}

export const AffiliateAdapter = {
  convertRewardSummarization,
  converseTransaction,
};
