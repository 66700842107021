<template>
  <div>
    <g-form ref="form">
      <div
      class="
        flex
        lg:w-3/5
        md:w-2/5
        w-full
        mt-6
      "
    >
      <div class="lg:mr-4 mr-2 ">
        <InputField
          label="姓"
          required
          maxLength=25
          v-model="lastName"
        />
      </div>

      <div class="">
        <InputField
          label="名"
          required
          maxLength=25
          v-model="firstName"
        />
      </div>
      </div>

      <div class="md:w-2/5 w-2/4 mr-3 mt-6">
        <InputField
          label="電話番号"
          required
          charSet="number"
          v-model="phoneNumber"
        />
      </div>

      <div id="postal-code" class="mt-6">
        <label
          class="
            mb-2 block text-opacity-70
          "
        >
          <span>郵便番号</span>
          <span class="text-red-600 text-xs">*必須</span>
        </label>

        <div
          class="
            flex
            lg:w-3/5
            md:w-2/5
            sm:w-full
          "
        >
          <div class="lg:w-20 w-28">
            <InputField v-model="cityCode"
              required charSet="number" size=3 />
          </div>

          <div class="">
            <div class="w-5 mx-2 bg-gray-400 mt-5" style="height:2px"></div>
          </div>

          <div class="w-32 lg:w-28">
            <InputField v-model="districtCode" required
            charSet="number" size=4 />
          </div>
        </div>
      </div>

      <div class="flex mb-5 mt-6">
        <div class="lg:w-32 md:w-1/6 w-1/2 mr-3">
          <InputField
            label="都道府県"
            required
            maxLength=50
            v-model="prefecture"
          />
        </div>
        <div class="md:w-2/3 w-full ml-3">
          <InputField
            label="住所"
            maxLength=200
            required
            v-model="address"
          />
        </div>
      </div>

      <div
        v-if="showBtn"
        class="flex lg:w-2/5 w-1/2 mx-auto"
      >
        <go-back-btn></go-back-btn>

        <div
          class="
            w-32
            my-8
            pl-2
          "
        >
          <g-button @click="save()">保 存</g-button>
        </div>
      </div>
    </g-form>
  </div>
</template>

<script>
// import GButton from '../common/GButton.vue';
// import GForm from '../common/GForm.vue';
// import InputField from '../common/InputField.vue';

export default {
  name: 'ShippingAddressForm',
  emits: ['isShowMsg'],
  props: {
    showBtn: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    // InputField,
    // GForm,
    // GButton,
  },
  created() {
    if (Object.keys(this.data).length !== 0) {
      this.firstName = this.data.firstName;
      this.lastName = this.data.lastName;
      this.phoneNumber = this.data.phoneNumber;
      this.prefecture = this.data.prefecture;
      this.address = this.data.address;
      if (this.data.addressPostCode) {
        this.cityCode = this.data.addressPostCode.slice(0, 3);
        this.districtCode = this.data.addressPostCode.slice(3, 7);
      }
    }
  },
  computed: {
    postalCode() {
      return `${this.cityCode}${this.districtCode}`;
    },
  },
  data: () => ({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    cityCode: '',
    districtCode: '',
    prefecture: '',
    address: '',
  }),
  methods: {
    async save() {
      if (!this.$refs.form.isValid()) return;
      const payload = {
        first_name: this.firstName,
        last_name: this.lastName,
        phone_number: this.phoneNumber,
        address_postcode: this.postalCode,
        prefecture: this.prefecture,
        address: this.address,
      };
      this.$emit('save', payload);
    },
  },
};
</script>
