<template>
    <div
      v-if="!registerCompleted"
      class="
        container
        max-w-screen-md
        mx-auto
        pt-28
        md:px-10 px-5
      "
    >
      <div
        class="
          w-full
          flex
          items-center
          justify-center
          lg:mb-8
          mb-4
          text-xl
          md:text-2xl
          lg:text-3xl
        "
      >
        <span class="rounded bg-gray-500 flex-grow" style="height: 1px"></span>
        <span class="px-3">会員様情報</span>
        <span class="rounded bg-gray-500 flex-grow" style="height: 1px"></span>
      </div>

      <g-form ref="form">
      <div
        class="
          flex
          lg:w-3/5
          md:w-2/5
          sm:w-full
          mb-6
        "
      >
        <div class="lg:mr-4 mr-2">
          <InputField
            label="姓"
            required
            maxLength=25
            v-model="lastName"
          ></InputField>
        </div>

        <div class="">
          <InputField
            label="名"
            required
            maxLength=25
            v-model="firstName"
          />
        </div>
      </div>

      <div
        class="
          flex
          lg:w-3/5
          md:w-2/5
          sm:w-full
          mt-2
          mb-6
        "
      >
        <div class="lg:mr-4 mr-2 ">
          <InputField
            label="セイ"
            required
            maxLength=25
            v-model="lastNameKana"
            type="katakana"
          />
        </div>

        <div class="">
          <InputField
            label="メイ"
            maxLength=25
            required
            v-model="firstNameKana"
            type="katakana"
          />
        </div>
      </div>

      <div id="email-input" class="mt-2 mb-6">
        <InputField
          label="メールアドレス"
          required
          maxLength=150
          type="email"
          v-model="email"
        />
      </div>

      <div id="password-input" class="mt-2 mb-6">
        <InputField
          label="パスワード"
          required
          type="password"
          v-model="password"
        />
      </div>

      <div
        class="
          mt-2
          lg:w-3/5
          md:w-2/5
          sm:w-full
          mb-6
        "
      >
        <InputField
          label="電話番号"
          required
          charSet="number"
          v-model="phoneNumber"
        />
      </div>

      <div id="postal-code" class="mt-2 mb-6">
        <label
          class=" mb-2 block text-opacity-70"
        >
          <span>郵便番号</span>
          <span class="text-red-600 text-xs">*必須</span>
        </label>

        <div
          class="
            flex
            mt-2
            lg:w-3/5
            md:w-2/5
            sm:w-full
            mb-6
          "
        >
          <div class="lg:w-1/5 w-1/3">
            <InputField required v-model="cityCode" charSet="number" size=3 />
          </div>

          <div class="">
            <div class="w-5 mx-2 bg-gray-400 mt-5" style="height:2px"></div>
          </div>

          <div class="lg:w-4/5 w-2/3">
            <InputField required v-model="districtCode" charSet="number" size=4 />
          </div>
        </div>
      </div>

      <div class="flex mb-6">
        <div class="lg:w-1/5 w-1/3 lg:mr-4 mr-2 ">
          <InputField
            label="都道府県"
            required
            maxLength=50
            v-model="prefectures"
          />
        </div>
        <div class="w-4/5 ">
          <InputField
            label="住所"
            maxLength=200
            required
            v-model="address"
          />
        </div>
      </div>

      <div class="flex mb-6">
        <p v-if="error" class="text-left text-lg text-red-600">{{error}}</p>
      </div>
      <div
        class="
          lg:w-56
          md:w-36
          w-32
          mx-auto
          lg:mt-12
          mt-8
        "
      >
        <button
          type="submit"
          class="
            w-full
            h-full
            text-white
            py-2
            md:text-lg
            lg:text-xl
            bg-black-p-btn
            rounded-md
          "
          @click="register()"
        >
          会員登録する
        </button>
      </div>
      </g-form>

      <go-back-btn></go-back-btn>

    </div>

    <div
      v-if="registerCompleted"
      class="
        container
        max-w-screen-md
        mx-auto
        text-center
        lg:pt-44
        pt-24
        sm:px-8
        px-2
      "
    >
      <p
        class="
          label-text
          w-full
          px-10
          mx-auto
          lg:text-2xl
          text-xl
          text-opacity-100
        "
      >
        会員登録が完了しました
      </p>

      <div
        class="
          lg:w-48
          md:w-30
          lg:mb-32
          lg:mt-14
          w-40
          mx-auto
          my-8
        "
      >
        <!-- Click to redirect to Top page -->
        <g-button @click="goHome()">トップページへ</g-button>
      </div>

    </div>
</template>

<script>
import GButton from '../../components/common/GButton.vue';
// import InputField from '../../components/common/InputField.vue';
// import GFrom from '@/components/common/GForm.vue';

export default {
  name: 'RegisterMember',
  components: {
    GButton,
    // InputField,
    // GFrom,
  },

  mounted() {
  },

  data: () => ({
    registerCompleted: false,
    isSubmitted: false,
    firstName: '',
    firstNameKana: '',
    lastName: '',
    lastNameKana: '',
    email: '',
    password: '',
    phoneNumber: '',
    cityCode: '',
    districtCode: '',
    prefectures: '',
    address: '',
    error: '',
  }),
  computed: {
    postalCode() {
      return this.cityCode + this.districtCode;
    },
  },
  methods: {
    register() {
      if (!this.$refs.form.isValid() || this.isSubmitted) {
        return;
      }

      // prevent call api many times when waiting response
      this.isSubmitted = true;

      let payload = {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        first_name_kana: this.firstNameKana,
        last_name_kana: this.lastNameKana,
        phone_number: this.phoneNumber,
        address_postcode: this.postalCode,
        prefecture: this.prefectures,
        address: this.address,
        password: this.password,
      };

      this.$store.dispatch('user/register', payload).then(() => {
        this.registerCompleted = true;
        window.scrollTo(0, 0);
      }).catch(() => {
        this.error = 'このメールアドレスは既に利用されています。別のメールアドレスを入力してください。';
      }).finally(() => {
        this.isSubmitted = false;
      });
    },

    redirectToPrevPage() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.push({ name: 'home' });
    },

  },
};
</script>

<style scoped>
.title {
  overflow: hidden;
  text-align: center;
}

.title:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  width: 80%;
}

.title:before {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  width: 10%;
}

.title:before {
  margin-left: -50%;
}

.title:after {
  margin-right: -50%;
}
</style>
