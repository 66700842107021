<template>
  <div class="pt-24">
    <LoginForm />

    <div id="login-from-checkout">
      <div>
        <h1
          class="
            text-center
            text-2xl
            pt-12
            pb-8
            md:text-5xl
            lg:text-4xl
            lg:pt-20
          "
        >
          初めての購入はこちら
        </h1>

        <div
          class="
            container
            md:max-w-screen-md
            xl:max-w-screen-lg
            px-6
            mx-auto
          "
        >
          <div class="bg-gray-p-bg">

            <div
              class="
                lg:w-96
                md:w-64
                w-64 mx-auto
                pt-10
              "
            >
              <button
                type="submit"
                class="
                  w-full
                  h-full
                  text-white
                  py-4
                  text-xs
                  md:text-sm
                  lg:text-xl
                  lg:py-6
                  bg-black-p-btn
                  rounded-md
                "
              >
                初めて購入する方はこちら
              </button>
            </div>

            <p
              class="
                px-12
                py-5
                leading-relaxed
                text-center
                mx-auto
                lg:px-12
                lg:py-8
                md:px-48
                lg:w-full
                text-xs
                md:text-sm
                lg:text-xl
              "
            >
              サブスクリプション管理のため購入の際に会員登録されます
            </p>
          </div>
        </div>
      </div>

      <div
        class="
          container
          md:max-w-screen-m
          xl:max-w-screen-lg
          px-6
          mx-auto
        "
      >

      </div>

      <div>
        <div
          class="
            w-20
            text-xl
            mx-auto
            mt-8
            mb-12
            lg:w-24
            lg:text-2xl
            lg:mt-16
            lg:mb-14
            mg:w-32
          "
        >
          <button
            type="submit"
            class="
              w-full
              h-full
              text-white
              py-2
              text-xs
              md:text-sm
              lg:text-xl
              bg-black-p-btn
              rounded-md
            "
            @click="redirectToPrevPage()"
            >
              戻   る
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import LoginForm from '../../components/LoginPage/LoginForm.vue';

export default {
  name: 'LoginFromBuying',
  components: {
    LoginForm,
  },
  props: {
  },
  methods: {
    redirectToPrevPage() {
      this.$router.go(-1);
    },
  },
};
</script>
