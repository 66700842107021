export class User {
  constructor(
    email,
    firstName,
    lastName,
    firstNameKana,
    lastNameKana,
    phoneNumber,
    addressPostCode,
    prefecture,
    address,
    affilicateCode,
    deliveryAddress,
    isActive,
  ) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.firstNameKana = firstNameKana;
    this.lastNameKana = lastNameKana;
    this.phoneNumber = phoneNumber;
    this.addressPostCode = addressPostCode;
    this.prefecture = prefecture;
    this.address = address;
    this.affilicateCode = affilicateCode;
    this.deliveryAddress = deliveryAddress;
    this.isActive = isActive;
  }

  get getDeliveryAddress() {
    return this.deliveryAddress;
  }

  get fullName() {
    return `${this.lastName} ${this.firstName}`;
  }

  get fullNameKana() {
    return `${this.lastNameKana} ${this.firstNameKana}`;
  }

  setBankAccountInfo(bankAccountInfo) {
    this.bankAccountInfo = bankAccountInfo;
  }
}

function convertFromResponse(obj) {
  return new User(
    obj.email,
    obj.first_name,
    obj.last_name,
    obj.first_name_kana,
    obj.last_name_kana,
    obj.phone_number,
    obj.address_postcode,
    obj.prefecture,
    obj.address,
    obj.affiliate_code,
    obj.delivery_address,
    obj.is_active,
  );
}

export const UserAdapter = {
  convertFromResponse,
};
