<template>
  <div class="flex flex-col items-center lg:my-10 my-4 pagination"
    v-if="totalPage > 1"
  >
      <div class="flex text-gray-400">
        <div class="previous
            h-7
            w-4
            mr-1
            flex
            justify-center
            items-center
            rounded-full
            cursor-pointer
          "
          v-if="currentPage - 1 >= 1"
          @click="pageChange(currentPage - 1)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left w-4 h-4"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </div>

        <div class="flex h-7 font-medium">
          <div
            class="
              mx-2
              w-7
              flex
              justify-center
              items-center
              cursor-pointer
              leading-5
              transition
              duration-150
              ease-in
              rounded-full
            "
            v-for="page in displayRange"
            :key="page"
            :class="{ 'current': page === currentPage}"
            @click="pageChange(page)"
          >
            {{ page }}
          </div>
        </div>

        <div class="next
            h-7
            w-4
            ml-1
            flex
            justify-center
            items-center
            rounded-full
            cursor-pointer
          "
          v-if="currentPage + 1 <= totalPage"
          @click="pageChange(currentPage + 1)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right w-4 h-4"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Paginate',
  emits: ['getDeliveryAddressPerPage'],
  props: {
    totalPage: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    currentPage: 1,
  }),
  computed: {
    displayRange() {
      let range = [];
      let numberOfSlotOneSide = 3;
      let firstPage = 1;

      let leftStart = this.currentPage - numberOfSlotOneSide;
      if (leftStart <= 0) {
        for (let i = 1; i <= this.currentPage; i += 1) {
          range.push(i);
        }
        for (let i = 1; i <= Math.abs(leftStart) + 1; i += 1) {
          if (this.currentPage + i <= this.totalPage) {
            range.push(this.currentPage + i);
          }
        }
      } else if (leftStart >= 1) {
        range.push(firstPage, '...');
        for (let i = numberOfSlotOneSide - 2; i >= 0; i -= 1) {
          range.push(this.currentPage - i);
        }
      } else {
        for (let i = 1; i <= this.currentPage; i += 1) {
          range.push(i);
        }
      }

      let leftEnd = range[range.length - 1];
      let rightEnd = leftEnd + numberOfSlotOneSide;
      if (rightEnd > this.totalPage) {
        for (let i = 1; i <= rightEnd - this.totalPage; i += 1) {
          // range.splice(range.length - 1 -i, 0, leftEnd - i);
          // TODO: bu lai phan thua ra
        }

        for (let i = 1; i <= this.totalPage - leftEnd; i += 1) {
          if (leftEnd + i <= this.totalPage) {
            range.push(leftEnd + i);
          }
        }
      } else {
        for (let i = 1; i <= numberOfSlotOneSide - 2; i += 1) {
          range.push(leftEnd + i);
        }
        range.push('...', this.totalPage);
      }

      return range;
    },
  },
  mounted() {
    this.currentPage = this.modelValue;
  },
  methods: {
    pageChange(page) {
      if (page === '...') return;
      this.currentPage = page;
      this.$emit('update:modelValue', page);
    },
  },
};
</script>
<style scoped>
.current {
  background-color: #707070;
  color: white;
}
</style>
