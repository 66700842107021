<template>
  <div class="w-full h-full compensation-top-nav lg:mb-32 mb-10">
    <div class="lg:container mx-auto px-3 p-static">
      <h1 class="md:text-4xl text-2xl md:pt-14 md:mb-16 mb-6">よくあるご質問</h1>
      <article class="md:text-2xl leading-9">
        <div class="border-t-2 md:py-5"
          v-for="(qa, i) in qaList" :key="i"
        >
            <div class="flex my-5">
                <span class="tag-qa sm:h-11 sm:w-11 h-10 w-11 bg-green-900
                text-white text-center flex justify-center items-center rounded-full">Q</span>
                <span class="w-full pl-4">{{qa.q}}</span>
            </div>
             <div class="flex my-5">
                <span class="tag-qa sm:h-11 sm:w-11 h-10 w-11 bg-yellow-900
                text-white text-center flex justify-center items-center rounded-full">A</span>
                <span  class="w-full pl-4">{{qa.a}}</span>
            </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>

export default {
  name: 'QA',
  components: {

  },
  data: () => ({
    qaList: [
      {
        q: 'お支払い方法は何がありますか？',
        a: '現在クレジットカード、デビッドカードのみのお取り扱いとなります。',
      },
      {
        q: '配送先は自由に設定できますか？',
        a: 'はい、毎月月末の日が変わるまでに設定していただいた配送先に送られます。',
      },
      {
        q: '気に入った商品をリピートできますか？',
        a: 'はい、各お店でご対応いただけますのでショップ宛にお問い合わせくださいませ。',
      },
      {
        q: '送料は決まっていますか？',
        a: 'Weeatのサービスは送料を含めた価格となっています。',
      },
      {
        q: '紹介報酬の発生条件はありますか？',
        a: 'アカウントをお持ちの方が商品ページから発行した紹介URLから注文が入った分に関して3%の紹介報酬が発生します。',
      },
      {
        q: '紹介報酬は現金で受け取りますか？',
        a: 'ご指定いただく受取り口座宛に現金でお振込します。10,000円以下の場合は手数料250円がかかります。また、口座間違え等による組み戻しの手数料は利用者様の負担となります。',
      },
      {
        q: '配送日はいつですか？',
        a: '毎月１０日前後に到着するよう配送しており、詳細の指定は承っておりません。ショップに直接お問い合わせいただき、ショップが対応できる場合はこの限りではありません。',
      },
      {
        q: '返品はできますか？',
        a: '不良品のみ返品可能ですが、詳細は各店舗の規定によりますのでご確認ください。',
      },
      {
        q: 'サブスクは一時停止などできますか？',
        a: '当サービスでは自由に次回のスキップ、また利用の停止をしていただけます。',
      },
      {
        q: 'サブスクの解約をしたいのですが。',
        a: '当サービスでは自由に利用の停止をしていただけます。停止したままの状態ですと今後の課金や配送は一切行われません。',
      },
      {
        q: 'アカウントを削除したい',
        a: 'アカウントの削除を行うと全てのサービスがストップします。問題ない場合、解約希望を運営へのお問い合わせフォームよりお送りください。',
      },
    ],
  }),
};
</script>
