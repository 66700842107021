<template>

    <h1 class="text-2xl md:pt-10" v-if="loggedIn">
      こんにちは {{loggedInUser.lastName}} {{loggedInUser.firstName}} さん
    </h1>
    <h1 class="text-2xl md:pt-10" v-else>こんにちは ゲスト さん</h1>

    <div class="grid lg:grid-cols-6 grid-cols-6 lg:gap-5 gap-1 lg:my-10 my-5">

      <router-link
        :to="{
          name: 'my-orders',
        }"
      >
        <div class="col-span-1">
          <div
            class="
              border
              lg:py-4
              pt-2
              lg:px-2
              border-gray-400
              text-center
              tabs-item
            "
            :class="[activeMyOrder ? activeBgClass : defaultBgClass]"
          >
            <img
              v-if="!activeMyOrder"
              src="../../assets/images/truck.png"
              alt=""
              class="mx-auto lg:hidden h-5"
            />
            <img
              v-else
              src="../../assets/svg_icon/truck-active.svg"
              alt=""
              class="mx-auto lg:hidden h-5"
            />
            <span class="text-small"><span class="hidden md:inline">マイ</span>オーダー</span>
          </div>
        </div>
      </router-link>

      <router-link
        :to="{
          name: 'my-shipments',
        }"
      >
        <div class="col-span-1">
          <div
            class="
              border
              lg:py-4
              pt-2
              lg:px-2
              border-gray-400
              text-center
              tabs-item
            "
            :class="[activeMyShipments ? activeBgClass : defaultBgClass]"
          >
            <img
              v-if="!activeMyShipments"
              src="../../assets/images/cart.png"
              alt=""
              class="mx-auto lg:hidden h-5"
            />
            <img
              v-else
              src="../../assets/svg_icon/cart-active.svg"
              alt=""
              class="mx-auto lg:hidden h-5"
            />
            <span class="text-small">購入履歴</span>
          </div>
        </div>
      </router-link>

      <router-link
        :to="{
          name: 'my-favorite-products',
        }"
      >
        <div class="col-span-1">
          <div
            class="
              border
              lg:py-4
              pt-2
              lg:px-2
              border-gray-400
              text-center
              tabs-item
            "
            :class="[activeMyFavoriteProducts ? activeBgClass : defaultBgClass]"
          >
            <img
              v-if="!activeMyFavoriteProducts"
              src="../../assets/images/heart.png"
              alt=""
              class="mx-auto lg:hidden h-5"
            />
            <img
              v-else
              src="../../assets/svg_icon/heart-active.svg"
              alt=""
              class="mx-auto lg:hidden h-5"
            />
            <span class="text-small">お気に入り</span>
          </div>
        </div>
      </router-link>

      <router-link
        :to="{
          name: 'my-info',
        }"
      >
        <div class="col-span-1">
          <div
            class="
              border
              lg:py-4
              pt-2
              lg:px-2
              border-gray-400
              text-center
              tabs-item
            "
            :class="[activeMyInfo ? activeBgClass : defaultBgClass]"
          >
            <img
              v-if="!activeMyInfo"
              src="../../assets/images/pen.png"
              alt=""
              class="mx-auto lg:hidden h-5"
            />
            <img
              v-else
              src="../../assets/svg_icon/pen-active.svg"
              alt=""
              class="mx-auto lg:hidden h-5"
            />
            <span class="text-small">会員情報</span>
          </div>
        </div>
      </router-link>

      <!-- Todo: update icon -->
      <router-link
        :to="{
          name: 'my-delivery-management',
        }"
      >
        <div class="col-span-1">
          <div
            class="
              border
              lg:py-4
              pt-2
              lg:px-2
              border-gray-400
              text-center
              tabs-item
            "
            :class="[activeMyDeliveryManagement ? activeBgClass : defaultBgClass]"
          >
              <img
                v-if="!activeMyDeliveryManagement"
                src="../../assets/svg_icon/contacts-black.png"
                alt=""
                class="mx-auto lg:hidden h-5"
              />
              <img
                v-else
                src="../../assets/svg_icon/contacts.png"
                alt=""
                class="mx-auto lg:hidden h-5"
              />
              <span class="text-small">配送先管理</span>
          </div>
        </div>
      </router-link>

      <router-link
        :to="{
          name: 'my-referral-reward',
        }"
      >
        <div class="col-span-1">
          <div
            class="
              border
              lg:py-4
              pt-2
              lg:px-2
              border-gray-400
              text-center
              tabs-item
            "
            :class="[activeMyReferralReward ? activeBgClass : defaultBgClass]"
          >
              <img
                v-if="!activeMyReferralReward"
                src="../../assets/images/friends.png"
                alt=""
                class="mx-auto lg:hidden h-5"
              />
              <img
                v-else
                src="../../assets/svg_icon/friends-active.svg"
                alt=""
                class="mx-auto lg:hidden h-5"
              />
              <span class="text-small">紹介料</span>
          </div>
        </div>
      </router-link>

    </div>

</template>
<script>
import { authComputed } from '@/helpers';

export default {
  name: 'Tabs',
  props: {
    activeMyOrder: {
      type: Boolean,
      default: false,
    },
    activeMyFavoriteProducts: {
      type: Boolean,
      default: false,
    },
    activeMyInfo: {
      type: Boolean,
      default: false,
    },
    activeMyReferralReward: {
      type: Boolean,
      default: false,
    },
    activeMyShipments: {
      type: Boolean,
      default: false,
    },
    activeMyDeliveryManagement: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({
    defaultBgClass: 'bg-gray-100',
    activeBgClass: 'bg-black-p-btn text-white',
  }),
  computed: {
    ...authComputed,
  },
};
</script>
