<template>
  <div v-if="!showSuccessMsg" class="container max-w-screen-xl mx-auto pt-40 px-12">
    <g-form ref="form">
      <p class="lg-text-2xl text-xl">お問い合わせ</p>
      <p
        class="
          lg-text-xl
          text-lg mb-8
          text-red-600"
      >
        ※各商品のお問い合わせはショップが対応しますので、商品ページのお問い合わせフォームよりご送信ください
      </p>
      <div class="mt-4 required">
        <InputField
          v-model="senderName"
          :rules="rules"
          required
          label="お名前"
        />
      </div>

      <div class="mt-6">
        <InputField
          label="会社名"
          v-model="companyName"
        />
      </div>

      <div class="mt-6 required" id="email-input">
        <InputField
          v-model="email"
          required
          type="email"
          label="メールアドレス"
        />
      </div>

      <div class="mt-6 required">
        <InputField
          v-model="phoneNumber"
          required
          label="電話番号"
          charSet='number'
        />
      </div>

      <div class="mt-6 required">
        <SelectField
          :options="contactTypes"
          className="p-2"
          v-model="selectedContactType"
          required
          label="お問い合わせ内容"
        />
      </div>

      <div class="mt-6 required">
        <TextAreaField
          v-model="content"
          required
          label="メッセージ"
        />
      </div>

      <div>
        <div
          class="
            w-20
            text-xl
            mx-auto
            mt-8
            mb-12
            lg:w-24
            lg:text-2xl
            lg:mt-16
            lg:mb-14
            mg:w-32
          "
        >
          <button
            type="submit"
            class="
              btn
              w-full
              h-full
              text-white
              py-0
              text-xs
              md:text-sm
              lg:text-xl
              lg:py-2
              bg-black-p-btn
              rounded-md
            "
            @click.prevent="send()"
            >
              送 信
          </button>
        </div>
      </div>
    </g-form>
  </div>

  <div v-else
    class="
      container
      mx-auto
      lg:mt-80
      mt-32
      text-center
    "
  >
    <p
      class="
        text-center
        lg:text-3xl
        text-xl
      "
    >
      送信しました
    </p>
    <div
      class="
        lg:w-48
        md:w-30
        lg:mb-32
        lg:mt-14
        w-40
        mx-auto
        my-8
      "
    >
      <g-button @click="$router.push('/')">トップページへ</g-button>
    </div>
  </div>
</template>

<script>
import { SystemSettingService, UserService } from '@/services';
// import InputField from '../components/common/InputField.vue';
import SelectField from '../components/common/SelectField.vue';
import TextAreaField from '../components/common/TextAreaField.vue';
import { authComputed } from '@/helpers';
import GForm from '../components/common/GForm.vue';
import GButton from '../components/common/GButton.vue';

export default {
  name: 'ContactForm',
  components: {
    // InputField,
    SelectField,
    TextAreaField,
    GForm,
    GButton,
  },
  computed: {
    ...authComputed,
  },
  data: () => ({
    senderName: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    selectedContactType: '',
    content: '',
    contactTypes: [
      'サービスに関するお問い合わせ',
      'ご注文に関するお問い合わせ',
      '出店について',
      '取材のご依頼',
      'その他',
    ],

    showSuccessMsg: false,
  }),
  mounted() {
    if (this.loggedIn) {
      UserService.getCurrentUser().then((user) => {
        this.senderName = `${user.lastName} ${user.firstName}`;
        this.email = user.email;
        this.phoneNumber = user.phoneNumber;
      });
    }
  },
  methods: {
    async send() {
      if (!this.$refs.form.isValid()) return;

      let payload = {
        name: this.senderName,
        email: this.email,
        phone_number: this.phoneNumber,
        contact_type: this.selectedContactType,
        content: this.content,
      };

      if (this.companyName) payload.company_name = this.companyName;

      const res = await SystemSettingService.sendContactFormToAdmin(payload);
      if (res.status === 200) {
        this.showSuccessMsg = true;
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>
