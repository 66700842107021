function calNextPayDateOfOrder(order) {
  let now = new Date();

  if (order.isPassNextMonth) {
    return new Date(now.getFullYear(), now.getMonth() + 2, 1);
  }
  return new Date(now.getFullYear(), now.getMonth() + 1, 1);
}

function totalPriceWithCoupon(cartTotalPrice, coupon) {
  let couponAmount = coupon.discount_amount ?? 0;
  if (coupon.discount_type === 'percent') {
    couponAmount = Math.floor((cartTotalPrice * coupon.discount_amount) / 100 + 0.5);
  }

  if (couponAmount > cartTotalPrice) {
    return 0;
  }
  return cartTotalPrice - couponAmount;
}

export const utils = {
  calNextPayDateOfOrder,
  totalPriceWithCoupon,
};
