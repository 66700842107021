import { mapGetters } from 'vuex';

export * from './utils';

export const authComputed = {
  ...mapGetters({
    loggedIn: 'user/loggedIn',
    loggedInUser: 'user/loggedInUser',
  }),
};

export const systemSetting = {
  ...mapGetters({
    productCategory: 'setting/category',
    // pcSetting: 'setting/pcSetting',
    // mobileSetting: 'setting/mobileSetting',
    systemSetting: 'setting/systemSetting',
    isMobile: 'setting/isMobile',
  }),
};

export const cart = {
  ...mapGetters({
    cartItems: 'cart/cartItems',
    cartTotalPrice: 'cart/cartTotalPrice',
  }),
};
