export class DeliveryAddress {
  constructor() {
    this.addressBookId = '';
    this.firstName = '';
    this.lastName = '';
    this.phoneNumber = '';
    this.addressPostCode = '';
    this.prefecture = '';
    this.address = '';
    this.createdAt = null;
    this.updatedAt = null;
    this.customerId = '';
    this.displayName = '';
    this.cityCode = '';
    this.districtCode = '';
    this.isMain = false;
  }

  setVal(
    id,
    firstName,
    lastName,
    phoneNumber,
    addressPostCode,
    prefecture,
    address,
    createdAt,
    updatedAt,
    customerId,
    isMain,
  ) {
    this.addressBookId = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.addressPostCode = addressPostCode;
    this.prefecture = prefecture;
    this.address = address;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.customerId = customerId;
    this.displayName = `${lastName} ${firstName}`;

    if (addressPostCode) {
      this.cityCode = addressPostCode.slice(0, 3);
      this.districtCode = addressPostCode.slice(3, 7);
    } else {
      this.cityCode = '';
      this.districtCode = '';
    }

    this.isMain = isMain;
  }
}

function convertFromResponse(obj) {
  let add = new DeliveryAddress();
  add.setVal(
    obj.id,
    obj.first_name,
    obj.last_name,
    obj.phone_number,
    obj.address_postcode,
    obj.prefecture,
    obj.address,
    obj.created_at,
    obj.updated_at,
    obj.customer_id,
    obj.is_main,
  );
  return add;
}

export const DeliveryAddressAdapter = {
  convertFromResponse,
};
