import { createApp } from 'vue';
import Toaster from '@meforma/vue-toaster';
import App from './App.vue';
import '@/style/index.css';
import router from '@/routers';
import store from './store';

import InputField from '@/components/common/InputField.vue';
import GButton from '@/components/common/GButton.vue';
import GForm from '@/components/common/GForm.vue';
import GoBackBtn from '@/components/common/GoBackBtn.vue';

const app = createApp(App);
app.use(router);
app.use(store);
app.use(Toaster);

app.component('go-back-btn', GoBackBtn);
app.component('InputField', InputField);
app.component('g-button', GButton);
app.component('g-form', GForm);

app.config.globalProperties.$filters = {
  formatCurrency(val) {
    return `${val}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  },
  formatJpDatetime(val, format) {
    if (!val) return '';
    let date = typeof val === 'string' ? new Date(val) : val;

    if (format === 'MM/DD') {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    }
    return date.toLocaleDateString('ja-JP');
  },
};

app.mount('#app');

window.addEventListener('beforeunload', () => {
  app.unmount();
});
