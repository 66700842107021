 <template>
  <div>
    <div>
      <div
        class="
          w-full
          flex
          items-center
          lg:mb-8
          mb-4
          text-xl
          md:text-2xl
          lg:text-3xl
        "
      >
        <span class="rounded bg-gray-500 w-10" style="height: 1px"></span>
        <span class="px-3">会員様情報</span>
        <span class="flex-grow rounded bg-gray-500" style="height: 1px"></span>
      </div>
      <div class="info mb-3 lg:mb-7">
        <h3>お名前</h3>
        <p>{{fullName}}<span class="ml-4">
          （{{fullNameKana}}）</span></p>
      </div>
      <div class="info mb-3 lg:mb-7">
        <h3>メールアドレス</h3>
        <p>{{email}}</p>
      </div>
      <div class="info mb-3 lg:mb-7">
        <h3>電話番号</h3>
        <p>{{phoneNumber}}</p>
      </div>
      <div class="info mb-3 lg:mb-7">
        <h3>郵便番号</h3>
        <p>{{cityCode}}-{{districtCode}}</p>
      </div>
      <div class="info mb-3 lg:mb-7">
        <h3>住所（都道府県から）</h3>
        <p>{{prefecture}} {{address}}</p>
      </div>
    </div>
  </div>

</template>

<script>
import { UserService } from '@/services';

export default {
  name: 'InfoUser',
  components: {
  },
  data: () => ({
    fullName: '',
    fullNameKana: '',
    // firstName: '',
    // lastName: '',
    // firstNameKana: '',
    // lastNameKana: '',
    email: '',
    phoneNumber: '',
    cityCode: '',
    districtCode: '',
    prefecture: '',
    address: '',

  }),
  mounted() {
    UserService.getCurrentUser().then((currUser) => {
      // this.firstName = currUser.firstName;
      // this.lastName = currUser.lastName;
      // this.firstNameKana = currUser.firstNameKana;
      // this.lastNameKana = currUser.lastNameKana;
      this.fullName = currUser.fullName;
      this.fullNameKana = currUser.fullNameKana;
      this.email = currUser.email;
      this.phoneNumber = currUser.phoneNumber;
      if (currUser.addressPostCode) {
        this.cityCode = currUser.addressPostCode.substring(0, 3);
        this.districtCode = currUser.addressPostCode.substring(3);
      }
      this.prefecture = currUser.prefecture;
      this.address = currUser.address;
    });
  },
};
</script>
