 <template>
  <div>
    <p class="mb-3">合計計算</p>
    <div class="border bg-white p-3">
      <div class="flex py-2 border-b justify-between">
        <span>小計（税抜）</span>
        <span>{{$filters.formatCurrency(amountWithoutTax)}}円</span>
      </div>
      <div class="flex py-2 border-b justify-between">
        <span>割引</span>
        <span>0円</span>
      </div>
      <div class="flex py-2 border-b justify-between">
        <span>(10%対象)</span>
        <span>0円</span>
      </div>
      <div class="flex py-2 border-b justify-between">
        <span>(8%対象)</span>
        <span>{{$filters.formatCurrency(eightPercentTaxAmount)}}円</span>
      </div>
      <div class="flex py-2 border-b justify-between">
        <span>クーポン</span>
        <span v-if="coupon.discount_type=='amount'">{{coupon.discount_amount}}円</span>
        <span v-else>{{coupon.discount_amount}}%</span>
      </div>
      <div class="flex py-2 justify-between text-xl">
        <span>合計（税込）</span>
        <span>{{$filters.formatCurrency(totalPriceWithCoupon)}}円</span>
      </div>
    </div>
  </div>
</template>

<script>
import { cart, utils } from '@/helpers';

export default {
  name: 'TotalOrderValue',
  computed: {
    ...cart,
    eightPercentTaxAmount() {
      return Math.round((this.cartTotalPrice * 8) / 108);
    },
    amountWithoutTax() {
      return this.cartTotalPrice - this.eightPercentTaxAmount;
    },
    totalPriceWithCoupon() {
      return utils.totalPriceWithCoupon(this.cartTotalPrice, this.coupon);
    },
    coupon() {
      return this.$store.getters['cart/coupon'];
    },
  },
};
</script>
