/* eslint-disable no-shadow */
import axios from 'axios';
import { UserService, AuthService } from '@/services';
import { User } from '@/services/adapter';

const TOKEN_KEY = 'credentials';

class LoggedInSession {
  constructor() {
    this.accessToken = '';
    this.refreshToken = '';
    this.tokenRefreshing = false;
    this.user = new User();
  }

  static createEmptySession() {
    return new LoggedInSession();
  }

  static createLoggedInSession(token) {
    let session = new LoggedInSession();
    session.setToken(token);
    return session;
  }

  setToken(token) {
    this.accessToken = token.access_token;
    this.refreshToken = token.refresh_token;
  }
}

const savedToken = localStorage.getItem(TOKEN_KEY);
const state = savedToken
  ? LoggedInSession.createLoggedInSession(JSON.parse(savedToken))
  : LoggedInSession.createEmptySession();

const mutations = {
  SET_CREDENTIALS(state, token) {
    state.setToken(token);
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
    axios.defaults.headers.common.Authorization = `Bearer ${state.accessToken}`;
  },
  CLEAR_CREDENTIALS() {
    localStorage.removeItem(TOKEN_KEY);
    // eslint-disable-next-line no-restricted-globals
    location.replace('/');
    delete axios.defaults.headers.common.Authorization;
  },
  SET_LOGGEDIN_USER(state, user) {
    state.user = { ...state.user, ...user };
  },
  CLEAR_LOGGEDIN_USER(state) {
    state.user = new User();
  },
  START_REFRESH_TOKEN(state) {
    state.tokenRefreshing = true;
  },
  REFRESH_TOKEN_DONE(state) {
    state.tokenRefreshing = false;
  },
};

const actions = {
  login({ commit, dispatch }, { email, password }) {
    return AuthService.login(email, password).then((response) => {
      commit('SET_CREDENTIALS', response.data);
      return dispatch('getUser');
    });
  },
  refreshToken({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (state.tokenRefreshing) {
        setTimeout(() => {
          if (state.tokenRefreshing) {
            actions.refreshToken({ state, commit });
          } else {
            resolve(state.accessToken);
          }
        }, 1000);
      } else {
        commit('START_REFRESH_TOKEN');
        AuthService.refreshToken(state.refreshToken)
          .then((res) => {
            commit('SET_CREDENTIALS', res.data);
            commit('REFRESH_TOKEN_DONE');
            resolve(state.accessToken);
          })
          .catch((error) => reject(error));
      }
    });
  },
  logout({ commit, dispatch }) {
    dispatch('cart/clear', null, { root: true });
    commit('CLEAR_CREDENTIALS');
  },
  async register({ dispatch }, user) {
    await UserService.register(user);
    await dispatch('login', user);
    return true;
  },
  getUser({ commit }) {
    return UserService.getCurrentUser().then((user) => {
      commit('SET_LOGGEDIN_USER', user);
    });
  },
};

const getters = {
  loggedIn(state) {
    return !!state.accessToken;
  },
  loggedInUser(state) {
    let loggedInUser = { ...state.user };
    return loggedInUser;
  },
};

export const UserSession = {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
