<template>
  <div class="lg:pt-24 pt-8">
    <LoginForm
    />

    <!-- go to checkout then auto register account -->
    <div v-if="$route.query.from === 'checkout'">
      <h1
        class="
          pt-10
          pb-7
          text-center
          text-3xl
          lg:p-16
          md:text-5xl
          lg:text-4xl
        "
      >
        初めて購入する方はこちら
      </h1>
      <div
        class="
          container md:max-w-screen-md xl:max-w-screen-lg px-6 mx-auto
          pt-12
          pb-12
          bg-gray-p-bg
          text-center
        "
      >
        <button
          type="submit"
          class="
            lg:w-96
            md:w-64
            text-white
            p-4
            md:text-lg
            lg:text-xl
            lg:py-6
            bg-black-p-btn
            rounded-md
          "
          @click="goToCheckoutWithAutoRegister()"
        >
            初めて購入する方はこちら
        </button>
        <p class='mt-8'>サブスクリプション管理のため購入の際に会員登録されます</p>
      </div>
    </div>

    <!-- register new user only -->
    <div v-else
      class="
        container
        md:max-w-screen-md
        xl:max-w-screen-lg
        px-6 mx-auto
      "
    >

      <div
        class="
          container
          md:max-w-screen-md

          xl:max-w-screen-lg
          px-6
          mx-auto
        "
      >
        <div
          class="
            lg:w-96
            md:w-64
            lg:mt-20
            mt-14
            w-64 mx-auto
          "
        >
          <button
            type="submit"
            class="
              w-full
              h-full
              text-white
              py-4
              md:text-lg
              lg:text-xl
              lg:py-6
              bg-black-p-btn
              rounded-md
            "
            @click="redirectToSignup()"
          >
            会員登録のみはこちらから
          </button>
        </div>
      </div>

    </div>

    <!-- back btn -->
    <div>
      <div
        class="
          w-20
          mx-auto
          mt-8
          mb-12
          lg:mt-16
          lg:mb-14
          mg:w-32
        "
      >
        <button
          type="submit"
          class="
            w-full
            h-full
            text-white
            py-2
            md:text-lg
            lg:text-xl
            bg-black-p-btn
            rounded-md
          "
          @click="redirectToPrevPage()"
          >
            戻   る
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import LoginForm from '../../components/LoginPage/LoginForm.vue';
import { authComputed } from '@/helpers';

export default {
  name: 'LoginFromIcon',
  components: {
    LoginForm,
  },
  data: () => ({
    email: '',
    password: '',
  }),
  computed: {
    ...authComputed,
  },
  mounted() {
    if (this.loggedIn) this.$router.push({ name: 'home' });
  },
  methods: {
    redirectToPrevPage() {
      this.$router.go(-1);
    },
    redirectToSignup() {
      this.$router.push({ name: 'signup' });
    },
    goToCheckoutWithAutoRegister() {
      this.$router.push({ name: 'cart', params: { step: 'step2', mode: 'autoregister' } });
    },
  },
};
</script>
