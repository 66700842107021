<template>
    <div class="w-full h-full lg:mb-32 compensation-top-nav mb-10">
       <div class="lg:container mx-auto px-3 p-static">
         <h1 class="md:text-4xl md:mb-16 md:pt-14">特定商取引法に基づく表記</h1>
         <div class="md:text-base grid md:grid-cols-4 table-custom gap-0 mb-4
         md:border-b md:border-l border-gray-500">
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              サービス名称
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              Weeat
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              販売者
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              商品ごとに表示
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              運営責任者
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              合同会社Lelien
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              代表者
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              中尾  恵
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              所在地
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              東京都渋谷区神宮前六丁目２３-４ ２F（サテライト）
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              連絡先
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              メールフォームよりお問い合わせください
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              商品価格
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              商品ごとに表示します
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              配送料金について
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              Weeatのサブスクには送料が含まれます
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              お支払い料金
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              クレジットカード
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              お支払い日
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              毎月１日
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              お届け地域
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              日本国内
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              商品の引き渡し時期
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              １日の課金後１０日以内にお届け
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              返品交換について
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3 md:pr-10">
              当サービスで扱っているのは食品となりますので、性質上返品や交換は一切
承っておりません。ただし、汚損や破損、不良品と判断される場合は到着後
商品ページ記載の所定の期限以内にショップにご連絡をいただいた場合に限
り返品・交換の対応を行います。
           </div>
           <div class="md:col-span-1 md:text-center
            bg-gray-200 md:border border-gray-500 px-4 py-3">
              定期購入の条件
           </div>
           <div class="md:col-span-3 md:border border-gray-500 px-4 py-3 md:mb-0 mb-3">
              毎月１日に課金され、課金後のキャンセルは承っておりません。<br/>
なお任意のタイミングでストップやスキップが可能です。
           </div>
         </div>
       </div>
    </div>
</template>

<script>

export default {
  name: 'CommercialTransactions',
  components: {

  },
  data: () => ({}),
};
</script>
