<template>
<div class="lg:container mx-auto px-3 compensation-top-nav pb-10">
  <div v-if="!showSuccessMsg">
      <UserTabs :activeMyDeliveryManagement="true"/>
      <div>
        <div class="bg-gray-p-text-color text-white px-4 py-3 mt-3 lg:mb-6 mb-4">次回送付先変更</div>
        <ShippingAddressForm
          @save="save"
          :showBtn="true"
        />
      </div>
  </div>

  <div
    v-else
    class="
      container
      mx-auto
      lg:mt-28
      mt-8
      text-center
    "
  >
    <p
      class="
        text-center
        lg:text-3xl
        text-xl
      "
    >
      配送先を登録しました
    </p>
  </div>
</div>
</template>

<script>
import UserTabs from '../../components/UserPage/UserTabs.vue';
import ShippingAddressForm from '../../components/UserPage/ShippingAddressForm.vue';
import { UserService } from '@/services';

export default {
  name: 'MyDeliveryAddressCreate',
  components: {
    UserTabs,
    ShippingAddressForm,
  },
  data: () => ({
    isProcessing: false,
    showSuccessMsg: false,
  }),
  methods: {
    save(data) {
      if (this.isProcessing) return;
      this.isProcessing = true;
      UserService.createDeliveryAddress(data).then(() => {
        this.showSuccessMsg = true;
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.$router.push({ name: 'my-delivery-management' });
        }, 2000);
      }).finally(() => {
        this.isProcessing = false;
      });
    },
  },
};
</script>
