let DEFAULT_LOGO = require('@/assets/images/default_shop_logo.jpg');
let DEFAULT_BANNER_SP = require('@/assets/images/default_shop_banner_sm.jpg');
let DEFAULT_BANNER_PC = require('@/assets/images/default_shop_banner_pc.jpg');

class Setting {
  constructor() {
    this.facebookLink = '';
    this.instagramLink = '';
    this.introduction = '';
    this.linkedinLink = '';
    this.logo = '';
    this.mobileBanner = '';
    this.pcBanner = '';
    this.productReturnPolicy = '';
    this.tiktokLink = '';
    this.twitterLink = '';
    this.website = '';
  }

  setVal(obj) {
    this.facebookLink = obj.facebook_link;
    this.instagramLink = obj.instagram_link;
    this.introduction = obj.introduction;
    this.linkedinLink = obj.linkedin_link;

    if (obj.logo) {
      this.logo = obj.logo;
    } else {
      this.logo = DEFAULT_LOGO;
    }

    if (obj.mobile_banner) {
      this.mobileBanner = obj.mobile_banner;
    } else {
      this.mobileBanner = DEFAULT_BANNER_SP;
    }

    if (obj.pc_banner) {
      this.pcBanner = obj.pc_banner;
    } else {
      this.pcBanner = DEFAULT_BANNER_PC;
    }

    this.productReturnPolicy = obj.product_return_policy;
    this.tiktokLink = obj.tiktok_link;
    this.twitterLink = obj.twitter_link;
    this.website = obj.website;
  }
}
export class Shop {
  constructor() {
    this.id = '';
    this.ownerName = '';
    this.name = '';
    this.nameFurigana = '';
    this.phoneNumber = '';
    this.managerNameForDisplay = '';
    this.managerNameFuriganaForDisplay = '';
    this.allowAffiliate = '';
    this.affiliateRate = '';
    this.status = '';
    this.isTopBranch = false;
    this.setting = new Setting();
    this.ceoAddress = '';
    this.ceoAddressPostcode = '';
    this.ceoName = '';
    this.ceoNameFurigana = '';
  }

  setVal(obj) {
    this.id = obj.id;
    this.ownerName = obj.owner_name;
    this.name = obj.name;
    this.nameFurigana = obj.name_furigana;
    this.phoneNumber = obj.phone_number;
    this.managerName = obj.manager_name_for_display;
    this.managerNameFurigana = obj.manager_name_furigana_for_display;
    this.allowAffiliate = obj.allow_affiliate;
    this.affiliateRate = obj.affiliate_rate;
    this.isTopBranch = obj.is_top_branch;
    this.status = obj.status;
    this.ceoAddress = obj.ceo_address;
    this.ceoAddressPostcode = obj.ceo_address_postcode;
    this.ceoName = obj.ceo_name;
    this.ceoNameFurigana = obj.ceo_name_furigana;
    let setting = new Setting();
    setting.setVal(obj.setting);
    this.setting = setting;
  }
}

function convertListItemFromResponse(obj) {
  let shop = new Shop();
  shop.setVal(obj);
  return shop;
}

function convertDetailItemFromResponse(obj) {
  let shop = new Shop();
  shop.setVal(obj);
  return shop;
}

export const ShopAdapter = {
  convertListItemFromResponse,
  convertDetailItemFromResponse,
};
