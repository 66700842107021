<template>
    <!-- <div class="w-full relative mb-10">
        <div class="news_img">
            <img
            class="w-full h-full object-cover"
            :src="news.thumb"
            />
        </div>
        <div class="w-full flex items-center my-3">
            <span class="flex-grow rounded bg-gray-500 text-sm" style="height: 1px"></span>
            <span class="px-5">{{$filters.formatJpDatetime(news.publishedAt)}}</span>
        </div>
        <div>
            <h2 class="line-clamp-2 lg:mb-3 mb-1 md:text-lg">
                {{news.title}}
            </h2>
            <p class="line-clamp-3 mb-3 text-xs md:text-base">
                {{ news.briefText}}
            </p>
            <router-link :to="{ name: 'news-detail', params: { id: news.id }}"
                class="text-xs md:text-sm text-red-600"
            >
                もっと見る  ▶︎
            </router-link>
        </div>
    </div> -->
    <router-link
          :to="{ name: 'news-detail', params: { id: news.id }}"
        >
          <div class="w-full relative inline-block mb-3">
            <div class="dummy md:mt-56 mt-36"></div>
            <div class="thumb-img absolute inset-0 overflow-hidden">
              <img
                :src="news.thumb"
                class="object-cover w-full h-full"
              >
            </div>
          </div>
          <div class="w-full flex items-center my-2">
            <span class="flex-grow rounded bg-gray-500 text-sm" style="height: 1px"></span>
            <span class="px-5">{{$filters.formatJpDatetime(news.publishedAt)}}</span>
          </div>
          <div>
              <h2 class="line-clamp-2 lg:mb-3 mb-1 text-lg">
                {{news.title}}
              </h2>
              <p class="truncate line-clamp-3 mb-3">
                {{ news.briefText}}
              </p>
              <div class="hidden" v-html="news.content"></div>
              <router-link :to="{ name: 'news-detail', params: { id: news.id }}"
                class="text-sm text-red-600"
              >
                もっと見る  ▶︎
              </router-link>
          </div>
        </router-link>
</template>

<script>

export default {
  name: 'NewsItem',
  components: {
  },
  props: {
    news: {
      type: Object,
    },
  },
};
</script>
