<template>
    <div class="lg:container mx-auto compensation-top-nav px-3 pb-10" v-if="order">
        <UserTabs :activeMyOrder="true"/>
        <!-- <TableOrder /> -->
        <div v-if="[ 'active', 'not_started' ].includes(order.status)" class="mb-4">
          <div class="block md:hidden">次回課金日｜{{ calNextSubscriptionPayDate() }}
            <br/>各種設定は次回課金日の前日23:59までの設定が反映されます。</div>
          <div class="hidden sm:block">次回課金日｜{{ calNextSubscriptionPayDate() }}
             各種設定は次回課金日の前日23:59までの設定が反映されます。</div>
        </div>
        <h2 v-else>このサブスクリプションは停止中です</h2>
        <div class="md:flex block justify-between items-center">
          <div class="mb-4">
            <div><span class="w-4">◆</span>
              <router-link :to="{ name: 'shop-products', params: { id: order.shop.id }}"
                class="overme"
              >
                {{order.shop.name}}
              </router-link>
            </div>
            <div class="ml-4">
              <router-link :to="{ name: 'product-detail', params: { id: order.product.id }}"
              class="overme">
                {{order.product.name}}
              </router-link>
            </div>
            <div class="ml-4">{{order.selectedOptionName}}</div>
          </div>

          <div class="flex">
            <button v-if="!order.isPassNextMonth"
              :disabled="![ 'active', 'not_started' ].includes(order.status)"
              class="btn bg-red-800 text-white h-10 px-10 lg:w-auto w-2/5 mr-4"
              @click="openSkipConfirmDialog = true"
            >1回パス</button>
            <button v-else class="btn bg-red-800 text-white h-10 px-10 lg:w-auto w-2/5 mr-4"
              :disabled="![ 'active', 'not_started' ].includes(order.status)"
              @click="openCancelSkipConfirm = true"
            >パスの取り消し</button>

            <button v-if="[ 'active', 'not_started' ].includes(order.status)"
              class="btn bg-blue-900 text-white h-10 px-10 lg:w-auto w-2/5"
              @click="openCancelConfirm = true"
            >利用停止</button>
            <button v-else class="btn bg-blue-900 text-white h-10 px-10 lg:w-auto w-2/5"
              @click="openReOpenOrderConfirm = true"
            >再開</button>
          </div>
        </div>

        <div class="bg-gray-p-text-color text-white px-4 py-3 mt-3 lg:mb-6 mb-4">次回送付先変更</div>
        <div class="edit-shippng">
          <shipping-address-form :item="order" :showMainDetail="true"
              :addressBook="addressBook" ref="shippingAddress"></shipping-address-form>
        </div>
        <div class="bg-gray-p-text-color text-white px-4 py-3 mt-3 mb-6 lg:mb-10">お支払いカード変更</div>
        <div class="md:w-1/2">
          <PaymentMethodSelection ref="paymentMethod" :selected="order.paymentMethodId">
          </PaymentMethodSelection>
        </div>

        <div class="lg:pb-10 pb-6 text-center mt-6 w-24 mx-auto">
          <g-button @click="save()">保 存</g-button>
        </div>
        <!-- Banner  -->
        <!-- <div class="container px-3 mx-auto lg:mb-16 mb-9">
          <div class="lg:w-3/4 w-full mx-auto">
            <Banner v-if="systemSetting.banners[5]"
              :image="systemSetting.banners[5].image" :link="systemSetting.banners[5].link" />
          </div>
        </div> -->

        <go-back-btn></go-back-btn>
    </div>

    <confirm-dialog v-model="openSkipConfirmDialog"
      @yes="skipNextDelivery()"
    >
      <template v-slot:main>来月のお届けをパスしますか？</template>
    </confirm-dialog>

    <confirm-dialog v-model="openCancelSkipConfirm"
      @yes="cancelPassNextMonthRequest()"
    >
      <template v-slot:main>パスを取り消しますか？</template>
    </confirm-dialog>

    <confirm-dialog v-model="openCancelConfirm"
      @yes="cancelOrder()"
    >
      <template v-slot:main>このサブスクリプションを停止しますか？</template>
    </confirm-dialog>

    <confirm-dialog v-model="openReOpenOrderConfirm"
      @yes="reOpenOrder()"
    >
      <template v-slot:main>このサブスクリプションを再開しますか？</template>
    </confirm-dialog>
</template>

<script>
import UserTabs from '../../components/UserPage/UserTabs.vue';
import ShippingAddressForm from '@/components/CheckoutPage/ShippingAddressForm.vue';
import { systemSetting, utils } from '@/helpers';
import { OrderService, UserService } from '@/services';
import PaymentMethodSelection from '@/components/CheckoutPage/PaymentMethodSelection.vue';
import GButton from '../../components/common/GButton.vue';
import ConfirmDialog from '@/components/common/ConfirmDialog.vue';

export default {
  name: 'MyOrderEdit',
  components: {
    UserTabs,
    ShippingAddressForm,
    PaymentMethodSelection,
    GButton,
    ConfirmDialog,
  },
  computed: {
    ...systemSetting,
  },
  data: () => ({
    isProcessing: false,
    orderId: null,
    order: null,
    shippingAddressBookId: null,
    addressBook: [],
    openSkipConfirmDialog: false,
    openCancelSkipConfirm: false,
    openCancelConfirm: false,
    openReOpenOrderConfirm: false,
  }),
  async mounted() {
    this.orderId = this.$route.params.id;
    this.order = await OrderService.getOrderDetail(this.orderId);
    if (this.order.product.status === 'CLOSED') {
      this.$router.push({ name: 'home' });
    }
    this.shippingAddressBookId = this.order.shippingAddress.addressBookId;
    let book = await UserService.getAllDeliveryAddress(1, 100);
    this.addressBook = book.items;
  },
  methods: {
    skipNextDelivery() {
      if (this.isProcessing) return;
      this.isProcessing = true;
      OrderService.skipNextDelivery(this.orderId).then(() => {
        this.$toast.show('次をスキップしました');
        this.order.skipSetAt = new Date();
      }).finally(() => {
        this.isProcessing = false;
      });
    },
    cancelOrder() {
      if (this.isProcessing) return;
      this.isProcessing = true;

      OrderService.cancelOrder(this.orderId).then(() => {
        this.order.status = 'canceled';
        this.$toast.show('オーダーを停止しました');
      }).finally(() => {
        this.isProcessing = false;
      });
    },
    calNextSubscriptionPayDate() {
      if (this.order === null) return '';
      // TODO: need common function to handle status
      if (this.order.status !== 'active' && this.order.status !== 'not_started') return '停止中';
      return this.$filters.formatJpDatetime(utils.calNextPayDateOfOrder(this.order));
    },
    cancelPassNextMonthRequest() {
      if (this.isProcessing) return;
      this.isProcessing = true;

      OrderService.cancelSkip(this.orderId).then(() => {
        this.order.skipSetAt = undefined;
        this.$toast.show('スキップを取り消しました');
      }).finally(() => {
        this.isProcessing = false;
      });
    },
    reOpenOrder() {
      if (this.isProcessing) return;
      this.isProcessing = true;

      OrderService.reOpenOrder(this.orderId).then((res) => {
        this.order.status = 'active';
        this.order = res;
      }).finally(() => {
        this.isProcessing = false;
      });
    },
    // async getStripePaymentMethodOfSubscription(subscriptionId) {
    //   let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    //   console.log(stripe);
    //   const subscription = await stripe.subscriptions.retrieve(subscriptionId);
    //   return subscription.default_payment_method;
    // },
    async save() {
      if (this.isProcessing) return;
      this.isProcessing = true;

      try {
        // let hasUpdate = false;
        let shippingAddressBookId = await this.$refs.shippingAddress.getShippingAddress();
        if (shippingAddressBookId !== this.shippingAddressBookId) {
          await OrderService.updateShippingAddr(this.order.id, shippingAddressBookId);
          // hasUpdate = true;
        }

        let selectedPaymentMethod = await this.$refs.paymentMethod.getSelectedPaymentMethod();
        if (selectedPaymentMethod !== this.order.paymentMethodId) {
          await OrderService.updatePaymentMethod(this.order.id, selectedPaymentMethod);
          // hasUpdate = true;
        }

        // if (hasUpdate)
        this.$toast.show('保存しました');
      } finally {
        this.isProcessing = false;
      }
    },
  },
};
</script>
