import axios from 'axios';
import { ShipmentAdapter } from './adapter';

function getMyShipment(startDate, endDate, searchKeyword, page, size) {
  let from = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
  let to = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
  return axios.get('shipment/', {
    params: {
      paid_date_from: from, paid_date_to: to, page, size, q: searchKeyword,
    },
  }).then((res) => ({
    items: res.data.items.map((i) => ShipmentAdapter.convertFromRes(i)),
    totalPage: Math.ceil(res.data.total / size),
  }));
}

export const ShipmentService = {
  getMyShipment,
};
