<template>
  <div class="container px-8 mx-auto mt-8 mb-16" v-if="newsList.length>0">
      <h3 class="text-center text-4xl lg:text-5xl mb-4">News</h3>
      <p class="text-center text-base lg:mb-10 mb-5">Weeatからのお知らせ・新着情報です</p>
      <div class="w-full mb-10 grid lg:grid-cols-3 grid-cols-2 gap-4 md:gap-10 2xl:gap-12">
        <news-item v-for="news in newsList.slice(0, 3)" :key="news.id" :news="news">
        </news-item>
      </div>

      <div v-if="newsList.length > 3" class="text-center w-28 mx-auto">
        <router-link :to="{ name: 'news-list' }">
          <g-button>
            もっと見る
          </g-button>
        </router-link>
      </div>
    </div>
</template>

<script>
import NewsItem from '../common/NewsItem.vue';

export default {
  components: { NewsItem },
  props: {
    newsList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>

</style>
