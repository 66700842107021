<template>
  <div>
    <div class="shop-banner relative">
      <img :src='isMobile ? shop.setting.mobileBanner : shop.setting.pcBanner'
        alt="" class="mx-auto mb-3" />
      <div class="w-full h-full absolute bottom-0">
          <div class="lg:container mx-auto px-3 h-full flex items-center">
              <h1 class="md:text-4xl text-2xl w-full overme ml-auto mr-0
              md:mt-16 mt-6 text-right text-white">{{shop.name}}</h1>
          </div>
      </div>
      <div class="w-full absolute bottom-3 right-0">
          <div class="lg:container mx-auto px-3 shop-info text-right">
              <ul class="inline-block sns-button">
                  <li class="inline-block" v-if="shop.setting.facebookLink">
                      <a :href="shop.setting.facebookLink" target="_blank">
                          <img src="@/assets/svg_icon/blue_facebook.svg" alt="" class="mx-2 w-10"/>
                      </a>
                  </li>
                  <li class="inline-block" v-if="shop.setting.tiktokLink">
                      <a :href="shop.setting.tiktokLink" target="_blank">
                          <img src="@/assets/svg_icon/tiktok_color.svg" alt="" class="mx-2 w-10" />
                      </a>
                  </li>
                  <li class="inline-block" v-if="shop.setting.instagramLink">
                      <a :href="shop.setting.instagramLink" target="_blank">
                        <img src="@/assets/svg_icon/instagram_color.svg" alt="" class="mx-2 w-10"/>
                      </a>
                  </li>
                  <li class="inline-block" v-if="shop.setting.twitterLink">
                      <a :href="shop.setting.twitterLink" target="_blank">
                          <img src="@/assets/svg_icon/twitter_color.svg" alt="" class="mx-2 w-10" />
                      </a>
                  </li>
                  <li class="inline-block" v-if="shop.setting.linkedinLink">
                      <a :href="shop.setting.linkedinLink">
                          <img src="@/assets/svg_icon/LinkedIn_icon_circle.svg"
                            alt="" class="mx-2 w-10" />
                      </a>
                  </li>
                  <li class="inline-block" v-if="shop.setting.website">
                      <a :href="shop.setting.website">
                          <img src="@/assets/svg_icon/www.png"
                            alt="" class="mx-2 w-10" />
                      </a>
                  </li>
              </ul>
          </div>
      </div>
    </div>

    <div class="container py-14 mx-auto text-3xl line-clamp-2">{{'「'+shop.name+'」店の商品'}}</div>

    <div class="container mx-auto md:px-3 px-5">
        <template v-if="products.length > 0">
          <div class="row">
            <div
              class="
                w-full
                grid
                lg:grid-cols-3
                grid-cols-2 wrap_ProductShop"
            >
              <ProductCard class="ProductShop"
                v-for="(product, index) in products"
                :key="index"
                :product="product"
              />
            </div>
          </div>
          <div class="row">
            <paginate v-model="page" :totalPage="totalPage"></paginate>
          </div>
        </template>
        <div v-else class="w-full text-center text-xl  md:mb-24 mb-10">商品がありません</div>
      </div>
  </div>
</template>

<script>
import { systemSetting } from '@/helpers';
import ProductCard from '@/components/ProductCard.vue';
import { Shop } from '@/services/adapter';
import { ShopService, ProductService } from '@/services';
import Paginate from '@/components/common/Paginate.vue';

const PRODUCT_PER_PAGE = process.env.VUE_APP_ITEM_PER_PAGE;

export default {
  name: 'ShopProducts',
  components: {
    ProductCard,
    Paginate,
  },
  data: () => ({
    shop: new Shop(),
    products: [],
    page: 1,
    totalPage: 1,
  }),
  computed: {
    ...systemSetting,
  },
  watch: {
    page() {
      this.getProductInCurrentPage();
    },
  },
  mounted() {
    ShopService.getShopDetail(this.$route.params.id).then((res) => {
      this.shop = res;
    }).catch(() => {
      this.$router.push({ name: 'home' });
    });
    this.getProductInCurrentPage();
  },
  methods: {
    getProductInCurrentPage() {
      ProductService.getProductOfShop(this.$route.params.id,
        this.page, PRODUCT_PER_PAGE).then((res) => {
        this.products = res.items;
        this.totalPage = res.totalPage;
      });
    },

  },
};
</script>
