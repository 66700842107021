function stripHtml(html) {
  let el = document.createElement('div');
  el.innerHTML = html;
  let text = el.textContent;
  return text.length > 50 ? `${text.slice(0, 50)}...` : text;
}
export class News {
  constructor(id, title, content, thumb, published) {
    this.id = id;
    this.title = title;
    this.content = content;
    this.briefText = stripHtml(content);
    this.thumb = thumb;
    this.publishedAt = published;
  }
}

function convertFromResponse(obj) {
  return new News(obj.id, obj.title, obj.content, obj.thumb_img, obj.published_at);
}

export const NewsAdapter = {
  convertFromResponse,
};
