export class Bank {
  constructor(id, bankName, branchName, accountType, accountNumber, accountOwnerName) {
    this.id = id;
    this.bankName = bankName;
    this.branchName = branchName;
    this.accountType = accountType;
    this.accountNumber = accountNumber;
    this.accountOwnerName = accountOwnerName;
  }
}

function convertFromResponse(obj) {
  return new Bank(
    obj.id,
    obj.bank_name,
    obj.branch_name,
    obj.account_type,
    obj.account_number,
    obj.account_owner_name,
  );
}

export const BankInfoAdapter = {
  convertFromResponse,
};
