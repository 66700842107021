<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data: () => ({
    inputs: new Map(),
  }),
  methods: {
    register(input) {
      this.inputs.set(input.$.uid, input);
    },
    unregister(input) {
      delete this.inputs.delete(input.$.uid);
    },
    validate() {
      // eslint-disable-next-line no-restricted-syntax
      for (let input of this.inputs.values()) {
        input.validate();
      }
    },
    clearErrors() {
      // eslint-disable-next-line no-restricted-syntax
      for (let input of this.inputs.values()) {
        input.clearErrors();
      }
    },
    isValid() {
      this.validate();
      // eslint-disable-next-line no-restricted-syntax
      for (let input of this.inputs.values()) {
        if (input.errors.length > 0) {
          this.$toast.error('入力エラーがあります、確認してください');
          return false;
        }
      }
      return true;
    },
  },
  provide() {
    return {
      register: this.register,
      unregister: this.unregister,
    };
  },
};
</script>

<style>
</style>
