<template>
  <div class="container md:px-3 px-5 mx-auto md:mt-12 lg:mt-28 mt-5 mb-16">
    <h2 class="text-3xl lg:text-5xl text-center md:mb-8 mb-3">About  WEEAT</h2>

    <div class="w-3/4 mx-auto lg:mb-16 mb-8 mb-8 text-center">
      <p class="">まだ知らない美味しいものに出会える、サブスクリプショングルメサービス</p>
      <p class="my-2">ショップ厳選のツキイチお楽しみボックスをお届けします</p>
      <p>ちょっと嬉しくなるような美味しいものに出会える瞬間を</p>
    </div>

    <div class="md:w-3/4 m-auto grid grid-cols-1 gap-8 lg:grid-cols-3">
      <div class="flex flex-col items-center">
        <img
          :src="require(`../../assets/images/about-weeat-encounter.png`)"
          alt=""
          class="md:w-36 md:h-36 w-24 h-24"
        />
        <h4 class="text-3xl my-3">出会い</h4>
        <p class="text-center md:w-full w-2/3 mx-auto">
          今まで知らなかった美味しいもの と出会えるサービス
        </p>
      </div>
      <div class="flex flex-col items-center">
        <img
          :src="require(`../../assets/images/about-weeat-reward.png`)"
          alt=""
          class="md:w-36 md:h-36 w-24 h-24"
        />
        <h4 class="text-3xl my-3">ご褒美感</h4>
        <p class="text-center md:w-full w-2/3 mx-auto">
          自分ではあまり買わないちょっと嬉しい気分になるものをお届け
        </p>
      </div>
      <div class="flex flex-col items-center">
        <img
          :src="require(`../../assets/images/about-weeat-pleasure.png`)"
          alt=""
          class="md:w-36 md:h-36 w-24 h-24"
        />
        <h4 class="text-3xl my-3">お楽しみ</h4>
        <p class="text-center md:w-full w-2/3 mx-auto">
          毎月何が届くかわからない箱を開けるお楽しみ感が
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AboutWeeat',
};
</script>
