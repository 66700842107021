import { createStore } from 'vuex';
import { Cart } from '@/store/cart';
import { SystemSetting } from '@/store/global';
import { UserSession } from '@/store/usersession';

const store = createStore({
  modules: {
    cart: Cart,
    setting: SystemSetting,
    user: UserSession,
  },
});

export default store;
