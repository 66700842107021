<template>
  <div class="w-full h-full compensation-top-nav">
    <div class="lg:container mx-auto px-3 p-static">
      <h1 class="md:text-4xl text-2xl md:mb-16 mb-6">Weeat出店者利用規約</h1>
      <article class="md:text-2xl leading-7">
        <p class="md:mb-10 mb-4">
          ▶︎ 重要な基本ルール<br/>
        ・毎月同じ品を定期購入するスタイルにはご利用いただけません。<br/>
        ・出品商品と全く違うものを送らないでください（お肉のサブスクなのに和菓子が届くなど）<br/>
        ・不良品はお店負担で返品や交換等の対応をお願いいたします。<br/>
        ・販売価格が商品の定価＋送料を１０％以上下回らないようご注意ください。（なるべく利用者がお得になるよう設計ください）
        </p>
        <hr class="lg:my-10 my-4"/>
        <p class="mb-3">「Weeat」への出店ご利用に際して以下の規約をご確認ください。<br/>
      本サービスを利用するためには、合同会社Lelien（以下、「当社」といいます。）が定める利用規約の全てに同意していただく必要があり、
      本サービスを利用したときは、本利用規約に同意したものとみなされるものとします。
      </p>
      <p class="my-8">第1条 出店申込</p>
      <div class="pl-14 relative">
        <span class="list absolute left-0">1項</span>
         <p class="mb-3"> 出店を希望する者（以下、「出店希望者」といいます。）は、本人及び代表者等責任を有する者がオンラインにより本サービス所定の方法で申し込むものとします。
           出店希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。</p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">4項</span>
         <p class="mb-3">
          出店希望者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
          なお、出店員希望者が18歳未満の未成年である場合には、いかなる場合でも登録を認めないものとします。</p>
        <div class="pl-5">
          <ol class="list-decimal">
            <li>
              本利用規約に違反するおそれがあると当社が判断した場合
            </li>
            <li>
              当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
            </li>
            <li>
              過去に本サービスの利用の登録を取り消された者である場合
            </li>
            <li>
              反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）との何らかの関与を行っていると当社が判断した場合
            </li>
            <li>
              その他、登録を適当でないと判断した場合
            </li>
          </ol>
        </div>
      </div>

      <p class="my-8">第2条 メールアドレス及びパスワードの管理</p>
      <div class="pl-14 relative">
        <span class="list absolute left-0">1項</span>
        <p class="mb-4">出店店舗は、本サービス上で設定するメールアドレス、パスワード等の管理を行う責任を負うものとします。</p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">2項</span>
        <p class="mb-4">メールアドレス及びパスワード等を第三者に利用させ、又は譲渡若しくは担保設定その他の処分をすることはできません
        </p>
      </div>
        <div class="pl-14 relative">
        <span class="list absolute left-0">3項</span>
        <p class="mb-4">メールアドレス及びパスワード等の管理不十分、使用上の過誤、
          第三者の使用等による損害の責任は出店店舗が負うものとし、当社は一切の責任を負わないものとします。
        </p>
      </div>
        <div class="pl-14 relative">
        <span class="list absolute left-0">4項</span>
        <p class="mb-4">会員は、メールアドレス又はパスワード等が第三者に漏れた場合、あるいはメールアドレス又はパスワード等が第三者に使用されている疑いのある場合には、
          直ちに当社にその旨を連絡するとともに、当社の指示がある場合にはこれに従うにものとします。この場合、
          当社はそのメールアドレスやパスワード等を不正アカウントとして停止することができるものとし、当社は、かかる停止に基づき会員に生じた損害について一切の責任を負いません。
        </p>
      </div>

      <p class="my-8">第3条 販売について</p>
      <div class="pl-14 relative">
        <span class="list absolute left-0">1項</span>
        <p class="mb-4">出店店舗は、商品のお問い合わせ等があった場合には、取引に必要な情報について回答しなければなりません。</p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">2項</span>
        <p class="mb-4">出店店舗は、商品の注文があった場合には、当該注文者（以下、「顧客」といいます。）
          へ所定の日程に指定された宛先へ遅延なく商品商品の送付を行うものとし、当社は一切の責任を負わないものとします。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">3項</span>
        <p class="mb-4">出店店舗は、顧客に対し、取引の当事者は出店店舗と顧客であり、
          販売等に伴う権利・義務は出店店舗と当該顧客との間で発生することを明確に表示するものとします。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">4項</span>
        <p class="mb-4">出店店舗は、販売を行うにあたり、特定商取引に関する法律、不当景品類及び不当表示防止法、
          個人情報の保護に関する法律、資金決済に関する法律、消費者契約法、犯罪による収益の移転防止に関する法律その他関係法令を遵守しなければなりません。 出店店舗は、住所、担当者の氏名、
          連絡が取れる電話番号その他特定商取引に関する法律で要求される事項及び当社が要求する事項を、当社の指示に従って表示しなければならないものとします。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">5項</span>
        <p class="mb-4">出店店舗は、顧客との間で商品の販売に関する契約(以下、「販売契約」といいます。)が成立した場合、販売契約を履行する義務を負うものとし、
          販売契約成立後に利用契約が終了した場合でも、販売契約を履行する義務を免れないものとします。
        </p>
      </div>
       <div class="pl-14 relative">
        <span class="list absolute left-0">6項</span>
        <p class="mb-4">出店店舗は、顧客との間で、商品の不着、到着遅延、瑕疵その他に関する紛争が生じた場合、又は第三者との間で著作権、
          商標権等の知的財産権若しくは人格権等に関する紛争が生じた場合には、
          すべて出店店舗の責任と負担において顧客が不利にならないように適切に解決するものとします。
          また、当社が顧客その他の第三者に損害賠償等の支払を余儀なくされた場合には、出店店舗はその全額を当社に支払うとともに、
          その解決のために要した弁護士費用その他一切の諸経費を当社に支払うものとします。
        </p>
      </div>

      <p class="my-8">第4条 決済手段の提供</p>
      <div class="pl-14 relative">
        <span class="list absolute left-0">1項</span>
        <p class="mb-4">出店店舗は、本サービスを利用して販売した商品の代金の決済に関し、当社と提携するStripeの決済手段を利用いただきます。
          ただし、商品が注文されてから一定期間発送がない場合、出店店舗が本利用規約に違反して商品を販売したと合理的に判断できる場合、
          販売契約が解除、取消し、無効等の理由により効力を失うことが合理的に予想される場合、当該販売契約に関してチャージバックその他の決済事業者等
          （第8条第1項に定義されます。以下同じ。）
          が定める当社に対する支払いの拒絶又は返還事由の発生が合理的に予想される場合その他決済手段を利用させることが適切でないと当社が合理的に判断した場合には、
          決済手段の全部または一部を利用させないことができるものとします。
          当社は、本項に定める措置により出店店舗に生じた損害に関し、当社に故意又は重大な過失がない限り、責任を負いません。 </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">2項</span>
        <p class="mb-4">出店店舗が前項の決済手段を利用する場合において、商品の発送日の翌日から7日の間に顧客その他の第三者からクレームがなされなかった場合に限り決済が確定し、
          当社より当月末に当月の売上代金を指定の口座に支払うものとします。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">3項</span>
        <p class="mb-4">当社から出店店舗に対しての支払いを行う場合、当社は、出店店舗から指定があり、かつ、会員に対する支払を行う口座として適切であると当社が認めた口座
          （以下、「指定口座」といいます。）に対して支払いを行うものとします。会員は、自らの名義以外の口座を指定口座として指定することはできないものとします。
          指定口座の適切性に疑義があると当社が判断した場合、
          当社は出店店舗に対する支払いを留保することができるものとし、これにより出店店舗に発生した損害について当社は一切の責任を負わないものとします。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">4項</span>
        <p class="mb-4">出店店舗が第1項に定める決済手段を利用する場合、会員は、販売に関する一切の資料、証跡その他当社が指定したものを5年間保管したうえで、
          決済事業者等（決済事業者等から当社が要請を受けた場合には、当社を含む。以下本項において同じ。）から求められた資料等を提出する等、決済事業者等及び当社の指示に従って、
          決済事業者等による調査に協力するとともに、当該決済事業者等から業務の是正要求があった場合にはこれに応じるものとします。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">5項</span>
        <p class="mb-4">当社から指定口座に対して行う支払いに係る振込手数料その他の費用は、会員が負担するものとします。
        </p>
      </div>
       <div class="pl-14 relative">
        <span class="list absolute left-0">6項</span>
        <p class="mb-4">当社から指定口座に対して支払いを行うことにより、当社の出店店舗に対する支払債務は消滅するものとし、
          出店店舗が指定口座を誤って指定した場合でも同様とします。出店店舗が指定口座を誤って指定したことにより出店店舗に発生した損害について当社は一切の責任を負わず、
          出店店舗が指定口座を誤って指定したことにより発生する組戻手数料その他一切の費用は、出店店舗が負担するものとします。
        </p>
      </div>

      <p class="my-8">第5条 本サービス利用手数料</p>
      <div class="pl-14 relative">
        <span class="list absolute left-0">1項</span>
        <p class="mb-4">会員は、本サービスの利用の対価として、販売手数料と紹介報酬手数料（以下「本サービス利用手数料」といいます。）を支払うものとします。</p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">2項</span>
        <p class="mb-4">当社は、前項の本サービス利用手数料を売上代金から差し引きすることにより、出店店舗から手数料を受け取ります。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">3項</span>
        <p class="mb-4">本サービス利用手数料には、各決済の手数料も含むものとし、別途決済手数料はかかりません。</p>
      </div>

      <p class="my-8">第6条 届出事項の変更等</p>
        <div class="pl-14 relative">
        <span class="list absolute left-0">1項</span>
        <p class="mb-4">出店店舗は、出店申込の際に当社に届け出た事項に変更のあった場合は、遅滞なくメールにて届け出るものとします。</p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">2項</span>
        <p class="mb-4">当社からの出店店舗に対する通知は、出店店舗が当社に申請した連絡先に発信することにより、出店店舗に通常到達すべきときに到達したとみなされるものとします。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">3項</span>
        <p class="mb-4">出店店舗は、当社から要求された場合には、直ちに出店店舗自ら
          （出店店舗が法人である場合には代表取締役等当社が指定する者）の身分を証明することができる書類を当社の指示に従って当社に提供しなければならないものとします。</p>
      </div>

      <p class="my-8">第7条 退会</p>
        <div class="pl-14 relative">
        <span class="list absolute left-0">1項</span>
        <p class="mb-4">出店店舗は、サブスクリプションの性質上、お申し出より１ヶ月後以降に退店することができます。 </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">2項</span>
        <p class="mb-4">出店店舗の主たる運営者が死亡した場合その他本人の会員資格の利用が不可能となる事由があったときは、当社は、
          当該会員がその時点で退会したものとみなし、メールアドレス及びパスワードを利用できなくするものとします。
          但し、退会時において未済の取引がある場合は、出店店舗は速やかにこれを完了させるものとし、その完了後に退会が認められるものとします。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">3項</span>
        <p class="mb-4">本利用規約に別段の定めがある場合を除き、退会の時点で当社が出店店舗に支払うこととなっていた金銭等については、
          当社所定の手続を行うことにより、退会後1ヶ月間に限り支払いを受けることができるものとし、
          かかる期間が経過した後は、当社は自らの裁量により、違約金として没収又は顧客に返金することができるものとします。</p>
      </div>

      <p class="my-8">第8条 出店店舗資格の停止、除名</p>
      <div class="pl-14 relative">
        <span class="list absolute left-0">1項</span>
        <p class="mb-4"> 当社は、以下の事由がある場合、会員に何ら事前の通知又は催告をすることなく、出店店舗資格を一時停止し、又は除名することができるものとします。</p>

        <div class="pl-5">
          <ol class="list-decimal">
            <li>
              出店店舗がメールアドレス、パスワード又は本サービスを不正に使用し又は使用させた場合
            </li>
            <li>
              登録情報に虚偽の事実があることが判明した場合
            </li>
            <li>
             当社、他の出店店舗その他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
            </li>
            <li>
              手段の如何を問わず、本サービスの運営を妨害した場合
            </li>
            <li>
              自ら振出し、若しくは引受けた手形又は小切手につき、不渡りの処分を受けた場合
            </li>
            <li>
              租税公課の滞納処分を受けた場合
            </li>
            <li>
              後見開始、保佐開始若しくは補助開始の審判を受けた場合
            </li>
            <li>
              1ヶ月以上本サービスの利用がなく、当社からの連絡に対して応答がない場合
            </li>
            <li>
              出店店舗に対し、差押、仮差押、仮処分、強制執行、破産、民事再生、会社更生、特別清算の申し立てがなされた場合、
              又は、出店店舗が自ら破産、民事再生、会社更生、特別清算の申し立てをした場合
            </li>
            <li>
             出店店舗が本利用規約及びガイドラインのいずれか又は双方の条項に違反した場合
            </li>
            <li>その他、出店店舗として不適格と当社が判断した場合
            </li>
          </ol>
        </div>
      </div>

      <div class="pl-14 relative">
        <span class="list absolute left-0">2項</span>
        <p class="mb-4">前項各号のいずれかの事由に該当した場合、出店店舗は、当社に対して負っている債務の一切について当然に期限の利益を失い、
          直ちに当社に対して全ての債務の支払を行わなければなりません。</p>
      </div>
       <div class="pl-14 relative">
        <span class="list absolute left-0">3項</span>
        <p class="mb-4">当社は、本条に基づき当社が行った行為により出店店舗に生じた損害について一切の責任を負いません。 </p>
      </div>
       <div class="pl-14 relative">
        <span class="list absolute left-0">4項</span>
        <p class="mb-4">本条の措置の時点で当社が会員に支払うこととなっていた金銭等については、当社は自らの裁量により、
          違約金として没収又は顧客に返金することができるものとします。</p>
      </div>
       <div class="pl-14 relative">
        <span class="list absolute left-0">5項</span>
        <p class="mb-4"> 本条に基づき会員の登録が取り消された場合、出店店舗は当社の指示に基づき、当社から提供を受けた本サービスに関連するソフトウェア、
          マニュアルその他の物につき、返還、廃棄その他の処分を行うものとします。</p>
      </div>

      <p class="my-8">第9条 本サービスの変更及び廃止</p>
      <div class="mb-10">当社は、いつでも本サービスの内容を事前の告知なく、変更又は廃止することができるものとします。
          当社は、変更又は廃止により出店店舗に生じた損害には一切責任を負いません。

      </div>

      <p class="my-8">第10条 本サービスの停止</p>
      <div class="mb-10">当社は、次の各号の事由が生じた場合には、出店店舗に事前に通知することなく本サービスの一部又は全部を停止することができるものとし、
        当該停止により出店店舗に生じた損害には一切責任を負いません。
      </div>
      <div class="pl-14">
          <ol class="list-decimal">
            <li>
              本サービスの提供のための装置、システムの保守又は点検を行う場合
            </li>
            <li>
              コンピュータ、通信回線等が事故により停止した場合
            </li>
            <li>
             火災、停電、地震、天災、システム障害等により、本サービスの運営が困難な場合
            </li>
            <li>
              その他、当社が停止を必要と判断した場合
            </li>
          </ol>
      </div>

      <p class="my-8">第11条 情報の保存</p>
      <div class="mb-10">出店店舗は、本サービスを利用するに当たり、自らの責任で自らの商品の販売に必要な情報についてのバックアップをとらなければなりません。
        当社は、当該情報が消滅した場合でも、一切の責任を負わないものとします。
      </div>

      <p class="my-8">第12条 禁止行為</p>
      <div class="mb-10">出店店舗は、本サービスの利用において、以下の各号のいずれかに該当する行為又はそのおそれがある行為をしてはならないものとします。</div>
      <div class="pl-14">
          <ol class="list-decimal">
            <li>
              本サービス上であるか否かを問わず、他の本サービスの利用者その他の第三者（決済事業者等を含みます。以下本条において同じ。）
              又は本サービスの知的財産権（著作権、意匠権、特許権、実用新案権、商標権、工業所有権等）
              及びその他の権利を侵害する行為（偽ブランド品の販売を含みます。）、また侵害する恐れのある行為
            </li>
            <li>
              本サービス上であるか否かを問わず、他の本サービスの利用者その他の第三者又は当社の財産、信用、プライバシーを侵害する行為、又は侵害する恐れのある行為
            </li>
            <li>
             本サービス上であるか否かを問わず、他の本サービスの利用者その他の第三者又は当社に不利益を与える行為、又はその恐れのある行為
            </li>
            <li>
              本サービス上であるか否かを問わず、他の本サービスの利用者、当社が本サービスの運営を委託した者その他の第三者又は当社を誹謗中傷する行為、又は不快感を抱かせる行為
            </li>
            <li>他の本サービス利用者その他の第三者の個人情報の売買又は譲受にあたる行為、又はそれらの恐れのある行為 </li>
            <li>公序良俗に反する行為（盗品、わいせつな商品、脱法ドラッグその他人体、健康に影響を及ぼす商品の取引を含みます。）、その恐れのある行為、又はそれを助長する行為 </li>
            <li>公序良俗に反する情報を他の本サービス利用者その他の第三者に提供する行為 </li>
            <li>猥褻な情報及び未成年者にとって有害と認められる情報の売買、譲受又は掲載する行為、それらを助長する行為 </li>
            <li>資金洗浄、マネーロンダリング、その他犯罪による収益の移転防止に関する法律に違反する行為 </li>
            <li>特定商取引に関する法律、銃砲刀剣類所持等取締法、麻薬及び向精神薬取締法、絶滅のおそれのある野生動植物の種の国際取引に関する条約その他法令に違反する行為や犯罪的行為、
              若しくはその恐れのある行為、あるいはそれを幇助する行為 </li>
            <li>本サービス及びその他当社が提供するサービスの運営を妨げる行為、又は当社の信用・名誉等を毀損する行為 </li>
            <li>コンピュータウィルス等有害なプログラムを、本サービスを通じて又は本サービスに関連して使用、又は提供する行為 </li>
            <li>他人になりすまして情報を送信、受信又は表示する行為</li>
            <li>本サービスのE-メールサービスを利用して無差別又は大量に不特定多数の者に対してその意思に反しE-メール等を送信する行為、
              又は事前に承認していない送信先に対するE-メール配信行為 </li>
            <li>IPアドレス、アカウント、パスワード、E-メールアドレス、及びドメイン名を不正に使用する行為</li>
            <li>本サービスを当社の許可なく第三者に利用させる行為</li>
            <li>インターネット上で、他の本サービス利用者その他の第三者又は当社が入力した情報を不正に改ざんする行為</li>
            <li>サーバその他本サービスのコンピュータに不正にアクセスする行為</li>
            <li>本サービスにおいて、事実に反する、又はその恐れのある情報を提供する行為</li>
            <li>日本通信販売協会が定める広告に関する自主基準に違反する行為</li>
            <li>選挙期間中であるか否かを問わず、選挙運動又はこれに類似する行為</li>
            <li>本サービスと同種、又は類似の業務を行う行為</li>
            <li>本サービスにおいて登録禁止商品を販売する行為</li>
            <li>本サービスに関連して、特定商取引に関する法律上の訪問販売、電話勧誘販売、連鎖販売取引、特定継続的役務提供、業務提供誘引販売取引を行う行為</li>
            <li>営業目的を有し、又は営業として本サービスを利用する行為</li>
            <li>顧客又はその関係者が商品等を換金すること、又はその目的があることを知っていながら行う行為</li>
            <li>顧客との間に紛議が発生するおそれ、クレジットカード等の不正利用が発生するおそれがあると当社が判断する行為</li>
            <li>実態のない取引を行う行為</li>
            <li>資金移動を目的とする行為（貸金取引を含みます。）</li>
            <li>商品券、プリペイドカード、印紙、切手、回数券その他有価証券の売買等（電子マネー又はプリペイドカードのチャージ等を含みます。）を行う行為</li>
            <li>許認可等が必要となる取引のうち、許認可を取得したうえで、当社にこれを証明する関連証書類を提出していない取引を行う行為 その他当社が不適当と判断する行為</li>
        </ol>
      </div>

      <p class="my-8">第13条 本サービスの責任の範囲、免責事項</p>
      <div class="pl-14 relative">
        <span class="list absolute left-0">1項</span>
        <p class="mb-4">当社は、出店店舗が本サービスを利用する際に、コンピュータウィルスなど有害なプログラム等による損害を受けないことを保証しないものとします。</p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">2項</span>
        <p class="mb-4">当社は、出店店舗が本サービスを利用する際に使用するいかなる機器、ソフトウェアについても、その動作保証を一切しないものとします。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">3項</span>
        <p class="mb-4">当社は、出店店舗が本サービスを利用する際に発生する通信費用について、一切負担しないものとします。

        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">4項</span>
        <p class="mb-4">当社は、出店店舗が本サービスを利用することにより一定の売上を得ることができることを保証するものではありません。

        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">5項</span>
        <p class="mb-4">出店店舗は、本サービスを利用することが、出店店舗に適用のある法令（特定商取引に関する法律を含みますが、これに限りません。）、
          業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、出店店舗による本サービスの利用が、
          出店店舗に適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。

        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">6項</span>
        <p class="mb-4">当社は、いかなる場合でも、出店店舗の逸失利益、間接損害、特別損害、拡大損害、
          弁護士費用その他の通常かつ直接の損害以外の損害を賠償しないものとします。</p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">7項</span>
        <p class="mb-4">出店店舗との間、若しくは出店店舗と第三者（顧客を含みますがこれに限られません。）との間で生じたトラブルに関しては、
          出店店舗の責任において処理及び解決するものとし、当社はかかる事項について一切責任を負わないこととします。ただし、当社に故意又は重大な過失がある場合はこの限りではありません。

        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">8項</span>
        <p class="mb-4">当社は、本サービスの一時停止・サービス変更・中止に伴い出店店舗及び第三者が被った不利益・損害に関して一切責任を負わないこととします。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">9項</span>
        <p class="mb-4">当社は、本サービスの利用によって出店店舗若しくは第三者が被った不利益・損害に関して一切責任を負わないこととします。
          ただし、当社に故意又は重大な過失がある場合はこの限りではありません。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">10項</span>
        <p class="mb-4">出店店舗は、本サービスを利用するに当たっては、自己の責任において当社がホームページ等において提示している利用方法を確認するものとし、
          出店店舗の操作ミスについて当社は一切の責任を負わないものとします。
        </p>
      </div>

      <p class="my-8">第14条 紛争処理及び損害賠償</p>
      <div class="pl-14 relative">
        <span class="list absolute left-0">1項</span>
        <p class="mb-4">出店店舗は、本利用規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。</p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">2項</span>
        <p class="mb-4">出店店舗が、本サービスに関連して他の会員その他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、
          直ちにその内容を当社に通知するとともに、出店店舗の費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">3項</span>
        <p class="mb-4">出店店舗による本サービスの利用に関連して、当社が、他の会員その他の第三者から権利侵害その他の理由により何らかの請求を受けた場合は、
          出店店舗は当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。
        </p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">4項</span>
        <p class="mb-4">当社は、本サービスに関連して出店店舗が被った損害について、一切賠償の責任を負いません。なお、消費者契約法の適用その他の理由により、
          本項その他当社の損害賠償責任を免責する規定にかかわらず当社が出店店舗に対して損害賠償責任を負う場合においても、当社の賠償責任は、
          10,000円を上限とします。ただし、当社に故意又は重大な過失がある場合はこの限りではありません。
        </p>
      </div>
      <!-- <div class="pl-14 relative">
        <span class="list absolute left-0">5項</span>
        <p class="mb-4">当社から指定口座に対して行う支払いに係る振込手数料その他の費用は、会員が負担するものとします。
        </p>
      </div> -->

      <p class="my-8">第22条 有効期間</p>
      <div class="mb-10">利用契約は、会員について第2条に基づく登録が完了した日に効力を生じ、当該会員が退会した日、
        当該会員が除名された日又は本サービスの提供が終了した日のいずれか早い日まで、当社と会員との間で有効に存続するものとします。
      </div>
      <p class="my-8">第23条 契約終了後の措置</p>
       <div class="mb-10">会員は、利用契約が終了した場合には、本サービス及び本サービス上で提供されたものを利用することができなくなるものとし、
         これにより会員が損害を被った場合でも、当社は一切の責任を負わないものとします。
      </div>
      <p class="my-8">第24条 ガイドライン</p>
      <div class="mb-10">会員は、本利用規約の他に、ガイドラインが適用されることに合意したものとします。</div>
      <p class="my-8">第29条 本利用規約の変更</p>
      <div class="pl-14 relative">
        <span class="list absolute left-0">1項</span>
        <p class="mb-4">当社は、本利用規約を、会員の事前の承諾なく変更することができるものとします。</p>
      </div>
      <div class="pl-14 relative">
        <span class="list absolute left-0">2項</span>
        <p class="mb-4">本利用規約の変更について、当社がホームページ等で本利用規約を変更する旨及び変更内容並びにその効力発生時期を公表し、
          又はこれらを会員に通知した後、当該効力発生時期が到来したときに、会員は当該変更内容を承認したものとみなされます。

        </p>
      </div>
      <p class="my-8">第30条 反社会的勢力でないことの表明・確約</p>
       <div class="pl-5">
          <ol class="list-decimal">
            <li>
              出店希望者及び出店店舗 （個人・法人・団体を含みます。以下本条において同じ。）は、現在又は将来にわたって、
              次の各号の反社会的勢力のいずれにも該当しないことを表明・確約するものとします。<br/><br/>
              (1) 暴力団<br/>
              (2) 暴力団員又は暴力団員でなくなってから５年を経過しない者<br/>
              (3) 暴力団準構成員<br/>
              (4) 暴力団関係企業<br/>
              (5) 総会屋等<br/>
              (6) 社会運動等標ぼうゴロ<br/>
              (7) 特殊知能暴力集団等<br/>
              (8) その他前各号に準ずる者及び団体

            </li>
            <li>
              出店希望者及び出店店舗は、現在又は将来にわたって、前項の反社会的勢力と次の各号のいずれかに該当する関係も有しないことを表明・確約するものとします。<br/><br/>
                (1) 反社会的勢力によって、その経営を支配されている関係<br/>
                (2) 反社会的勢力がその経営に実質的に関与している関係<br/>
                (3) 反社会的勢力を役職員や顧問としたり、紛争解決の依頼や相談をするなど、反社会的勢力を利用していると認められる関係<br/>
                (4) 反社会的勢力に対して資金等を提供し、又は便宜を供与するなどの関係<br/>
                (5) 役職員又は経営に実質的に関与している者が、反社会的勢力と社会的に非難されるべき関係

            </li>
            <li>
             出店希望者及び出店店舗は、自ら又は第三者を利用して次の各号のいずれかに該当する行為を行わないことを表明・確約するものとします。<br/><br/>
              (1) 暴力的な要求行為<br/>
              (2) 法的な責任を超えた不当な要求行為<br/>
              (3) 取引に関して、脅迫的な言動をし、又は暴力を用いる行為<br/>
              (4) 風説を流布し、偽計又は威力を用いて当社の信用を棄損し、又は当社の業務を妨害する行為<br/>
              (5) 換金を目的とする商品の販売行為<br/>
              (6) 合理的な理由なく、商品の販売を行う者が保有するカード等を使用する、本利用規約にかかる信用販売行為<br/>
              (7) その他前各号に準ずる行為

            </li>
            <li>
             出店希望者及び出店店舗は、上記各項のいずれかを満たさないと認められることが判明した場合又はこの表明・確約が虚偽の申告であることが判明した場合は、
             当社が登録の拒否若しくは出店店舗資格の一時停止又は除名を行っても異議を申し立てないものとします。これにより損害が生じた場合でも、
             一切出店希望者及び出店店舗の責任とし、当社は一切の責任を負わないものとします。
            </li>
          </ol>
        </div>

      <p class="mb-6">2021年11月20日 制定</p>

      </article>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TermsUse',
  components: {

  },
  data: () => ({}),
};
</script>
