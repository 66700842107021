<template>
    <div class="lg:container mx-auto px-3 compensation-top-nav pb-10">
        <UserTabs :activeMyReferralReward="true"/>
        <div class="bg-gray-200 lg:p-12 md:p-6 p-3">
          <div class="grid-cols-2 grid lg:gap-10 gap-4">
            <div class="col-span-2 lg:col-span-1 text-center">
              <h3 class="mb-3 text-2xl text-left">ご紹介報酬</h3>
              <div class="border border-gray-400 bg-white lg:p-6 p-3">
                <p class="text-2xl mb-3">現在の残高</p>
                <h2  class="text-4xl">{{$filters.formatCurrency(sum.balance)}}円</h2>
                <hr class="my-4"/>
                  <div class="grid-cols-2 grid lg:gap-10 gap-4">
                    <div class="col-span-1 text-right text-xl">
                      累計報酬
                    </div>
                      <div class="col-span-1 text-left text-xl">
                        {{$filters.formatCurrency(sum.cumulativeAmount)}}円
                    </div>
                 </div>
              </div>
            </div>
            <div class="col-span-2 lg:col-span-1">
              <h3 class="mb-3 text-2xl">紹介報酬受け取り口座</h3>
              <div class="border border-gray-400 bg-white lg:p-6 p-3">
                  <div class="grid-cols-3 grid lg:gap-10 gap-4 items-center">
                    <div class="col-span-2 text-left text-xl">
                      <p class="text-2xl mb-3">
                        {{bankAccount.bankName}}<br/>
                        {{bankAccount.branchName ? `${bankAccount.branchName}支店` : ''}}<br/>
                        {{bankAccount.accountNumber}}
                      </p>
                    </div>
                    <router-link class="col-span-1 text-right text-xl"
                      :to="{
                        name: 'my-info',
                      }"
                    >
                      <g-button>変 更</g-button>
                    </router-link>
                 </div>
              </div>
               <div class="col-span-1 mt-4 text-center text-xl">
                 <p class="md:hidden text-xxs mb-4 leading-loose">
                    1万円以下のお引き出しは、お振り込み手数料が差し引かれます<br>
                    紹介報酬は、振り込み依頼から１０営業日以内に指定の口座に振り込まれます
                  </p>
                  <g-button v-if="sum.balance > 10000 " @click="sendWithdrawRequest()"
                    :disabled="sum.balance <= 250"
                  >
                    報酬振り込み依頼
                  </g-button>

                  <g-button v-else @click="openSendingWithdrawRequestConfirm = true"
                    :disabled="sum.balance <= 250"
                  >
                    報酬振り込み依頼
                  </g-button>
                  <p class="text-red-600 text-base mt-2"
                    v-if="sum.balance > 0 && sum.balance <= 250">
                    手数料250円がかかります。現在の残高が足りないので、振り込むことができません。
                  </p>
                </div>
            </div>
          </div>
          <p class="md:block hidden text-center mt-6 lg:w-1/2 mx-auto leading-loose">
            1万円以下のお引き出しは、お振り込み手数料が差し引かれます<br>
            紹介報酬は、振り込み依頼から１０営業日以内に指定の口座に振り込まれます
          </p>
        </div>

        <transaction-list></transaction-list>
    </div>

    <confirm-dialog v-model="openSendingWithdrawRequestConfirm"
      @yes="sendWithdrawRequest()"
    >
      <template v-slot:main>1万円以下の振り込みの場合、手数料250円がかかります。振り込み依頼してよろしいですか？
      </template>
    </confirm-dialog>
</template>

<script>
import UserTabs from '../../components/UserPage/UserTabs.vue';
import ConfirmDialog from '@/components/common/ConfirmDialog.vue';
import { RewardSummarization, Bank } from '@/services/adapter';
import { AffiliateService, UserService } from '@/services';
import TransactionList from '@/components/MyReward/TransactionList.vue';

export default {
  name: 'MyReferralReward',
  components: {
    UserTabs,
    ConfirmDialog,
    TransactionList,
  },
  data: () => ({
    isProcessing: false,
    openSendingWithdrawRequestConfirm: false,
    sum: new RewardSummarization(),
    bankAccount: new Bank(),
  }),
  mounted() {
    UserService.getBankAcountInfo().then((res) => {
      this.bankAccount = res;
    });
    AffiliateService.getAffiliateSummarization().then((res) => {
      this.sum = res;
    });
  },
  methods: {
    sendWithdrawRequest() {
      if (!this.bankAccount.accountNumber || this.bankAccount.accountNumber.length === 0) {
        this.$toast.error('先にお振込先の設定をしてください');
        setTimeout(() => {
          this.$router.push({ name: 'my-info' });
        }, 1500);
        return;
      }
      if (this.isProcessing) return;
      this.isProcessing = true;

      AffiliateService.sendWithdrawRequest(this.sum.balance)
        .then(() => {
          this.$toast.show('送信しました');
          this.$router.go();
        })
        .catch(() => {
          this.$toast.error('失敗しました');
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

  },
};
</script>
