<template>
  <div class="lg:px-6">
    <div v-if="!isSendContact">
      <div class="">
        <p>商品に関するお問い合わせの場合は。商品のタイトルを添えてお問い合わせください。</p>
        <p>ご注文に関するお問い合わせの場合は、注文番号を記載の上お問い合わせください。</p>
      </div>

      <div class="mt-8">
        <g-form ref="form">
          <div class="mb-6">
            <InputField label="お名前" v-model="name" required/>
          </div>

          <div class="mb-6">
            <InputField label="メールアドレス" type="email" required v-model="email"/>
          </div>

          <div class="mb-6">
            <TextAreaField label="お問い合わせ内容" v-model="contactContent" required/>
          </div>
        </g-form>

        <div
          class="
            w-32
            my-10
            text-md
            mx-auto
          "
        >
          <g-button @click="sendContactForm()">送 信</g-button>
        </div>
      </div>
    </div>

    <div v-if="isSendContact" class="my-20">
      <p>お問い合わせを送信いたしました。</p>
      <p>お店から３営業費以内を目安に返信がございます。</p>
      <p>もし届かない場合は、未着の可能性がございますのでお</p>
      <p>手数ですが再度ご送信ください。</p>
    </div>
  </div>
</template>

<script>
import GForm from '../common/GForm.vue';
import TextAreaField from '../common/TextAreaField.vue';
import { ShopService } from '@/services';

export default {
  name: 'ShopContactForm',
  components: {
    TextAreaField,
    GForm,
  },
  props: {
    shopId: {
      type: String,
    },
  },
  data: () => ({
    isProcessing: false,
    isSendContact: false,
    name: '',
    email: '',
    contactContent: '',
  }),
  methods: {
    sendContactForm() {
      if (!this.$refs.form.isValid() || this.isProcessing) return;
      this.isProcessing = true;
      ShopService.sendContactToShop(this.shopId,
        { name: this.name, email: this.email, content: this.contactContent }).then(() => {
        this.isSendContact = true;
        window.scrollTo(0, 0);
      }).finally(() => {
        this.isProcessing = false;
      });
    },
  },
};
</script>
