import axios from 'axios';
import { ProductAdapter } from './adapter';

function getNewReleaseProducts() {
  return axios.get('/product/new_release/').then(
    (res) => Promise.resolve(
      res.data.map((obj) => ProductAdapter.convertListItemFromResponse(obj)),
    ),
    (err) => Promise.reject(err),
  );
}

function getDetailProduct(productId) {
  return axios.get(`/product/detail/${productId}`).then((res) => ProductAdapter.convertDetailItemFromResponse(res.data));
}

function getRecommendProducts() {
  return axios.get('/product/recommend').then(
    (res) => Promise.resolve(
      res.data.map((obj) => ProductAdapter.convertListItemFromResponse(obj)),
    ),
    (err) => Promise.reject(err),
  );
}

function getFavoriteProduct(page, size) {
  return axios.get('/product/favorite', { params: { page, size } })
    .then((res) => ({
      items: res.data.items.map((obj) => ProductAdapter.convertListItemFromResponse(obj)),
      totalPage: Math.ceil(res.data.total / size),
    }));
}

function addFavoriteProduct(productId) {
  return axios.post(`/product/favorite/${productId}`);
}

function deleteFavoriteProduct(productId) {
  return axios.delete(`/product/favorite/${productId}`);
}

function getProductOfShop(shopId, page, size) {
  return axios.get('/product/', { params: { shop_id: shopId, page, size } })
    .then((res) => ({
      items: res.data.items.map((p) => ProductAdapter.convertListItemFromResponse(p)),
      totalPage: Math.ceil(res.data.total / size),
    }));
}

function getProductOfCategory(categoryId, page, size) {
  return axios.get('/product/', { params: { category_id: categoryId, page, size } })
    .then((res) => ({
      items: res.data.items.map((p) => ProductAdapter.convertListItemFromResponse(p)),
      totalPage: Math.ceil(res.data.total / size),
    }));
}

function getProductDetailFromIds(ids) {
  return axios.post('/product', { ids }).then((res) => res.data.map((p) => ProductAdapter.convertDetailItemFromResponse(p)));
}

export const ProductService = {
  getNewReleaseProducts,
  getDetailProduct,
  getRecommendProducts,
  getFavoriteProduct,
  addFavoriteProduct,
  deleteFavoriteProduct,
  getProductOfShop,
  getProductOfCategory,
  getProductDetailFromIds,
};
