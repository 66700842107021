import axios from 'axios';
import { AffiliateAdapter } from '@/services/adapter';

function sendWithdrawRequest(amount) {
  return axios.post('/user/balance/withdraw_request/', { amount });
}

function getTransactionHistory(page, size) {
  return axios.get('/user/balance/transaction', { params: { page, size } })
    .then((res) => ({
      items: res.data.items.map((t) => AffiliateAdapter.converseTransaction(t)),
      totalPage: Math.ceil(res.data.total / size),
    }));
}

function getAffiliateSummarization() {
  return axios.get('/user/balance/')
    .then((res) => AffiliateAdapter.convertRewardSummarization(res.data));
}

export const AffiliateService = {
  sendWithdrawRequest,
  getAffiliateSummarization,
  getTransactionHistory,
};
