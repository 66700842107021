<template>
  <div class="lg:container mx-auto px-3 compensation-top-nav pb-10">
    <UserTabs :activeMyInfo="true"/>
    <g-form ref="form">
    <div class="mx-auto lg:w-2/3">
      <div class="flex mt-6 w-full">
        <div
          class="md:w-1/4 w-1/2 mr-3"
        >
          <InputField
            label="姓"
            required
            maxLength=25
            v-model="userInfo.lastName"
          />
        </div>

        <div
          class="md:w-1/4 w-1/2 ml-3"
        >
          <InputField label="名"
            required
            maxLength=25
            v-model="userInfo.firstName"
          />
        </div>
      </div>

      <div class="flex mt-6 w-full">
        <div
          class="md:w-1/4 w-1/2 mr-3"
          label="セイ"
        >
          <InputField
            label="セイ"
            required
            maxLength=25
            v-model="userInfo.lastNameKana"
            type="katakana"
          />
        </div>

        <div
          class="md:w-1/4 w-1/2 ml-3"
          label="メイ"
        >
          <InputField
            label="メイ"
            maxLength=25
            required
            v-model="userInfo.firstNameKana"
            type="katakana"
          />
        </div>
      </div>

      <div
        class="info mt-6"
        label="メールアドレス"
        id="email"
      >
        <InputField
          label="メールアドレス"
          required
          maxLength=150
          type="email"
          v-model="userInfo.email"
          disabled
        />
      </div>

      <!-- <div
        class="info mt-6"
        label="パスワード"
        id="password"
      >
        <InputField required
          label="パスワード"
          type="password"
          v-model="userInfo.password"
        />
      </div> -->

      <div
        class="info mt-6"
      >
        <InputField
          label="電話番号"
          required
          charSet="number"
          v-model="userInfo.phoneNumber"
        />
      </div>

      <div id="postal-code" class="mt-6">
        <label
          class="
            mb-2 block text-opacity-70
          "
          >
            <span>郵便番号</span>
            <span class="text-red-600 text-xs">*必須</span>
        </label>

        <div
          class="
            flex
            sm:w-1/3
            w-1/2
          "
        >
          <div class="lg:w-20 w-28">
            <InputField required charSet="number"
              v-model="userInfo.cityCode"
              size=3
            />
          </div>

          <div class="">
            <div class="w-5 mx-2 bg-gray-400 mt-5" style="height:2px"></div>
          </div>

          <div class="w-32 lg:w-28">
            <InputField required charSet="number"
              v-model="userInfo.districtCode"
              size=4
            />
          </div>
        </div>
      </div>

      <div class="flex mb-5 mt-6">
        <div
          class="lg:w-32 md:w-1/6  mr-3"
        >
          <InputField
            required
            label="都道府県"
            maxLength=50
            v-model="userInfo.prefecture"
          />
        </div>

        <div
          class="md:w-1/2 w-full ml-3"
        >
          <InputField
            required
            label="住所"
            maxLength=50
            v-model="userInfo.address"
          />
        </div>
      </div>
    </div>

    <div class="bg-gray-p-text-color text-white px-4 py-3 mt-3 mb-6 lg:mb-10">紹介報酬受け取り口座</div>
    <div class="mx-auto lg:w-2/3">
      <div class="info mb-3 lg:mb-6">
        <InputField
          label="銀行名"
          maxLength=100
          v-model="bankAccount.bankName"
        />
      </div>

      <div class="info mb-3 lg:mb-6">
        <InputField
          label="支店名"
          maxLength=100
          v-model="bankAccount.branchName"
        />
      </div>

      <div class="flex mb-3 lg:mb-6">
        <div class="md:w-2/6 w-1/3 mr-3">
          <SelectField
            label="口座種別"
            :className="'p-2'"
            :options="accountTypes"
            v-model="bankAccount.accountType"
          />
        </div>

        <div class="md:w-full w-1/3 ml-3">
          <InputField
            label="口座番号"
            charSet="number"
            v-model="bankAccount.accountNumber"
          />
        </div>
      </div>

      <div class="info mb-3 lg:mb-6">
        <InputField
          label="口座名義"
          maxLength=50
          v-model="bankAccount.accountHolder"
        />
      </div>
    </div>

    <div class="text-center text-green-500 text-xl" v-if="updateSuccessfully">保存しました</div>
    <div v-if="error.length > 0" class="text-red-500 text-center text-xl">{{error}}</div>
    <div
      class="
        lg:w-28
        w-24
        my-10
        mx-auto
      "
    >
      <g-button @click="save()">保 存</g-button>
    </div>
    </g-form>

    <g-dialog v-model="openPasswordConfirm">
      <template v-slot:main>
        <div>パスワード確認</div>
        <input-field type="password" v-model="confirmPassword"></input-field>
      </template>
      <template v-slot:action>
        <g-button @click="verifyPassword()">確認</g-button>
      </template>
    </g-dialog>
  </div>
</template>

<script>
import UserTabs from '../../components/UserPage/UserTabs.vue';
import SelectField from '../../components/common/SelectField.vue';
import { UserService, AuthService } from '@/services';
import { authComputed } from '@/helpers';
import GDialog from '@/components/common/Dialog.vue';

export default {
  name: 'MyInfo',

  components: {
    UserTabs,
    SelectField,
    GDialog,
  },
  computed: {
    ...authComputed,
  },

  data: () => ({
    isProcessing: false,
    open: false,
    error: '',
    accountTypes: [{ key: 'basic', value: '普通預金' }, { key: 'current_account', value: '当座預金' }],
    userInfo: {
      firstName: '',
      lastName: '',
      firstNameKana: '',
      lastNameKana: '',
      email: '',
      password: '',
      phoneNumber: '',
      cityCode: '',
      districtCode: '',
      prefecture: '',
      address: '',
    },
    bankAccount: {
      bankName: '',
      branchName: '',
      accountType: '',
      accountNumber: '',
      accountHolder: '',
    },
    updateSuccessfully: false,
    initBankAccountState: '',
    initUserInfoState: '',

    openPasswordConfirm: false,
    confirmPassword: '',
  }),
  mounted() {
    this.updateSuccessfully = false;

    UserService.getBankAcountInfo().then((acc) => {
      this.bankAccount.bankName = acc.bankName;
      this.bankAccount.branchName = acc.branchName;
      this.bankAccount.accountType = acc.accountType;
      this.bankAccount.accountNumber = acc.accountNumber;
      this.bankAccount.accountHolder = acc.accountOwnerName;

      this.initBankAccountState = JSON.stringify(this.bankAccount);
    });

    UserService.getCurrentUser().then((currUser) => {
      this.userInfo.firstName = currUser.firstName;
      this.userInfo.lastName = currUser.lastName;
      this.userInfo.firstNameKana = currUser.firstNameKana;
      this.userInfo.lastNameKana = currUser.lastNameKana;
      this.userInfo.email = currUser.email;
      this.userInfo.phoneNumber = currUser.phoneNumber;
      if (currUser.addressPostCode) {
        this.userInfo.cityCode = currUser.addressPostCode.substring(0, 3);
        this.userInfo.districtCode = currUser.addressPostCode.substring(3);
      }
      this.userInfo.prefecture = currUser.prefecture;
      this.userInfo.address = currUser.address;
      this.initUserInfoState = JSON.stringify(this.userInfo);
    });
  },
  methods: {
    async save() {
      this.error = '';
      if (!this.$refs.form.isValid() || this.isProcessing) return;

      let currentBankInfoString = JSON.stringify(this.bankAccount);
      if (currentBankInfoString !== this.initBankAccountState) {
        this.openPasswordConfirm = true;
        return;
      }

      this.update();
    },

    verifyPassword() {
      AuthService.verifyPassword(this.confirmPassword).then(() => {
        this.update();
      }).catch(() => {
        this.error = 'パスワードが正しくありません';
      }).finally(() => {
        this.openPasswordConfirm = false;
      });
    },

    update() {
      if (this.isProcessing) return;
      this.isProcessing = true;

      UserService.updateCurrentUser(this.userInfo).then(() => {
        this.initUserInfoState = JSON.stringify(this.userInfo);

        if (this.bankAccount.bankName
          || this.bankAccount.branchName
          || this.bankAccount.accountType
          || this.bankAccount.accountNumber
          || this.bankAccount.accountHolder
        ) {
          UserService.saveBankAccountInfo(this.bankAccount).then(() => {
            this.initBankAccountState = JSON.stringify(this.bankAccount);

            this.$store.dispatch('user/getUser');
            this.updateSuccessfully = true;
            this.error = '';
          }).catch(() => {
            this.updateSuccessfully = false;
            this.error = '口座情報の保存が失敗しました';
          });
        } else {
          this.$store.dispatch('user/getUser');
          this.updateSuccessfully = true;
          this.error = '';
          window.scrollTo(0, 0);
        }
      }).catch(() => {
        this.error = '個人情報の保存が失敗しました';
      }).finally(() => {
        this.isProcessing = false;
      });
    },
  },

  beforeUnmount() {
  },
};
</script>
