<template>
  <MainLayout>
    <router-view :key="$route.path"></router-view>
  </MainLayout>
</template>

<script>
import MainLayout from './layouts/MainLayout.vue';

export default {
  name: 'App',
  components: {
    MainLayout,
  },
  beforeCreate() {
    this.$store.dispatch('setting/init');
    this.$store.dispatch('cart/loadCartData');
  },
  created() {
    window.addEventListener('resize', this.onResize);

    // auto login when refresh page
    const loggedInToken = localStorage.getItem('credentials');
    if (loggedInToken) {
      this.$store.dispatch('user/getUser');
    }
  },
  mounted() {
    this.onResize();
  },
  beforeUnmount() {
    this.$store.dispatch('cart/saveCartData2Local');
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      if (window.innerWidth > 640) {
        this.$store.dispatch('setting/setDevice', false);
      } else {
        this.$store.dispatch('setting/setDevice', true);
      }
    },
  },
};
</script>
