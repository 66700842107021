<template>
  <div>
    <label v-if="label" class="mb-2 block text-opacity-70">
      <span>{{ label }}</span>
      <span v-if="required" class="text-red-600 text-xs">*必須</span>
    </label>
    <select
      v-model="selectedOption"
      @change="selectedChanged"
      class="bg-white border border-black-p-btn border-opacity-50 w-full"
      :class="className"
    >
      <option disabled value=''>ー選択するー</option>
      <option
        class="lg:text-lg text-xs"
        v-for="(option, index) in options"
        :value="typeof option == 'object' ? option.key || option.id : index"
        :key="index"
      >
        {{ typeof option == 'object' ? option.value || option.name : option }}
      </option>
    </select>
    <p class="text-left text-sm text-red-600">{{ this.errors.join(" ") }}</p>
  </div>
</template>

<script>
export default {
  name: 'SelectField',
  inject: ['register', 'unregister'],
  props: {
    modelValue: String,
    options: Array,
    label: String,
    className: String,
    required: {
      type: Boolean,
    },
  },
  data: () => ({
    selectedOption: '',
    errors: [],
  }),
  created() {
    if (this.register) this.register(this);
  },
  mounted() {
    if (this.modelValue) this.selectedOption = this.modelValue;
  },
  unmounted() {
    if (this.unregister) this.unregister(this);
  },
  watch: {
    modelValue(val) {
      this.selectedOption = val;
    },
    options(val) {
      if (val.length > 0) {
        let option = val[0];
        this.selectedOption = typeof option === 'object' ? option.key || option.id : 0;
      }
    },
  },
  methods: {
    clearErrors() {
      this.errors = [];
    },

    selectedChanged() {
      this.validate();
      this.$emit('update:modelValue', this.selectedOption);
    },

    validate() {
      this.clearErrors();
      if (this.required && !this.selectedOption) {
        if (this.label) {
          this.errors.push(`${this.label}必須項目です。`);
        } else {
          this.errors.push('必須項目です。');
        }
      }
    },
  },
};
</script>
