<template>
  <!--d-->
  <MainSlider />

  <div class="lg:container mx-auto">
    <!-- Group Banner -->
    <div
      v-if="systemSetting.banners"
      class="container group-banner md:px-3 px-5 sm:hidden mt-8 mx-auto lg:mb-16 mb-9"
    >
      <div class="grid md:grid-cols-2 grid-cols-1 gap-8 lg:gap-10">
        <Banner v-if="systemSetting.banners[2]"
          :image="systemSetting.banners[2].image" :link="systemSetting.banners[2].link" />
        <Banner v-if="systemSetting.banners[3]"
          :image="systemSetting.banners[3].image" :link="systemSetting.banners[3].link" />
      </div>
    </div>

    <AboutWeeat />

    <!-- Group Banner -->
    <div
      v-if="systemSetting.banners"
      class="container group-banner md:px-3 px-5  mx-auto lg:mb-32 mb-9"
    >
      <div class="grid md:grid-cols-2 grid-cols-1 gap-8 lg:gap-10">
        <Banner v-if="systemSetting.banners[0]"
          :image="systemSetting.banners[0].image" :link="systemSetting.banners[0].link" />
        <Banner v-if="systemSetting.banners[1]"
          :image="systemSetting.banners[1].image" :link="systemSetting.banners[1].link" />
        <Banner
          class="hidden sm:block"
          :image="systemSetting.banners[2].image"
          :link="systemSetting.banners[2].link"
          v-if="systemSetting.banners[2]"
        />
        <Banner
          class="hidden sm:block"
          :image="systemSetting.banners[3].image"
          :link="systemSetting.banners[3].link"
          v-if="systemSetting.banners[3]"
        />
      </div>
    </div>

    <!-- Featured Products -->
    <ListProductCard v-if="recommendProducts.length > 0"
      :title="'おすすめ商品'"
      :products="recommendProducts.slice(0, 12)"
    />

    <!-- Banner  -->
    <div
      v-if="systemSetting.banners"
      class="container md:px-3 px-5 mx-auto mb-16 sm:block hidden"
    >
      <div class="w-3/4 mx-auto">
        <Banner v-if="systemSetting.banners[4]"
        :image="systemSetting.banners[4].image" :link="systemSetting.banners[4].link" />
      </div>
    </div>

  </div>

  <!-- Service Introduction -->
  <ServiceIntroduction />

  <div class="lg:container mx-auto">
    <!-- List Brand -->
    <InterestedBrands v-if="listShops.length>0" :brands="listShops" />

    <!-- New Products -->
    <ListProductCard v-if="newReleaseProducts.length>0"
      :title="'新着商品'"
      :products="newReleaseProducts.slice(0, 9)"
    />

    <!-- Banner  -->
    <div
      v-if="systemSetting.banners"
      class="container md:px-3 px-5 mx-auto lg:mb-16 mb-9 mt-6"
    >
      <div class="lg:w-3/4 w-full mx-auto">
        <Banner v-if="systemSetting.banners[5]"
        :image="systemSetting.banners[5].image" :link="systemSetting.banners[5].link" />
      </div>
    </div>
  </div>

  <!-- Sponsor area -->
  <div class="container mx-auto">
    <div class="hidden md:block">
      <a href="https://weeat.twitties8.com/cf/" target="_blank">
        <img
          :src="require(`../assets/images/big_PC.jpg`)"
          alt=""
          class="w-full"
        />
      </a>
    </div>
    <div class="hidden md:block">
      <a href="https://weeat.twitties8.com/cf/" target="_blank">
        <img
          :src="require(`../assets/images/small_PC.jpg`)"
          alt=""
          class="w-full"
        />
      </a>
    </div>
    <div class="lg:hidden">
      <a href="https://weeat.twitties8.com/cf/" target="_blank">
        <img
          :src="require(`../assets/images/big_SP.jpg`)"
          alt=""
          class="w-full"
        />
      </a>
    </div>
    <div class="lg:hidden">
      <a href="https://weeat.twitties8.com/cf/" target="_blank">
        <img
          :src="require(`../assets/images/small_SP.jpg`)"
          alt=""
          class="w-full"
        />
      </a>
    </div>
  </div>

  <div class="w-full grid grid-cols-1 relative overflow-hidden">
      <img src='@/assets/images/red-heart-in-childrens-hands-on-a-blue-background-close-up1.png'
      class="hidden md:block w-full h-full object-cover absolute"
      >
      <img src='@/assets/images/service-introduction-4.png'
      class="block md:hidden h-full object-cover absolute"
      >
      <div class="h-full w-full md:py-16 py-6">
          <div class="m-auto container md:px-3 px-5 mt-auto"
            style="position: relative; top: 50%; transform: translateY(-50%);">
            <h3 class="text-3xl mb-10">
              Weeatは<br class="md:hidden block"/>食品ロス問題に取り組んでいます
            </h3>
            <p>
              見栄えの良くない生鮮、賞味期限が規定を超えた食材。
            </p>
            <p>
              美味しく食べられるのに廃棄されてしまう食品がたくさんあります。
            </p>
            <p>
              その一方で、食が足りずに困る人もいる世界。
            </p>
            <p class="my-6">
              食材を無駄にせず「美味しいもの」として届ける取り組みを、私たちは推進していきます。
            </p>
            <p>また、１注文ごとに10円を貧困問題に取り組む団体へ寄付します。</p>
            <p>（寄付先は災害発生などにより変更することもございます）</p>
          </div>
      </div>
    </div>

  <!-- News -->
  <div class="lg:container mx-auto">
    <news-list :newsList="listNews"></news-list>
  </div>

  <div class="lg:container mx-auto hidden">
    <div
      v-if="systemSetting.banners"
      class="container md:px-3 px-5 mx-auto lg:mb-16 mb-9"
    >
      <div class="lg:w-3/4 w-full mx-auto">
        <Banner v-if="systemSetting.banners[5]"
          :image="systemSetting.banners[5].image" :link="systemSetting.banners[5].link" />
      </div>
    </div>
  </div>

  <button class="fixed btn w-16 h-16 p-2 btn-circle bottom-8 right-8"
      @click="scrollTop()"
  >
    TOPへ
  </button>
</template>

<script>
import MainSlider from '../components/HomePage/MainSlider.vue';
import AboutWeeat from '../components/HomePage/AboutWeeat.vue';
import InterestedBrands from '../components/HomePage/InterestedBrands.vue';
import ServiceIntroduction from '../components/HomePage/ServiceIntroduction.vue';
import Banner from '../components/Banner.vue';
import ListProductCard from '../components/ListProductCard.vue';
import { systemSetting } from '@/helpers';
import {
  ProductService,
  NewsService,
  ShopService,
} from '@/services';
import NewsList from '../components/HomePage/NewsList.vue';

export default {
  name: 'LoginFromIcon',
  components: {
    MainSlider,
    AboutWeeat,
    InterestedBrands,
    ServiceIntroduction,
    Banner,
    ListProductCard,
    NewsList,
  },
  data: () => ({
    loading: false,
    recommendProducts: [],
    listShops: [],
    newReleaseProducts: [],
    listNews: [],
  }),
  computed: {
    ...systemSetting,
  },
  mounted() {
    ProductService.getRecommendProducts().then((res) => {
      this.recommendProducts = res.sort(() => 0.5 - Math.random());
    });
    ShopService.getTopShops().then((res) => {
      this.listShops = res;
    });
    ProductService.getNewReleaseProducts().then((res) => {
      this.newReleaseProducts = res;
    });
    NewsService.getNews().then((res) => {
      this.listNews = res;
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>
