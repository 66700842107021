<template>
  <div class="shop-product">
      <Banner v-if="category.banner" :image="category.banner"/>
      <Banner v-else :image="require(`../assets/temp-images/category-banner.png`)"/>

      <div class="container py-14 mx-auto text-3xl">{{'「'+category.name+'」の商品'}}</div>
      <!-- <ListProductCard v-if="products.length > 0"
        :products="products"
      /> -->
      <div class="container mx-auto md:px-3 px-5">
        <template v-if="products.length > 0">
          <div
            class="
              w-full
              grid
              lg:grid-cols-3
              grid-cols-2 wrap_ProductShop"
          >
             <div class="ProductShop"
              v-for="(product, index) in products"
              :key="index"
            >
              <ProductCard
                :product="product"
              />
            </div>

          </div>
          <paginate v-model="page" :totalPage="totalPage"></paginate>
        </template>
        <div v-else class="w-full text-center text-xl md:mb-24 mb-10">商品がありません</div>
      </div>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import Banner from '../components/Banner.vue';
import { CategoryService } from '@/services';
import { Category } from '@/services/adapter';
import Paginate from '@/components/common/Paginate.vue';

const ITEM_PER_PAGE = process.env.VUE_APP_ITEM_PER_PAGE;

export default {
  props: {
    name: String,
  },
  components: {
    ProductCard,
    Banner,
    Paginate,
  },
  data: () => ({
    category: new Category(),
    products: [],
    page: 1,
    totalPage: 1,
  }),
  watch: {
    page() {
      this.getProductInCurrentPage();
    },
  },
  mounted() {
    CategoryService.getCategoryDetail(this.$route.params.id).then((res) => {
      this.category = res;
    });
    this.getProductInCurrentPage();
  },
  methods: {
    getProductInCurrentPage() {
      CategoryService.getProductsOfCategory(this.$route.params.id,
        this.page, ITEM_PER_PAGE).then((res) => {
        this.products = res.items;
        this.totalPage = res.totalPage;
      });
    },
  },
};
</script>
